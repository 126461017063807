import React, { useState } from "react";
import PropTypes from "prop-types";

import HowProgramWorks from "./HowProgramWorks";
import EligibleChecklist from "./EligibleChecklist";
import EnrollWithBlurb from "./EnrollWithBlurb";
import ChargerChoice from "./ChargerChoice";
import chargers from "./chargers/chargers";

import GrandTitleParas from "../shared/GrandTitledParas/GrandTitledParas";

import pricingOptionsGraph from "../../assets/images/graphs/pricing-options-graph-mn.png";
// import SmoothScroll from "../../../utils/Helpers/SmoothScroll"
import IconGasPump from "../../assets/images/icons/icon-gas-pump.svg";
import IconHardHat from "../../assets/images/icons/icon-hardhat.svg";
import IconZappyBolt from "../../assets/images/icons/icon-zappy-bolt.svg";

import "./EasyChargingHome.scss";
import { FormattedMessage, useIntl } from "react-intl";

const howProgramWorksObjt = (intl) => ({
     images: [IconGasPump, IconHardHat, IconZappyBolt],
     text: [
          intl.formatMessage({
               id: "evaahProgramWorksOne_alt",
               defaultMessage: "You select a Level 2 charger from one of our two pre-qualified options",
          }),
          intl.formatMessage({
               id: "evaahProgramWorksTwo_alt",
               defaultMessage: "We install your charger and maintain it",
          }),
          intl.formatMessage({
               id: "evaahProgramWorksThree",
               defaultMessage: "You save with an off-peak charging schedule",
          }),
     ],
});

const howProgramWorksObjtTwo = (intl) => ({
     images: [IconGasPump, IconHardHat, IconZappyBolt],
     text: [
          intl.formatMessage({
               id: "evaahProgramWorksOne_alt_customer_owned",
               defaultMessage: "Xcel Energy will inspect your station to verify eligibility",
          }),
          intl.formatMessage({
               id: "evaahProgramWorksTwo_alt_customer_owned",
               defaultMessage: "You will be responsible to maintain your own charger",
          }),
          intl.formatMessage({
               id: "evaahProgramWorksThree_customer_owned",
               defaultMessage: "You save with an off-peak charging schedule",
          }),
     ],
});

const renderEnrollmentDetails = (
     stateName,
     enrollURL,
     intl,
     howProgramWorksObjt,
     howProgramWorksObjtTwo,
     ownCharger,
     setOwnCharger
) => (
     <>
          <section className="container works-elible-enroll">
               <div className="row">
                    <div className="toggle-buttons">
                         <div
                              className={`own-charger-toggle ${ownCharger ? null : "active"}`}
                              onClick={() => setOwnCharger(false)}
                         >
                              <span>
                                   <FormattedMessage
                                        id="egbWillRentCharger"
                                        defaultMessage="I WILL RENT A LEVEL 2 CHARGER FROM XCEL ENERGY"
                                        description="I WILL RENT A LEVEL 2 CHARGER FROM XCEL ENERGY"
                                   />
                              </span>
                         </div>
                         <div
                              className={`own-charger-toggle ${ownCharger ? "active" : ``}`}
                              onClick={() => setOwnCharger(true)}
                         >
                              <span>
                                   <FormattedMessage
                                        id="egbWillOwnCharger"
                                        defaultMessage="I WILL USE MY OWN LEVEL 2 CHARGER"
                                        description="I WILL USE MY OWN LEVEL 2 CHARGER"
                                   />
                              </span>
                         </div>
                    </div>

                    <div className="how-works-toggle-content">
                         {!ownCharger ? (
                              <>
                                   <GrandTitleParas classNames="how-works" colonHeader="How the program works:">
                                        <h2 style={{ fontSize: "24px" }}>Xcel Energy-Provided Charger Option</h2>
                                        <p>
                                             This option is ideal if you would like to rent an Xcel Energy charger that
                                             is installed and maintained
                                        </p>
                                        <HowProgramWorks
                                             stateName={stateName}
                                             text={howProgramWorksObjt.text}
                                             images={howProgramWorksObjt.images}
                                        />
                                   </GrandTitleParas>
                                   <section className="container">
                                        <div className="row">
                                             <div className="col-md-10 offset-md-1">
                                                  <EligibleChecklist stateName={stateName} />
                                             </div>
                                        </div>
                                   </section>
                                   <GrandTitleParas classNames="enroll">
                                        <EnrollWithBlurb enrollURL={enrollURL} stateName={stateName} />
                                   </GrandTitleParas>
                                   <section className="container payment-pricing">
                                        <div className="row">
                                             <GrandTitleParas
                                                  classNames="how-works payment-and-price"
                                                  colonHeader="Payment and Pricing"
                                             >
                                                  <>
                                                       <div className="pricing-options d-lg-block">
                                                            <h3
                                                                 style={{
                                                                      textTransform: "uppercase",
                                                                      fontSize: "24px",
                                                                 }}
                                                            >
                                                                 <FormattedMessage
                                                                      id="program-costs-pricing"
                                                                      defaultMessage="Program Costs/Pricing"
                                                                      description="Program Costs/Pricing"
                                                                 />
                                                            </h3>
                                                            <table class="desktop-only">
                                                                 <thead>
                                                                      <tr>
                                                                           <th>
                                                                                <FormattedMessage
                                                                                     id={`EVAAH_Option_MN/WI`}
                                                                                     defaultMessage="Option"
                                                                                     description="Option"
                                                                                />
                                                                           </th>
                                                                           <th>
                                                                                <FormattedMessage
                                                                                     id={`EVAAH_MonthlyPayment_MN/WI`}
                                                                                     defaultMessage="Monthly Payment"
                                                                                     description="Monthly Payment"
                                                                                />
                                                                           </th>
                                                                           <th>
                                                                                <FormattedMessage
                                                                                     id={`EVAAH_IncludedInMonthlyPayment_MN/WI`}
                                                                                     defaultMessage="Included in Monthly Payment"
                                                                                     description="Included in Monthly Payment"
                                                                                />
                                                                           </th>
                                                                           <th>
                                                                                <FormattedMessage
                                                                                     id={`EVAAH_NotIncludedinProgram_MN/WI`}
                                                                                     defaultMessage="Not Included in Program"
                                                                                     description="Not Included in Program"
                                                                                />
                                                                           </th>
                                                                      </tr>
                                                                 </thead>
                                                                 <tbody>
                                                                      <tr>
                                                                           <td>
                                                                                <FormattedMessage
                                                                                     id={`EVAAH_RentTheChargerFromUs_MN/WI`}
                                                                                     defaultMessage="Rent the charger from us"
                                                                                     description="Rent the charger from us"
                                                                                />
                                                                           </td>
                                                                           <td>
                                                                                <FormattedMessage
                                                                                     id={`EVAAH_RentMonthlyPayment_MN`}
                                                                                     defaultMessage="$16.63"
                                                                                     description="$16.63"
                                                                                />
                                                                           </td>
                                                                           <td>
                                                                                <ul>
                                                                                     <li>
                                                                                          <FormattedMessage
                                                                                               id={`EVAAH_table_charger`}
                                                                                               defaultMessage="Charger"
                                                                                          />
                                                                                     </li>
                                                                                     <li>
                                                                                          <FormattedMessage
                                                                                               id={`evaahChargerSetUp_v2`}
                                                                                               defaultMessage="Charger install and set-up"
                                                                                          />
                                                                                          <sup>1</sup>
                                                                                     </li>
                                                                                     <li>
                                                                                          <FormattedMessage
                                                                                               id={`EVAAH_IncludedInMonthlyPayment_dataservicefees`}
                                                                                               defaultMessage="Data/service fees"
                                                                                          />
                                                                                     </li>
                                                                                     <li>
                                                                                          <FormattedMessage
                                                                                               id={`evaahUnlimitedMaintenance`}
                                                                                               defaultMessage="Unlimited maintenance as long as you participate"
                                                                                          />
                                                                                          <sup>2</sup>
                                                                                     </li>
                                                                                     <li>
                                                                                          <FormattedMessage
                                                                                               id={`evaahChargerRelocation`}
                                                                                               defaultMessage="Charger relocation within Xcel Energy territory"
                                                                                          />
                                                                                     </li>
                                                                                     <li>
                                                                                          <FormattedMessage
                                                                                               id={`evaahChargerRemoval`}
                                                                                               defaultMessage="Charger removal"
                                                                                          />
                                                                                     </li>
                                                                                </ul>
                                                                           </td>
                                                                           <td>
                                                                                <ul>
                                                                                     <li>
                                                                                          <FormattedMessage
                                                                                               id={`EVAAH_NotIncludedInProgram_Rent_MN/WI`}
                                                                                               defaultMessage="240-volt circuit wiring from panel to charger location"
                                                                                               description="240-volt circuit wiring from panel to charger location"
                                                                                          />
                                                                                          <sup>3</sup>
                                                                                     </li>
                                                                                     <li>
                                                                                          <FormattedMessage
                                                                                               id={`permit-fees`}
                                                                                               defaultMessage="Permit Fees"
                                                                                               description="Permit Fees"
                                                                                          />
                                                                                          <sup>4</sup>
                                                                                     </li>
                                                                                </ul>
                                                                           </td>
                                                                      </tr>
                                                                 </tbody>
                                                            </table>
                                                            <div class="table-mobile mobile-only">
                                                                 <h3>
                                                                      <FormattedMessage
                                                                           id={`EVAAH_Option_MN/WI`}
                                                                           defaultMessage="Option"
                                                                           description="Option"
                                                                      />
                                                                 </h3>
                                                                 <ul>
                                                                      <li>
                                                                           <FormattedMessage
                                                                                id={`EVAAH_RentTheChargerFromUs_MN/WI`}
                                                                                defaultMessage="Rent the charger from us"
                                                                                description="Rent the charger from us"
                                                                           />
                                                                      </li>
                                                                 </ul>

                                                                 <h3>
                                                                      <FormattedMessage
                                                                           id={`EVAAH_MonthlyPayment_MN/WI`}
                                                                           defaultMessage="Monthly Payment"
                                                                           description="Monthly Payment"
                                                                      />
                                                                 </h3>

                                                                 <ul>
                                                                      <li>
                                                                           <FormattedMessage
                                                                                id={`EVAAH_RentMonthlyPayment_MN`}
                                                                                defaultMessage="$16.63"
                                                                                description="$16.63"
                                                                           />
                                                                      </li>
                                                                 </ul>

                                                                 <h3>
                                                                      <FormattedMessage
                                                                           id={`EVAAH_IncludedInMonthlyPayment_MN/WI`}
                                                                           defaultMessage="Included in Monthly Payment"
                                                                           description="Included in Monthly Payment"
                                                                      />
                                                                 </h3>

                                                                 <ul>
                                                                      <li>
                                                                           <FormattedMessage
                                                                                id={`EVAAH_table_charger`}
                                                                                defaultMessage="Charger"
                                                                           />
                                                                      </li>
                                                                      <li>
                                                                           <FormattedMessage
                                                                                id={`evaahChargerSetUp_v2`}
                                                                                defaultMessage="Charger install and set-up"
                                                                           />
                                                                           <sup>1</sup>
                                                                      </li>
                                                                      <li>
                                                                           <FormattedMessage
                                                                                id={`EVAAH_IncludedInMonthlyPayment_dataservicefees`}
                                                                                defaultMessage="Data/service fees"
                                                                           />
                                                                      </li>
                                                                      <li>
                                                                           <FormattedMessage
                                                                                id={`evaahUnlimitedMaintenance`}
                                                                                defaultMessage="Unlimited maintenance as long as you participate"
                                                                           />
                                                                           <sup>2</sup>
                                                                      </li>
                                                                      <li>
                                                                           <FormattedMessage
                                                                                id={`evaahChargerRelocation`}
                                                                                defaultMessage="Charger relocation within Xcel Energy territory"
                                                                           />
                                                                      </li>
                                                                      <li>
                                                                           <FormattedMessage
                                                                                id={`evaahChargerRemoval`}
                                                                                defaultMessage="Charger removal"
                                                                           />
                                                                      </li>
                                                                 </ul>

                                                                 <h3>
                                                                      <FormattedMessage
                                                                           id={`EVAAH_NotIncludedinProgram_MN/WI`}
                                                                           defaultMessage="Not Included in Program"
                                                                           description="Not Included in Program"
                                                                      />
                                                                 </h3>

                                                                 <ul>
                                                                      <li>
                                                                           <FormattedMessage
                                                                                id={`EVAAH_NotIncludedInProgram_Rent_MN/WI`}
                                                                                defaultMessage="240-volt circuit wiring from panel to charger location"
                                                                                description="240-volt circuit wiring from panel to charger location"
                                                                           />
                                                                           <sup>3</sup>
                                                                      </li>
                                                                      <li>
                                                                           <FormattedMessage
                                                                                id={`permit-fees`}
                                                                                defaultMessage="Permit Fees"
                                                                                description="Permit Fees"
                                                                           />
                                                                           <sup>4</sup>
                                                                      </li>
                                                                 </ul>
                                                            </div>

                                                            <p style={{ marginTop: "20px", fontSize: "12px" }}>
                                                                 <sup>1</sup>
                                                                 <FormattedMessage
                                                                      id="evaahCircuitWiringMNbold"
                                                                      defaultMessage="{bold}"
                                                                      values={{
                                                                           bold: (
                                                                                <FormattedMessage
                                                                                     id="evaahCircuitWiringBold"
                                                                                     defaultMessage="A qualified electrician from Xcel Energy's trusted network will contact you within 2 business days of enrollment confirmation to schedule the installation of your new charger. If adding a new electric service and meter, the current wait time is 8 weeks."
                                                                                     description="A qualified electrician from Xcel Energy's trusted network will contact you within 2 business days of enrollment confirmation to schedule the installation of your new charger. If adding a new electric service and meter, the current wait time is 8 weeks."
                                                                                />
                                                                           ),
                                                                      }}
                                                                 />
                                                            </p>
                                                            <p style={{ fontSize: "12px" }}>
                                                                 <sup>2</sup>
                                                                 <FormattedMessage
                                                                      id="evaahCircuitWiringMN"
                                                                      defaultMessage="Negligence, flood, fire, or other natural disaster are not covered."
                                                                 />
                                                            </p>

                                                            <p style={{ fontSize: "12px" }}>
                                                                 <sup>3</sup>
                                                                 <FormattedMessage
                                                                      id={`evaah_home_requirements`}
                                                                      defaultMessage="{bold} Our qualified electricians can perform this work for you at the time of installation and will provide you with a quote prior to that work."
                                                                      values={{
                                                                           bold: (
                                                                                <b>
                                                                                     <FormattedMessage
                                                                                          id={`EVAAH_NotIncludedInProgram_Rent_MN/WI_home_require`}
                                                                                          defaultMessage="Your home may require additional 240-volt circuit wiring that is not included in the charger installation or price of the program and is unique to each home."
                                                                                          description=""
                                                                                     />
                                                                                </b>
                                                                           ),
                                                                      }}
                                                                 />
                                                            </p>

                                                            <p style={{ fontSize: "12px" }}>
                                                                 <sup>4</sup>
                                                                 <FormattedMessage
                                                                      id={`evaahNegligenceMN`}
                                                                      defaultMessage="Permit fees are not covered in the program pricing. In order to quality for the program, your circuit wiring and charger installation must be inspected and you must have a permit. Your electrician will pull all necessary permits for the work that needs to be completed and you will pay the electrician directly for the permits."
                                                                      description=""
                                                                 />
                                                            </p>
                                                       </div>
                                                       <div>
                                                            <h3
                                                                 style={{
                                                                      textTransform: "uppercase",
                                                                      fontSize: "24px",
                                                                      margin: "50px 0 16px",
                                                                 }}
                                                            >
                                                                 <FormattedMessage
                                                                      id="program-charging-rates-times"
                                                                      defaultMessage="Charging Rates & Times"
                                                                      description="Charging Rates & Times"
                                                                 />
                                                            </h3>
                                                            <p>
                                                                 <FormattedMessage
                                                                      id={`EVAAH_WithEitherOption_MN_v2`}
                                                                      defaultMessage="With EV Accelerate at Home, you have access to electricity pricing that can save you money on charging. Your Level 2 charger will be programmed to charge only during off-peak hours, between midnight and 6 a.m. every day. Your EV will automatically charge during the lowest cost time period, but you can always charge during other times if you need to."
                                                                      description="with either opts"
                                                                 />
                                                                 <ul>
                                                                      <li>
                                                                           <FormattedMessage
                                                                                id={`EVAAH_WithEitherOption_MN/WI_bullet2`}
                                                                                defaultMessage="Fuel cost charges, resource adjustments, and local taxes and fees are not included in the monthly or upfront prices and will be applied upon billing."
                                                                                description="Bullet 2"
                                                                           />
                                                                      </li>
                                                                      <li>
                                                                           <FormattedMessage
                                                                                id={`EVAAH_WithEitherOption_MN/WI_bullet3`}
                                                                                defaultMessage="Details about cancellation options and costs are found in FAQ."
                                                                                description="Bullet 3"
                                                                           />
                                                                      </li>
                                                                 </ul>
                                                            </p>
                                                       </div>
                                                       <div className="pricing-options-graph">
                                                            <img
                                                                 src={pricingOptionsGraph}
                                                                 alt="pricing options graph"
                                                            />
                                                       </div>
                                                       <div className="off-peak-def">
                                                            <p className="last-para">
                                                                 <FormattedMessage
                                                                      id={`EVAAH_OvernightOffPeakHours_MN`}
                                                                      defaultMessage="Overnight, off-peak hours are between midnight and 6 a.m. for your EV. Your home’s energy use will continue to be billed at its current rate."
                                                                 />
                                                            </p>
                                                       </div>
                                                  </>
                                             </GrandTitleParas>
                                        </div>
                                   </section>
                                   <section className="container charger-options" id="charger-options">
                                        <div className="row">
                                             <GrandTitleParas
                                                  classNames="how-works charger-options"
                                                  colonHeader="Charger Options"
                                             >
                                                  <div>
                                                       <h3 style={{ textTransform: "uppercase", fontSize: "24px" }}>
                                                            <FormattedMessage
                                                                 id={`charger-options`}
                                                                 defaultMessage="Charger Options"
                                                            />
                                                       </h3>
                                                       <p>
                                                            <FormattedMessage
                                                                 id={`chargersMustBeHardwired_MNasdasd`}
                                                                 defaultMessage="You can select either the {link1} or {link2}. Both chargers are ENERGY STAR®-certified and UL Listed for safety.{lineBreak}All chargers in the program must be hardwired to a 240-volt circuit and cannot be plugged into an outlet. See FAQ for more information{lineBreak}{italics}"
                                                                 description="You can select either the ChargePoint Home Flex or Enel X Juice Box."
                                                                 values={{
                                                                      lineBreak: (
                                                                           <>
                                                                                <br /> <br />
                                                                           </>
                                                                      ),
                                                                      italics: (
                                                                           <i>
                                                                                <FormattedMessage
                                                                                     id={`noteXcelChargerDisclaimer`}
                                                                                     defaultMessage="Note: Xcel Energy does not endorse one EV charger over the other."
                                                                                />
                                                                           </i>
                                                                      ),
                                                                      link1: (
                                                                           <a
                                                                                href={intl.formatMessage({
                                                                                     id: "EVAAH_ChargerOptions_link1_URL",
                                                                                     defaultMessage:
                                                                                          "https://ev.xcelenergy.com/ChargePoint_Home_Flex.pdf",
                                                                                })}
                                                                           >
                                                                                <FormattedMessage
                                                                                     id={`EVAAH_ChargerOptions_link1_text`}
                                                                                     defaultMessage="ChargePoint Home Flex"
                                                                                />
                                                                           </a>
                                                                      ),
                                                                      link2: (
                                                                           <a
                                                                                href={intl.formatMessage({
                                                                                     id: "EVAAH_ChargerOptions_link2_URL",
                                                                                     defaultMessage:
                                                                                          "https://ev.xcelenergy.com/Enel_X_JuiceBox_48.pdf",
                                                                                })}
                                                                           >
                                                                                <FormattedMessage
                                                                                     id={`EVAAH_ChargerOptions_link2_text`}
                                                                                     defaultMessage="Enel X Way Juice Box"
                                                                                />
                                                                           </a>
                                                                      ),
                                                                      link3: (
                                                                           <a
                                                                                href={intl.formatMessage({
                                                                                     id: "EVAAH_ChargerOptions_link3_URL",
                                                                                     defaultMessage:
                                                                                          "mailto:ElectricVehicles@xcelenergy.com",
                                                                                })}
                                                                           >
                                                                                <FormattedMessage
                                                                                     id={`EVAAH_ChargerOptions_link3_text`}
                                                                                     defaultMessage="email us"
                                                                                />
                                                                           </a>
                                                                      ),
                                                                 }}
                                                            />
                                                            <br />
                                                            <em>
                                                                 <FormattedMessage
                                                                      id={`EVAAH_ChargerOptions_tesla_owners_adapter`}
                                                                      defaultMessage="Tesla owners will need an adapter."
                                                                 />
                                                            </em>
                                                       </p>
                                                  </div>
                                             </GrandTitleParas>
                                             <GrandTitleParas classNames="charger-choices">
                                                  <ChargerChoice chargers={chargers} />
                                             </GrandTitleParas>
                                        </div>
                                   </section>
                              </>
                         ) : (
                              <>
                                   <GrandTitleParas classNames="how-works" colonHeader="How the program works:">
                                        <h2 style={{ fontSize: "24px" }}>Customer-Owned Charger Option</h2>
                                        <p>
                                             This option is ideal if you would like to use your own pre-approved charger
                                        </p>
                                        <p>
                                             You purchase and install a pre-approved Level 2 charger based on our two
                                             options.
                                        </p>
                                        <HowProgramWorks
                                             stateName={stateName}
                                             text={howProgramWorksObjtTwo.text}
                                             images={howProgramWorksObjtTwo.images}
                                        />
                                   </GrandTitleParas>
                                   {/* <EligibleChecklist stateName={stateName} /> */}
                                   <section className="container">
                                        <div className="row">
                                             <div className="col-md-10 offset-md-1">
                                                  <h3
                                                       style={{
                                                            width: "100%",
                                                            textAlign: "left",
                                                            textTransform: "uppercase",
                                                            fontSize: "24px",
                                                       }}
                                                  >
                                                       Eligibility
                                                  </h3>
                                                  <ul style={{ marginBottom: "0px" }}>
                                                       <li>
                                                            Drive an electric vehicle (all-electric or plug-in hybrid)
                                                       </li>
                                                       <li>
                                                            Live in a residence that receives electric service from Xcel
                                                            Energy in Minnesota
                                                       </li>
                                                       <li>
                                                            Have a qualifying ChargePoint or Enel X Way charger.{" "}
                                                            <a href="#charger-options">Click Here</a>
                                                            <sup>1</sup>
                                                       </li>
                                                       <li>Charging station will need to be hardwired and inspected</li>
                                                       <li>
                                                            Have access to Wi-Fi at the charging location<sup>2</sup>
                                                       </li>
                                                       <li>
                                                            Your charging station will be tested to verify data
                                                            transmission
                                                       </li>
                                                       <li>
                                                            Solar and Whole-Home Time of day customers do not qualify
                                                            for this option
                                                       </li>
                                                  </ul>

                                                  <EnrollWithBlurb
                                                       enrollURL={enrollURL}
                                                       stateName={stateName}
                                                       ownCharger={ownCharger}
                                                  />

                                                  <p>
                                                       <sup>1</sup>For customer-owned charger option, you will be
                                                       accepted into the program after our electrician inspects your
                                                       installed charger to verify it is a working eligible model, can
                                                       be hardwired, can be connected to Wi-Fi and charging data can be
                                                       transmitted.
                                                  </p>
                                                  <p>
                                                       <sup>2</sup>Since your charger needs to be Wi-Fi enabled, please
                                                       confirm that the charger location has Wi-Fi access. If signal
                                                       strength in this location is below 3 bars, you may need to
                                                       install a Wi-Fi booster before the electrician arrives to inspect
                                                       the charger. Be sure to have your Wi-Fi password available for
                                                       connecting to the charger during installation.
                                                  </p>
                                             </div>
                                        </div>
                                   </section>

                                   <section className="container payment-pricing">
                                        <div className="row">
                                             <GrandTitleParas
                                                  classNames="how-works payment-and-price"
                                                  colonHeader="Payment and Pricing"
                                             >
                                                  <>
                                                       <div className="pricing-options d-lg-block">
                                                            <h3
                                                                 style={{
                                                                      textTransform: "uppercase",
                                                                      fontSize: "24px",
                                                                 }}
                                                            >
                                                                 <FormattedMessage
                                                                      id="program-costs-pricing"
                                                                      defaultMessage="Program Costs/Pricing"
                                                                      description="Program Costs/Pricing"
                                                                 />
                                                            </h3>
                                                            <table class="desktop-only">
                                                                 <thead>
                                                                      <tr>
                                                                           <th>
                                                                                <FormattedMessage
                                                                                     id={`EVAAH_Option_MN/WI`}
                                                                                     defaultMessage="Option"
                                                                                     description="Option"
                                                                                />
                                                                           </th>
                                                                           <th>
                                                                                <FormattedMessage
                                                                                     id={`EVAAH_MonthlyPayment_MN/WI`}
                                                                                     defaultMessage="Monthly Payment"
                                                                                     description="Monthly Payment"
                                                                                />
                                                                           </th>
                                                                           <th>
                                                                                <FormattedMessage
                                                                                     id={`EVAAH_IncludedInMonthlyPayment_MN/WI`}
                                                                                     defaultMessage="Included in Monthly Payment"
                                                                                     description="Included in Monthly Payment"
                                                                                />
                                                                           </th>
                                                                           <th>
                                                                                <FormattedMessage
                                                                                     id={`EVAAH_IncludedInUpfrontPayment_MN/WI_not_included`}
                                                                                     defaultMessage="Not Included in Program Cost"
                                                                                     description="Not Included in Program Cost"
                                                                                />
                                                                           </th>
                                                                      </tr>
                                                                 </thead>
                                                                 <tbody>
                                                                      <tr>
                                                                           <td>
                                                                                <FormattedMessage
                                                                                     id={`EVAAH_CustomerOwnedCharger_MN/WI`}
                                                                                     defaultMessage="Customer- Owned Charger"
                                                                                     description="Customer- Owned Charger"
                                                                                />
                                                                           </td>
                                                                           <td>
                                                                                <FormattedMessage
                                                                                     id={`EVAAH_CustomerOwnedMonthlyPayment_MN_v2`}
                                                                                     defaultMessage="$6.73"
                                                                                     description="6.73"
                                                                                />
                                                                           </td>
                                                                           <td>
                                                                                <ul>
                                                                                     <li>
                                                                                          <FormattedMessage
                                                                                               id={`EVAAH_data_service`}
                                                                                               defaultMessage="Data/service fees"
                                                                                          />
                                                                                     </li>
                                                                                     <li>
                                                                                          <FormattedMessage
                                                                                               id={`evaah_electrician_visit`}
                                                                                               defaultMessage="Electrician initial visit"
                                                                                          />
                                                                                          <sup>1</sup>
                                                                                     </li>
                                                                                     <li>
                                                                                          <FormattedMessage
                                                                                               id={`EVAAH_charger_relocation`}
                                                                                               defaultMessage="Charger Relocation within Xcel Energy Territory"
                                                                                          />
                                                                                     </li>
                                                                                </ul>
                                                                           </td>
                                                                           <td>
                                                                                <ul>
                                                                                     <li>
                                                                                          <FormattedMessage
                                                                                               id={`EVAAH_NotIncludedInProgram_Rent_MN/WI`}
                                                                                               defaultMessage="240-volt circuit wiring from panel to charger location"
                                                                                               description="240-volt circuit wiring from panel to charger location"
                                                                                          />
                                                                                          <sup>2</sup>
                                                                                     </li>
                                                                                     <li>
                                                                                          <FormattedMessage
                                                                                               id={`permit-fees`}
                                                                                               defaultMessage="Permit Fees"
                                                                                               description="Permit Fees"
                                                                                          />
                                                                                          <sup>3</sup>
                                                                                     </li>
                                                                                </ul>
                                                                           </td>
                                                                      </tr>
                                                                 </tbody>
                                                            </table>
                                                            <div class="table-mobile mobile-only">
                                                                 <h3>
                                                                      <FormattedMessage
                                                                           id={`EVAAH_Option_MN/WI`}
                                                                           defaultMessage="Option"
                                                                           description="Option"
                                                                      />
                                                                 </h3>

                                                                 <ul>
                                                                      <li>
                                                                           <FormattedMessage
                                                                                id={`EVAAH_CustomerOwnedCharger_MN/WI`}
                                                                                defaultMessage="Customer- Owned Charger"
                                                                                description="Customer- Owned Charger"
                                                                           />
                                                                      </li>
                                                                 </ul>

                                                                 <h3>
                                                                      <FormattedMessage
                                                                           id={`EVAAH_MonthlyPayment_MN/WI`}
                                                                           defaultMessage="Monthly Payment"
                                                                           description="Monthly Payment"
                                                                      />
                                                                 </h3>

                                                                 <ul>
                                                                      <li>
                                                                           <FormattedMessage
                                                                                id={`EVAAH_CustomerOwnedMonthlyPayment_MN_v2`}
                                                                                defaultMessage="$6.73"
                                                                                description="6.73"
                                                                           />
                                                                      </li>
                                                                 </ul>

                                                                 <h3>
                                                                      <FormattedMessage
                                                                           id={`EVAAH_IncludedInMonthlyPayment_MN/WI`}
                                                                           defaultMessage="Included in Monthly Payment"
                                                                           description="Included in Monthly Payment"
                                                                      />
                                                                 </h3>

                                                                 <ul>
                                                                      <li>
                                                                           <FormattedMessage
                                                                                id={`EVAAH_data_service`}
                                                                                defaultMessage="Data/service fees"
                                                                           />
                                                                      </li>
                                                                      <li>
                                                                           <FormattedMessage
                                                                                id={`evaah_electrician_visit`}
                                                                                defaultMessage="Electrician initial visit"
                                                                           />
                                                                           <sup>1</sup>
                                                                      </li>
                                                                      <li>
                                                                           <FormattedMessage
                                                                                id={`EVAAH_charger_relocation`}
                                                                                defaultMessage="Charger Relocation within Xcel Energy Territory"
                                                                           />
                                                                      </li>
                                                                 </ul>

                                                                 <h3>
                                                                      <FormattedMessage
                                                                           id={`EVAAH_IncludedInUpfrontPayment_MN/WI_not_included`}
                                                                           defaultMessage="Not Included in Program Cost"
                                                                           description="Not Included in Program Cost"
                                                                      />
                                                                 </h3>

                                                                 <ul>
                                                                      <li>
                                                                           <FormattedMessage
                                                                                id={`EVAAH_NotIncludedInProgram_Rent_MN/WI`}
                                                                                defaultMessage="240-volt circuit wiring from panel to charger location"
                                                                                description="240-volt circuit wiring from panel to charger location"
                                                                           />
                                                                           <sup>2</sup>
                                                                      </li>
                                                                      <li>
                                                                           <FormattedMessage
                                                                                id={`permit-fees`}
                                                                                defaultMessage="Permit Fees"
                                                                                description="Permit Fees"
                                                                           />
                                                                           <sup>3</sup>
                                                                      </li>
                                                                 </ul>
                                                            </div>
                                                            <br />
                                                            <p className="font12">
                                                                 <sup>1</sup> A qualified electrician from Xcel Energy's
                                                                 trusted network will contact you within two business
                                                                 days of enrollment confirmation to schedule the
                                                                 inspection and hardwiring of your new charger. If
                                                                 adding a new electric service and meter, the current
                                                                 wait time is 6 weeks.
                                                            </p>
                                                            <p className="font12">
                                                                 <sup>2</sup> If you need 240-volt wiring, our qualified
                                                                 electricians can perform this work for you at the time
                                                                 of inspection and will provide you with a quote prior
                                                                 to that work. Click here for a list of our qualified
                                                                 electricians.
                                                            </p>
                                                            <p className="font12">
                                                                 <sup>3</sup> Permit fees are not covered in the program
                                                                 pricing. In order to qualify for the program, your
                                                                 circuit wiring and charger installation must be
                                                                 inspected and you must have a permit. Your electrician
                                                                 will pull all necessary permits for the work that needs
                                                                 to be completed and you will pay the electrician
                                                                 directly for the permits.
                                                            </p>
                                                       </div>
                                                       <div>
                                                            <h3
                                                                 style={{
                                                                      textTransform: "uppercase",
                                                                      fontSize: "24px",
                                                                      margin: "50px 0 16px",
                                                                 }}
                                                            >
                                                                 <FormattedMessage
                                                                      id="program-charging-rates-times"
                                                                      defaultMessage="Charging Rates & Times"
                                                                      description="Charging Rates & Times"
                                                                 />
                                                            </h3>
                                                            <p>
                                                                 <FormattedMessage
                                                                      id={`EVAAH_WithEitherOption_customerowned_MN`}
                                                                      defaultMessage="When you provide your own charger, you will have access to electricity pricing that can save you money on charging. Your Level 2 charger will be programmed to charge only during off-peak hours, between midnight and 6 a.m. every day. Your EV will automatically charge during the lowest pricing period, but you can always charge during other times if you need to."
                                                                      description="with either opts"
                                                                 />
                                                                 <br />
                                                                 <ul>
                                                                      <li>
                                                                           <FormattedMessage
                                                                                id={`EVAAH_WithEitherOption_MN/WI_bullet2`}
                                                                                defaultMessage="Fuel cost charges, resource adjustments, and local taxes and fees are not included in the monthly or upfront prices and will be applied upon billing."
                                                                                description="Bullet 2"
                                                                           />
                                                                      </li>
                                                                      <li>
                                                                           <FormattedMessage
                                                                                id={`EVAAH_WithEitherOption_MN/WI_bullet3`}
                                                                                defaultMessage="Details about cancellation options and costs are found in FAQ."
                                                                                description="Bullet 3"
                                                                           />
                                                                      </li>
                                                                 </ul>
                                                            </p>
                                                       </div>
                                                       <div className="pricing-options-graph">
                                                            <img
                                                                 src={pricingOptionsGraph}
                                                                 alt="pricing options graph"
                                                            />
                                                       </div>
                                                       <div className="off-peak-def">
                                                            <p className="last-para">
                                                                 <FormattedMessage
                                                                      id={`EVAAH_OvernightOffPeakHours_MN`}
                                                                      defaultMessage="Overnight, off-peak hours are between midnight and 6 a.m. for your EV. Your home’s energy use will continue to be billed at its current rate."
                                                                 />
                                                            </p>
                                                       </div>
                                                  </>
                                             </GrandTitleParas>
                                        </div>
                                   </section>
                                   <section className="container charger-options" id="charger-options">
                                        <div className="row">
                                             <GrandTitleParas
                                                  classNames="how-works charger-options"
                                                  colonHeader="Charger Options"
                                             >
                                                  <div>
                                                       <h3 style={{ textTransform: "uppercase", fontSize: "24px" }}>
                                                            <FormattedMessage
                                                                 id={`charger-options`}
                                                                 defaultMessage="Charger Options"
                                                            />
                                                       </h3>
                                                       <p>
                                                            <FormattedMessage
                                                                 id={`chargersMustBeHardwired_MNasdasd`}
                                                                 defaultMessage="You can select either the {link1} or {link2}. Both chargers are ENERGY STAR®-certified and UL Listed for safety.{lineBreak}All chargers in the program must be hardwired to a 240-volt circuit."
                                                                 description="You can select either the ChargePoint Home Flex or Enel X Juice Box."
                                                                 values={{
                                                                      lineBreak: (
                                                                           <>
                                                                                <br /> <br />
                                                                           </>
                                                                      ),
                                                                      italics: (
                                                                           <i>
                                                                                <FormattedMessage
                                                                                     id={`noteXcelChargerDisclaimer`}
                                                                                     defaultMessage="Note: Xcel Energy does not endorse one EV charger over the other."
                                                                                />
                                                                           </i>
                                                                      ),
                                                                      link1: (
                                                                           <a
                                                                                href={intl.formatMessage({
                                                                                     id: "EVAAH_ChargerOptions_link1_URL",
                                                                                     defaultMessage:
                                                                                          "https://ev.xcelenergy.com/ChargePoint_Home_Flex.pdf",
                                                                                })}
                                                                           >
                                                                                <FormattedMessage
                                                                                     id={`EVAAH_ChargerOptions_link1_text`}
                                                                                     defaultMessage="ChargePoint Home Flex"
                                                                                />
                                                                           </a>
                                                                      ),
                                                                      link2: (
                                                                           <a
                                                                                href={intl.formatMessage({
                                                                                     id: "EVAAH_ChargerOptions_link2_URL",
                                                                                     defaultMessage:
                                                                                          "https://ev.xcelenergy.com/Enel_X_JuiceBox_48.pdf",
                                                                                })}
                                                                           >
                                                                                <FormattedMessage
                                                                                     id={`EVAAH_ChargerOptions_link2_text`}
                                                                                     defaultMessage="Enel X Way Juice Box"
                                                                                />
                                                                           </a>
                                                                      ),
                                                                      link3: (
                                                                           <a
                                                                                href={intl.formatMessage({
                                                                                     id: "EVAAH_ChargerOptions_link3_URL",
                                                                                     defaultMessage:
                                                                                          "mailto:ElectricVehicles@xcelenergy.com",
                                                                                })}
                                                                           >
                                                                                <FormattedMessage
                                                                                     id={`EVAAH_ChargerOptions_link3_text`}
                                                                                     defaultMessage="email us"
                                                                                />
                                                                           </a>
                                                                      ),
                                                                 }}
                                                            />
                                                            <br />
                                                            <em>
                                                                 <FormattedMessage
                                                                      id={`EVAAH_ChargerOptions_tesla_owners_adapter`}
                                                                      defaultMessage="Tesla owners will need an adapter."
                                                                 />
                                                            </em>
                                                       </p>
                                                  </div>
                                             </GrandTitleParas>
                                             <GrandTitleParas classNames="charger-choices">
                                                  <ChargerChoice chargers={chargers} />
                                             </GrandTitleParas>
                                        </div>
                                   </section>
                              </>
                         )}
                    </div>
               </div>
          </section>
     </>
);

const EasyChargingHome = ({ stateName, enrollURL }) => {
     const intl = useIntl();
     const [ownCharger, setOwnCharger] = useState(false);

     return (
          <div className="easy-charging-home">
               <section className="overview">
                    {renderEnrollmentDetails(
                         stateName,
                         enrollURL,
                         intl,
                         howProgramWorksObjt(intl),
                         howProgramWorksObjtTwo(intl),
                         ownCharger,
                         setOwnCharger
                    )}
               </section>
          </div>
     );
};

export default EasyChargingHome;

EasyChargingHome.propTypes = {
     stateName: PropTypes.string,
     enrollURL: PropTypes.string,
};
