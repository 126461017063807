import React from "react";
import { FormattedMessage } from "react-intl";
import Tooltip from "../../../../components/shared/ToolTip/ToolTip";
import redCheck from "../../../assets/images/icons/red_check.svg";
import tooltipImage from "../../../assets/images/icons/Union.svg";
import MeterDiagram from "../../../assets/images/meter-diagram.png";

import {
     EV_ACCELERATE_AT_HOME_NEW_MEXICO,
     INCOME_QUALIFIED_COLORADO,
     EV_ACCELERATE_AT_HOME_MINNESOTA,
     EV_ACCELERATE_AT_HOME_WISCONSIN,
     EV_ACCELERATE_AT_HOME_COLORADO,
     OPTIMIZE_YOUR_CHARGE_COLORADO,
     OPTIMIZE_YOUR_CHARGE_NEW_MEXICO,
     EV_SUBSCRIPTION_SERVICE_PILOT_MINNESOTA,
     CHANGING_PERKS_PILOT_COLORADO,
     HOME_WIRING_REBATE_COLORADO,
     HOME_WIRING_REBATE_NEW_MEXICO,
     COLORADO,
     NEW_MEXICO,
     WISCONSIN,
     MINNESOTA,
     OPTIMIZE_YOUR_CHARGE_MINNESOTA,
     TIME_OF_DAY_SEPARATE_METER_MINNESOTA,
} from "../../../../constants";
import { homeWiringRebateFaqs } from "./homeWiringRebateFAQs";
import { byApplicable } from "./byApplicable";
import { RenderByState } from "../../../../components/RenderByState";

export const getFAQs = (faqKey, region) => {
     if (!window.location.pathname.includes("home-wiring-rebate")) {
          if (window.location.pathname.includes("mn")) region = MINNESOTA;
          if (window.location.pathname.includes("wi")) region = WISCONSIN;
          if (window.location.pathname.includes("co")) region = COLORADO;
     }

     const pdfLinks = {
          [COLORADO]:
               "https://www.xcelenergy.com/staticfiles/xe-responsive/Programs%20and%20Rebates/Residential/22-03-628%20CO%20EV%20Electrician%20Info%20Sheet%20P1.pdf",
          [WISCONSIN]: "https://www.xcelenergy.com/staticfiles/xe/PDF/WI-EV-Electricians-Info-Sheet.pdf",
          [MINNESOTA]:
               "https://www.xcelenergy.com/staticfiles/xe-responsive/Marketing/MN-EV-Electricians-Information-Sheet.pdf?_ga=2.96420120.508821716.1712775839-1228372669.1675109614",
     };

     switch (faqKey) {
          case HOME_WIRING_REBATE_NEW_MEXICO:
          case HOME_WIRING_REBATE_COLORADO:
               return homeWiringRebateFaqs(faqKey, region);
          default:
               return [
                    {
                         header: (
                              <span className="header">
                                   <FormattedMessage
                                        id="faqChargerInformationTitle"
                                        defaultMessage="Charger Information"
                                   />
                              </span>
                         ),
                         headLine: (
                              <span className="headline">
                                   <FormattedMessage
                                        id={`faqWhyAreOnly2LevelChargers${faqKey.split("-")[1]}`}
                                        defaultMessage="Why are only two Level 2 chargers eligible for this program?"
                                        values={{
                                             stateName: region,
                                        }}
                                   />
                              </span>
                         ),
                         body: (
                              <div>
                                   <p>
                                        <FormattedMessage
                                             id={`faqWhyAreOnly2LevelChargers${faqKey.split("-")[1]}Answer`}
                                             defaultMessage="Through a formal vetting and approval process, we selected chargers that fulfilled program requirements of providing access to your charging data while keeping your bills low. We use this charging data to bill your EV energy use at the discounted rate for off-peak charging. As technologies change and improve, we will continue to explore additional options for customers to participate in this program."
                                             values={{
                                                  stateName: region,
                                             }}
                                        />
                                   </p>
                              </div>
                         ),
                         applicableTo: [
                              // EV_ACCELERATE_AT_HOME_MINNESOTA,
                              // EV_ACCELERATE_AT_HOME_WISCONSIN,
                              EV_ACCELERATE_AT_HOME_COLORADO,
                              EV_ACCELERATE_AT_HOME_NEW_MEXICO,
                              // OPTIMIZE_YOUR_CHARGE_COLORADO,
                              // OPTIMIZE_YOUR_CHARGE_NEW_MEXICO,
                              // OPTIMIZE_YOUR_CHARGE_MINNESOTA
                         ],
                         id: "faqWhyAreOnly2LevelChargers",
                    },
                    {
                         header: (
                              <span className="header">
                                   <FormattedMessage
                                        id="faqChargerInformationTitle"
                                        defaultMessage="Charger Information"
                                   />
                              </span>
                         ),
                         headLine: (
                              <span className="headline">
                                   <FormattedMessage
                                        id="faqWhatIsALevel2Charger_MN/WI"
                                        defaultMessage="What is a Level 2 charger?"
                                        description="What is a Level 2 charger?"
                                   />
                              </span>
                         ),
                         body: (
                              <div>
                                   <p>
                                        <FormattedMessage
                                             id="faqWhatIsALevel2Charger_answer_MN/WI"
                                             values={{ lineBreak: <br /> }}
                                             defaultMessage="There are two common household levels of EV charging - Level 1 and 2.{lineBreak}Level 1 - Can provide 4 to 6 miles range for every hour of charging. EVs come with a standard Level 1 charger that plugs into a typical household outlet (120-volt).{lineBreak}Level 2 - Faster and more convenient charging, providing 25 to 40 miles range for every hour charging. Needs a dedicated 240-volt circuit (like an electric clothes dryer)."
                                        />
                                   </p>
                              </div>
                         ),
                         applicableTo: [EV_ACCELERATE_AT_HOME_WISCONSIN, "SSP-MN"],
                         id: "faqWhatIsALevel2ChargerMN/WI",
                    },
                    {
                         headLine: (
                              <span className="headline">
                                   <FormattedMessage
                                        id="faqBenefitsLevelTwoCharging"
                                        defaultMessage="What are the benefits of Level 2 charging?"
                                        description="What are the benefits of Level 2 charging?"
                                   />
                              </span>
                         ),
                         body: (
                              <div>
                                   <p>
                                        <FormattedMessage
                                             id="faqBenefitsLevelTwoChargingAnswer"
                                             defaultMessage="Level 2 charging is faster and more convenient, providing 25-40 miles of range for every hour of charging. Level 1 charging provides 4-6 miles of range for every one hour of charging. A Level 2 charger requires a dedicated 240-volt circuit (like an electric clothes dryer)."
                                             description="What are the benefits of Level 2 charging? Answer"
                                        />
                                   </p>
                              </div>
                         ),
                         applicableTo: [
                              OPTIMIZE_YOUR_CHARGE_COLORADO,
                              OPTIMIZE_YOUR_CHARGE_NEW_MEXICO,
                              EV_ACCELERATE_AT_HOME_COLORADO,
                              EV_ACCELERATE_AT_HOME_NEW_MEXICO,
                              OPTIMIZE_YOUR_CHARGE_MINNESOTA,
                         ],
                         id: "faqBenefitsLevelTwoCharging",
                    },
                    {
                         header: (
                              <span className="header">
                                   <FormattedMessage
                                        id="faqChargerInformationTitle"
                                        defaultMessage="Charger Information"
                                   />
                              </span>
                         ),
                         headLine: (
                              <span className="headline">
                                   <FormattedMessage
                                        id="faqWhatIsALevel2Charger_MN/WI"
                                        defaultMessage="What is a Level 2 charger?"
                                        description="What is a Level 2 charger?"
                                   />
                              </span>
                         ),
                         body: (
                              <div>
                                   <p>
                                        <FormattedMessage
                                             id="faqWhatIsALevel2Charger_answer_MN/WI"
                                             values={{ lineBreak: <br /> }}
                                             defaultMessage="There are two common household levels of EV charging - Level 1 and 2.{lineBreak}Level 1 - Can provide 4 to 6 miles range for every hour of charging. EVs come with a standard Level 1 charger that plugs into a typical household outlet (120-volt).{lineBreak}Level 2 - Faster and more convenient charging, providing 25 to 40 miles range for every hour of charging. Needs a dedicated 240-volt circuit (like an electric clothes dryer)."
                                        />
                                   </p>
                              </div>
                         ),
                         applicableTo: [EV_ACCELERATE_AT_HOME_MINNESOTA],
                         id: "faqWhatIsALevel2Charger",
                    },
                    {
                         headLine: (
                              <span className="headline">
                                   <FormattedMessage
                                        id={`faqWhyAreOnly2LevelChargers${faqKey.split("-")[1]}`}
                                        defaultMessage="Why are only two Level 2 chargers eligible for this program?"
                                        values={{
                                             stateName: region,
                                        }}
                                   />
                              </span>
                         ),
                         body: (
                              <div>
                                   <p>
                                        <FormattedMessage
                                             id={`faqWhyAreOnly2LevelChargers${faqKey.split("-")[1]}Answer`}
                                             defaultMessage="Through a formal vetting and approval process, we selected chargers that fulfilled program requirements of providing access to your charging data while keeping your bills low. We use this charging data to bill your EV energy use at the discounted rate for off-peak charging. As technologies change and improve, we will continue to explore additional options for customers to participate in this program."
                                             values={{
                                                  stateName: region,
                                             }}
                                        />
                                   </p>
                              </div>
                         ),
                         applicableTo: [EV_ACCELERATE_AT_HOME_MINNESOTA],
                         id: "faqWhyAreOnly2LevelChargers",
                    },
                    {
                         headLine: (
                              <span className="headline">
                                   <FormattedMessage
                                        id="faqAlreadyOwnLevelTwoCharger"
                                        defaultMessage="What if I already own a Level 2 charger?"
                                        description="What if I already own a Level 2 charger? question"
                                   />
                              </span>
                         ),
                         body: (
                              <div>
                                   <p>
                                        <FormattedMessage
                                             id={
                                                  region === "Minnesota"
                                                       ? "faqAlreadyOwnLevelTwoCharger_Answer_MN"
                                                       : "faqAlreadyOwnLevelTwoCharger_Answer_WI"
                                             }
                                             defaultMessage={
                                                  region === "Minnesota"
                                                       ? "If you have a ChargePoint Home or Enel X Juice Box charger that was purchased prior to Dec. 16, 2020, you may be able to participate in this program. Please email us instead of enrolling online. We will work with you directly to get you signed up. An electrician will need to program the charger and provide wiring if needed. If you bring your own charger, you’ll pay $240 for electrician set up and $6.68 per month for data and service fees and have access to electricity pricing that can save you money on charging. You will be responsible for maintenance of the charger. At this time, other Level 2 chargers are not compatible with the program."
                                                       : "If you own either a ChargePoint Home Flex or Enel X Way JuiceBox charger, you may be able to participate in this program. Please select the customer-owned charger option on the enrollment application and we will send an electrician to your home to ensure that your charging station qualifies. You will be responsible for installing 240-volt circuit wiring, from the panel to the charger location, if needed. There is a monthly data and service fee of $7.00 to gain access to electricity pricing that can save you money on off-peak charging.  You will be responsible for the maintenance of the charger. Currently, other Level 2 chargers are not compatible with the program."
                                             }
                                             description="What if I already own a Level 2 charger? answer"
                                        />
                                   </p>
                              </div>
                         ),
                         applicableTo: [EV_ACCELERATE_AT_HOME_WISCONSIN, EV_SUBSCRIPTION_SERVICE_PILOT_MINNESOTA],
                         id: "faqAlreadyOwnLevelTwoChargerMN/WI",
                    },
                    {
                         headLine: (
                              <span className="headline">
                                   <FormattedMessage
                                        id="faqAlreadyOwnLevelTwoCharger"
                                        defaultMessage="What if I already own a Level 2 charger?"
                                        description="What if I already own a Level 2 charger?"
                                   />
                              </span>
                         ),
                         body: (
                              <div>
                                   <p>
                                        <FormattedMessage
                                             id="faqAlreadyOwnLevelTwoCharger_Answer_MN_v2"
                                             defaultMessage="If you own either a ChargePoint Home Flex or Enel X Way JuiceBox charger, you may be able to participate in this program. Please select the customer-owned charger option on the enrollment application and we will send an electrician to your home to ensure that your charging station qualifies. You will be responsibile for installing 240-volt circuit wiring, from the panel to the charger location, if needed. There is a monthly data and service fee of $6.73 to gain access to electricity pricing that can save you money on off-peak charging.  You will be responsible for the maintenance of the charger. Currently, other Level 2 chargers are not compatible with the program."
                                             description="What if I already own a Level 2 charger answer"
                                             values={{
                                                  link: (
                                                       <a href="mailto:ElectricVehicles@xcelenergy.com">
                                                            {" "}
                                                            {/*faqAlreadyOwnLevelTwoCharger_Answer_MN_link_URL*/}
                                                            <FormattedMessage
                                                                 id="faqAlreadyOwnLevelTwoCharger_Answer_MN_link_text"
                                                                 defaultMessage="email us"
                                                                 description="email us"
                                                            />
                                                       </a>
                                                  ),
                                             }}
                                        />
                                   </p>
                              </div>
                         ),
                         applicableTo: [EV_ACCELERATE_AT_HOME_MINNESOTA],
                         id: "faqAlreadyOwnLevelTwoChargerMN",
                    },
                    {
                         headLine: (
                              <span className="headline">
                                   <FormattedMessage
                                        id="faqAlreadyOwnLevelTwoCharger"
                                        defaultMessage="What if I already own a Level 2 charger?"
                                        description="What if I already own a Level 2 charger?"
                                   />
                              </span>
                         ),
                         body: (
                              <div>
                                   <p>
                                        <FormattedMessage
                                             id="faqAlreadyOwnLevelTwoChargerAnswer"
                                             defaultMessage="EV Accelerate At Home provides a lifetime warranty for a Level 2 charger and could be a great option if you need more than one charger. With your existing charger, you may be eligible to participate in Optimize Your Charge."
                                             description="What if I already own a Level 2 charger? Answer"
                                             values={{
                                                  link: (
                                                       <a href="/optimize-your-charge">
                                                            <FormattedMessage
                                                                 id="optimizeYourCharge"
                                                                 defaultMessage="Optimize Your Charge"
                                                                 description="Optimize Your Charge"
                                                            />
                                                       </a>
                                                  ),
                                             }}
                                        />
                                   </p>
                              </div>
                         ),
                         applicableTo: [
                              //      EV_ACCELERATE_AT_HOME_COLORADO,
                              //      EV_ACCELERATE_AT_HOME_NEW_MEXICO,
                         ],
                         id: "faqAlreadyOwnLevelTwoCharger",
                    },
                    {
                         headLine: (
                              <span className="headline">
                                   <FormattedMessage
                                        id="whereFindEVID"
                                        defaultMessage="Where do I find my charger's ID or serial number?"
                                        description="Where do I find my charger's ID or serial number?"
                                   />
                              </span>
                         ),
                         body: (
                              <div>
                                   <p>
                                        <a href="https://www.xcelenergy.com/staticfiles/xe-responsive/Archive/Customer Resource_Finding CPSN_JBID_XcelMeter.pdf">
                                             <FormattedMessage
                                                  id="whereFindEVIDHere"
                                                  defaultMessage="Here "
                                                  description="Here "
                                             />
                                        </a>
                                        <FormattedMessage
                                             id="whereFindEVIDAnswer1"
                                             defaultMessage="is a guide with instructions on how to find your ChargePoint Home Flex serial number or Enel X Juice Box ID number and your meter number."
                                             description="is a guide with instructions on how to find your ChargePoint Home Flex serial number or Enel X Juice Box ID number and your meter number."
                                        />
                                   </p>
                                   <p>
                                        <FormattedMessage
                                             id="whereFindEVIDAnswer2"
                                             defaultMessage="If you are still having issues finding this information, please contact an Electric Vehicle Advisor at 800.895.4999."
                                             description="If you are still having issues finding this information, please contact an Electric Vehicle Advisor at 800.895.4999."
                                        />
                                   </p>
                              </div>
                         ),
                         applicableTo: [
                              EV_ACCELERATE_AT_HOME_MINNESOTA,
                              EV_ACCELERATE_AT_HOME_NEW_MEXICO,
                              EV_ACCELERATE_AT_HOME_COLORADO,
                              EV_ACCELERATE_AT_HOME_WISCONSIN,
                              OPTIMIZE_YOUR_CHARGE_COLORADO,
                              OPTIMIZE_YOUR_CHARGE_NEW_MEXICO,
                              OPTIMIZE_YOUR_CHARGE_MINNESOTA,
                         ],
                         id: "whereFindEVID",
                    },
                    {
                         headLine: (
                              <span className="headline">
                                   <FormattedMessage
                                        id="faqIncludedInMaintenanceAndWarranty"
                                        defaultMessage="What is included in charger maintenance and warranty?"
                                        description="What is included in charger maintenance and warranty?"
                                   />
                              </span>
                         ),
                         body: (
                              <div>
                                   <p>
                                        <FormattedMessage
                                             id="faqIncludedInMaintenanceAndWarrantyAnswer"
                                             defaultMessage="{xcelEnergyCharger} If you rent the charger from us and stay in the program, ongoing maintenance is included in your monthly fee and you are eligible for a lifetime warranty for your charger.
                {lineBreak}{customerOwnedCharger}: Maintenance is not included."
                                             description="What is included in charger maintenance and warranty? Answer"
                                             values={{
                                                  lineBreak: <br />,
                                                  xcelEnergyCharger: <b>Xcel Energy-Provided Charger:</b>,
                                                  customerOwnedCharger: <b>Customer-Owned Charger:</b>,
                                             }}
                                        />
                                   </p>
                              </div>
                         ),
                         applicableTo: [EV_ACCELERATE_AT_HOME_COLORADO, EV_ACCELERATE_AT_HOME_NEW_MEXICO],
                         id: "faqIncludedInMaintenanceAndWarranty",
                    },
                    {
                         headLine: (
                              <span className="headline">
                                   <FormattedMessage
                                        id="faq_ChargerMalfunctions_MN/WI"
                                        defaultMessage="What if my charger malfunctions?"
                                        description="What if my charger malfunctions?"
                                   />
                              </span>
                         ),
                         body: (
                              <div>
                                   <p>
                                        <FormattedMessage
                                             id="faq_ChargerMalfunctions_Answer_MN/WI_2"
                                             defaultMessage="A common fix for most issues is to turn the charger on and off. Start by turning the power to the charger off by flicking the breaker switch in your electric panel. Wait 5 minutes, then turn the power back on. Your charger should automatically reconnect to your Wi-Fi and resume charging as originally scheduled.{lineBreak}
                  {xcelEnergyCharger}: If this troubleshooting process does not work, please contact us at {link1} then contact the charger manufacturer at the phone number below. Describe the issue and indicate that you are participating in the Xcel Energy EV Accelerate At Home program. The manufacturer will help troubleshoot and work with you to identify the resolution. Please take note of the ticket number that you receive from the manufacturer in case that information is needed in the future. {lineBreak} {customerOwnedCharger} Contact your manufacturer at the number below, they will help troubleshoot and will work with you to identify the resolution. Xcel Energy does not provide maintenance for your charger.  {lineBreak} {link2} {link3}{lineBreak} {link4} {link5}"
                                             description="A common fix for most issues is to turn the charger on and off"
                                             values={{
                                                  lineBreak: <br />,
                                                  link1: (
                                                       <a
                                                            href="mailto:ElectricVehicles@xcelenergy.com" //ElectricVehicles@xcelenergy.com_URL
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                       >
                                                            <FormattedMessage
                                                                 id="ElectricVehicles@xcelenergy.com_text"
                                                                 defaultMessage="ElectricVehicles@xcelenergy.com"
                                                            />
                                                       </a>
                                                  ),
                                                  link2: (
                                                       <a
                                                            href="https://www.chargepoint.com/drivers/home/resource/" //ChargePoint_support_URL
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                       >
                                                            <FormattedMessage
                                                                 id="ChargePoint_support_text"
                                                                 defaultMessage="ChargePoint Support"
                                                            />
                                                       </a>
                                                  ),
                                                  link3: (
                                                       <a
                                                            href="tel:%20877-850-4562" //ChargePoint_support_phone_number_URL
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                       >
                                                            <FormattedMessage
                                                                 id="ChargePoint_support_phone_number_text"
                                                                 defaultMessage="877-850-4562"
                                                            />
                                                       </a>
                                                  ),
                                                  link4: (
                                                       <a
                                                            href="https://support-emobility.enelx.com/hc/en-us" //EnelX_support_URL
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                       >
                                                            <FormattedMessage
                                                                 id="EnelX_support_text"
                                                                 defaultMessage="Enel X Support"
                                                            />
                                                       </a>
                                                  ),
                                                  link5: (
                                                       <a
                                                            href="tel:%20844-584-2329" //EnelX_support_phone_number_URL
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                       >
                                                            <FormattedMessage
                                                                 id="EnelX_support_phone_number_text"
                                                                 defaultMessage="844-584-2329"
                                                            />
                                                       </a>
                                                  ),
                                                  xcelEnergyCharger: <strong>Xcel Energy-Provided Charger</strong>,
                                                  customerOwnedCharger: <strong>Customer-Owned Charger:</strong>,
                                             }}
                                        />
                                   </p>
                              </div>
                         ),
                         applicableTo: [EV_ACCELERATE_AT_HOME_WISCONSIN, EV_ACCELERATE_AT_HOME_MINNESOTA],
                         id: "faqChargerMalfunctions",
                    },
                    {
                         headLine: (
                              <span className="headline">
                                   <FormattedMessage
                                        id={`faqWhyAreOnly2LevelChargers${faqKey.split("-")[1]}`}
                                        defaultMessage="Why are only two Level 2 chargers eligible for this program?"
                                        values={{
                                             stateName: region,
                                        }}
                                   />
                              </span>
                         ),
                         body: (
                              <div>
                                   <p>
                                        <FormattedMessage
                                             id={`faqWhyAreOnly2LevelChargers${faqKey.split("-")[1]}Answer`}
                                             defaultMessage="Through a formal vetting and approval process, we selected chargers that fulfilled program requirements of providing access to your charging data while keeping your bills low. We use this charging data to bill your EV energy use at the discounted rate for off-peak charging. As technologies change and improve, we will continue to explore additional options for customers to participate in this program."
                                             values={{
                                                  stateName: region,
                                             }}
                                        />
                                   </p>
                              </div>
                         ),
                         applicableTo: [
                              // EV_ACCELERATE_AT_HOME_MINNESOTA,
                              EV_ACCELERATE_AT_HOME_WISCONSIN,
                              // EV_ACCELERATE_AT_HOME_COLORADO,
                              //EV_ACCELERATE_AT_HOME_NEW_MEXICO,
                              // OPTIMIZE_YOUR_CHARGE_COLORADO,
                              OPTIMIZE_YOUR_CHARGE_NEW_MEXICO,
                              OPTIMIZE_YOUR_CHARGE_MINNESOTA,
                         ],
                         id: "faqWhyAreOnly2LevelChargers",
                    },
                    {
                         header: (
                              <span className="header">
                                   <FormattedMessage
                                        id="faqInstallationProcessTitle"
                                        defaultMessage="INSTALLATION OR INSPECTION PROCESS"
                                   />
                              </span>
                         ),
                         headLine: (
                              <span className="headline">
                                   <FormattedMessage
                                        id="faqWhatIsIncludedInstallationService"
                                        defaultMessage="What is included in the installation service with the Xcel Energy-provided charger?"
                                        description="What is included in the installation service with the Xcel Energy-provided charger?"
                                   />
                              </span>
                         ),
                         body: (
                              <div>
                                   <p>
                                        <FormattedMessage
                                             id="faqWhatIsIncludedInstallationServiceAnswer_MN/WI"
                                             defaultMessage="The inspection service in this program only includes the set-up and hardwiring of the Level 2 charger and verifying charger eligibility. This service does not include permit fees or any additional electrical work, such as circuit wiring or any circuit rework needed to ensure charger compatibility, or panel upgrades, which may be required to set up your charging location for a 240-volt circuit."
                                             description="The inspection service in this program only includes the set-up and hardwiring of the Level 2 charger and verifying charger eligibility. This service does not include permit fees or any additional electrical work, such as circuit wiring or any circuit rework needed to ensure charger compatibility, or panel upgrades, which may be required to set up your charging location for a 240-volt circuit."
                                        />
                                   </p>
                              </div>
                         ),
                         applicableTo: [
                              EV_ACCELERATE_AT_HOME_WISCONSIN,
                              EV_ACCELERATE_AT_HOME_MINNESOTA,
                              EV_SUBSCRIPTION_SERVICE_PILOT_MINNESOTA,
                         ],
                         id: "faqWhatIsIncludedInstallationService",
                    },
                    {
                         headLine: (
                              <span className="headline">
                                   <FormattedMessage
                                        id="faq_included_in_inspection_service_title"
                                        defaultMessage="What is included in the inspection service with customer-owned charger? "
                                        description="What is included in the inspection service with customer-owned charger? "
                                   />
                              </span>
                         ),
                         body: (
                              <div>
                                   <p>
                                        <FormattedMessage
                                             id="faq_CircuitWiring_Answer_MN/WI_2"
                                             defaultMessage="The inspection service in this program only includes the set-up and hardwiring of the Level 2 charger and verifying charger eligibility. This service does not include permit fees or any additional electrical work, such as circuit wiring or any circuit rework needed to ensure charger compatibility, or panel upgrades, which may be required to set up your charging location for a 240-volt circuit."
                                             description=""
                                        />
                                   </p>
                              </div>
                         ),
                         applicableTo: [EV_ACCELERATE_AT_HOME_WISCONSIN, EV_SUBSCRIPTION_SERVICE_PILOT_MINNESOTA],
                         id: "faqInspectionService",
                    },
                    {
                         headLine: (
                              <span className="headline">
                                   <FormattedMessage
                                        id="faq_electrician_qualifications_title"
                                        defaultMessage="What if the electrician determines my charging station does not qualify?"
                                        description="What if the electrician determines my charging station does not qualify?"
                                   />
                              </span>
                         ),
                         body: (
                              <div>
                                   <p>
                                        <FormattedMessage
                                             id="faq_electrician_qualifications_answer"
                                             defaultMessage="If a qualified electrician inspects your charger and determines it does not qualify, the electrician and/or Xcel Energy will notify you on next steps you would need to take to participate in this program. You can still rent an Xcel Energy charging station if yours does not qualify."
                                             description=""
                                        />
                                   </p>
                              </div>
                         ),
                         applicableTo: [EV_ACCELERATE_AT_HOME_WISCONSIN, EV_SUBSCRIPTION_SERVICE_PILOT_MINNESOTA],
                         id: "faqElectricianQualifications",
                    },
                    {
                         headLine: (
                              <span className="headline">
                                   <FormattedMessage
                                        id="faq_CircuitWiring_MN/WI"
                                        defaultMessage="How much does additional circuit wiring or panel upgrades typically cost?"
                                        description="How much does additional circuit wiring or panel upgrades typically cost?"
                                   />
                              </span>
                         ),
                         body: (
                              <div>
                                   <p>
                                        <FormattedMessage
                                             id="faq_CircuitWiring_Answer_MN/WI"
                                             defaultMessage="The program cost does not include additional circuit wiring that may be required to add a new 240-volt outlet. Those costs depend on your home’s unique electrical setup and wiring.{lineBreak}

                  Our electricians can help you understand the actual costs of any additional work unique to your home. To complete any additional electrical work, you can use our electrician and pay them directly or choose another electrician."
                                             description="The program cost does not include additional circuit wiring"
                                             values={{
                                                  lineBreak: <br />,
                                             }}
                                        />
                                   </p>
                              </div>
                         ),
                         applicableTo: [
                              EV_ACCELERATE_AT_HOME_WISCONSIN,
                              EV_ACCELERATE_AT_HOME_MINNESOTA,
                              EV_SUBSCRIPTION_SERVICE_PILOT_MINNESOTA,
                         ],
                         id: "faqCircuitWiring",
                    },
                    {
                         headLine: (
                              <span className="headline">
                                   <FormattedMessage
                                        id="faq_WhatsIncluded_MN/WI"
                                        defaultMessage="What is included in the inspection service with customer-owned charger?"
                                        description="What is included in the inspection service with customer-owned charger?"
                                   />
                              </span>
                         ),
                         body: (
                              <div>
                                   <p>
                                        <FormattedMessage
                                             id="faq_WhatsIncluded_Answer_MN/WI"
                                             defaultMessage="The inspection service in this program only includes the set-up and hardwiring of the Level 2 charger and verifying charger eligibility. This service does not include permit fees or any additional electrical work, such as circuit wiring or any circuit rework needed to ensure charger compatibility, or panel upgrades, which may be required to set up your charging location for a 240-volt circuit."
                                        />
                                   </p>
                              </div>
                         ),
                         applicableTo: [EV_ACCELERATE_AT_HOME_MINNESOTA],
                         id: "faqWhatsIncluded",
                    },
                    {
                         headLine: (
                              <span className="headline">
                                   <FormattedMessage
                                        id="faq_electricianqualify_MN/WI"
                                        defaultMessage="What if the electrician determines my charging station does not qualify?"
                                        description="What if the electrician determines my charging station does not qualify?"
                                   />
                              </span>
                         ),
                         body: (
                              <div>
                                   <p>
                                        <FormattedMessage
                                             id="faq_electricianqualify_Answer_MN/WI"
                                             defaultMessage="If a qualified electrician inspects your charger and determines it does not qualify, the electrician and/or Xcel Energy will notify you on next steps you would need to take to participate in this program. You can still rent an Xcel Energy charging station if yours does not qualify."
                                        />
                                   </p>
                              </div>
                         ),
                         applicableTo: [EV_ACCELERATE_AT_HOME_MINNESOTA],
                         id: "faqElectricianQualify",
                    },
                    {
                         headLine: (
                              <span className="headline">
                                   <FormattedMessage
                                        id="faq_customerProvidedBenefits_MN/WI"
                                        defaultMessage="If I'm accepted into the customer-provided option, when will my benefits start?"
                                        description="If I'm accepted into the customer-provided option, when will my benefits start?"
                                   />
                              </span>
                         ),
                         body: (
                              <div>
                                   <p>
                                        <FormattedMessage
                                             id="faq_customerProvidedBenefits_Answer_MN/WI"
                                             defaultMessage="Once you receive an email confirmation that you are accepted into EV Accelerate At Home with your own charger, an electrician from Xcel Energy's network will contact you within 2 business days to schedule the inspection within 4 weeks. You or your authorized representative (over 18) must be present during the appointment. Once your charger, wiring, and data have been reviewed and accepted your benefits will start. Your charger eligibility may be confirmed upon your first billing cycle (4-6 weeks from the inspection visit).  See FAQ – Issues with Inspection of my customer-owned charger if there are any issues during the inspection, Xcel Energy cannot accept your application at this time.{lineBreak}
                      These timelines are rough estimates and are subject to change. If you are also installing a separate meter and service for the charger, this may take 6 weeks or more."
                                             values={{
                                                  lineBreak: <br />,
                                             }}
                                        />
                                   </p>
                              </div>
                         ),
                         applicableTo: [EV_ACCELERATE_AT_HOME_MINNESOTA],
                         id: "faqcustomerProvidedBenefits",
                    },
                    {
                         headLine: (
                              <span className="headline">
                                   <FormattedMessage
                                        id="faqIfI'mAccepted_MN/WI"
                                        defaultMessage="If I'm accepted into the program, when will my charging station be installed?"
                                        description="If I'm accepted into the program, when will my charging station be installed?"
                                   />
                              </span>
                         ),
                         body: (
                              <div>
                                   <p>
                                        <FormattedMessage
                                             id="faqIfI'mAccepted_Answer_MN/WI"
                                             defaultMessage="Once you receive email confirmation that you are accepted into EV Accelerate At Home, an electrician from Xcel Energy’s network will contact you within 2 business days to schedule installation within 4 weeks. You or your authorized representative (over 18) must be present during the appointment. These timelines are rough estimates and are subject to change. If you are also installing a separate meter and service for the charger, this may take 6 weeks or more."
                                             description="Once you receive email confirmation that you are accepted into EV Accelerate At Home, an electrician from Xcel Energy’s network will contact you within 2 business days to schedule installation within 4 weeks. You or your authorized representative (over 18) must be present during the appointment. These timelines are rough estimates and are subject to change. If you are also installing a separate meter and service for the charger, this may take 6 weeks or more."
                                        />
                                   </p>
                              </div>
                         ),
                         applicableTo: [
                              EV_ACCELERATE_AT_HOME_WISCONSIN,
                              EV_ACCELERATE_AT_HOME_MINNESOTA,
                              EV_SUBSCRIPTION_SERVICE_PILOT_MINNESOTA,
                         ],
                         id: "faqIfImAccepted",
                    },
                    {
                         headLine: (
                              <span className="headline">
                                   <FormattedMessage
                                        id="faqIfImAccepted_CustomerProvided_title"
                                        defaultMessage="If I'm accepted into the customer-provided option, when will my benefits start?"
                                        description="If I'm accepted into the customer-provided option, when will my benefits start? "
                                   />
                              </span>
                         ),
                         body: (
                              <div>
                                   <p>
                                        <FormattedMessage
                                             id="faqIfImAccepted_CustomerProvided_answer"
                                             defaultMessage="Once you receive an email confirmation that you are accepted into EV Accelerate At Home with your own charger, an electrician from Xcel Energy’s network will contact you within 2 business days to schedule the inspection within 4 weeks. You or your authorized representative (over 18) must be present during the appointment. Once your charger, wiring, and data have been reviewed and accepted your benefits will start. Your charger eligibility may be confirmed upon your first billing cycle (4-6 weeks from the inspection visit).  See FAQ – Issues with Inspection of my customer-owned charger if there are any issues during the inspection, Xcel Energy cannot accept your application at this time.
                      These timelines are rough estimates and are subject to change. If you are also installing a separate meter and service for the charger, this may take 6 weeks or more."
                                             description="Once you receive an email confirmation that you are accepted into EV Accelerate At Home with your own charger, an electrician from Xcel Energy’s network will contact you within 2 business days to schedule the inspection within 4 weeks. You or your authorized representative (over 18) must be present during the appointment. Once your charger, wiring, and data have been reviewed and accepted your benefits will start. Your charger eligibility may be confirmed upon your first billing cycle (4-6 weeks from the inspection visit).  See FAQ – Issues with Inspection of my customer-owned charger if there are any issues during the inspection, Xcel Energy cannot accept your application at this time.
                      These timelines are rough estimates and are subject to change. If you are also installing a separate meter and service for the charger, this may take 6 weeks or more."
                                        />
                                   </p>
                              </div>
                         ),
                         applicableTo: [EV_ACCELERATE_AT_HOME_WISCONSIN, EV_SUBSCRIPTION_SERVICE_PILOT_MINNESOTA],
                         id: "faqIfImAccepted_CustomerProvided",
                    },
                    {
                         header: (
                              <span className="header">
                                   <FormattedMessage
                                        id="faqInstallationProcessTitle"
                                        defaultMessage="INSTALLATION OR INSPECTION PROCESS"
                                   />
                              </span>
                         ),
                         headLine: (
                              <span className="headline">
                                   <FormattedMessage
                                        id={`faqWorkWithElectricians${faqKey.split("-")[1]}`}
                                        defaultMessage="Do you work with electricians in {stateName}?"
                                        description="Do you work with electricians in Colorado?"
                                        values={{
                                             stateName: region,
                                        }}
                                   />
                              </span>
                         ),
                         body: (
                              <RenderByState
                                   stateName={region}
                                   options={{
                                        default: (
                                             <div>
                                                  <p
                                                       style={{
                                                            fontWeight: 400,
                                                       }}
                                                  >
                                                       <FormattedMessage
                                                            id={`faqWorkWithElectriciansAnswer`}
                                                            defaultMessage="We work with skilled electricians who are experienced in
                        installing Level 2 chargers for electric vehicles. "
                                                            description="Do you work with electricians in Colorado? Answer"
                                                       />
                                                       &nbsp;
                                                       <a
                                                            style={{
                                                                 fontWeight: "bold",
                                                            }}
                                                            href={`${pdfLinks[region]}`}
                                                       >
                                                            <FormattedMessage
                                                                 id={`faqWorkWithElectriciansAnswerLink`}
                                                                 defaultMessage="Here are the electricians we partner with in {stateName}"
                                                                 values={{
                                                                      stateName: region,
                                                                 }}
                                                            />
                                                       </a>
                                                       <p>
                                                            <FormattedMessage
                                                                 id={`faqWorkWithElectriciansAnswer2`}
                                                                 defaultMessage="Customers can use any Colorado licensed electrician and are not required to use a partner electrician. "
                                                                 description="Customers can use any Colorado licensed electrician and are not required to use a partner electrician. "
                                                            />
                                                       </p>
                                                  </p>
                                             </div>
                                        ),
                                        [NEW_MEXICO]: (
                                             <div>
                                                  <p>
                                                       <FormattedMessage
                                                            id={`faqWorkWithElectricians${faqKey.split("-")[1]}Answer`}
                                                            defaultMessage="We work with skilled electricians who are experienced in installing Level 2 chargers for electric vehicles. Here are the electricians we partner with in New Mexico:"
                                                            description="Do you work with electricians in New Mexico? Answer"
                                                       />
                                                  </p>
                                                  <ul>
                                                       <li>
                                                            White Star Electric
                                                            <br />
                                                            15849 Harvest Rd.
                                                            <br />
                                                            Brighton, CO 80603
                                                            <br />
                                                            <a href="mailto:office@whitestarteam.com">
                                                                 office@whitestarteam.com
                                                            </a>
                                                            <br />
                                                            720-534-1901
                                                       </li>
                                                  </ul>
                                             </div>
                                        ),
                                   }}
                              />
                         ),
                         applicableTo: [
                              //EV_ACCELERATE_AT_HOME_MINNESOTA,
                              //      EV_ACCELERATE_AT_HOME_NEW_MEXICO,
                              //EV_ACCELERATE_AT_HOME_WISCONSIN,
                              HOME_WIRING_REBATE_COLORADO,
                              EV_ACCELERATE_AT_HOME_COLORADO,
                              OPTIMIZE_YOUR_CHARGE_COLORADO,
                              OPTIMIZE_YOUR_CHARGE_NEW_MEXICO,
                              OPTIMIZE_YOUR_CHARGE_MINNESOTA,
                         ],
                         id: "faqWorkWithElectricians",
                    },
                    {
                         headLine: (
                              <span className="headline">
                                   <FormattedMessage
                                        id={`faqWorkWithElectricians${faqKey.split("-")[1]}`}
                                        defaultMessage="Do you work with electricians in {stateName}?"
                                        description="Do you work with electricians in Colorado?"
                                        values={{
                                             stateName: region,
                                        }}
                                   />
                              </span>
                         ),
                         body: (
                              <RenderByState
                                   stateName={region}
                                   options={{
                                        default: (
                                             <div>
                                                  <p
                                                       style={{
                                                            fontWeight: 400,
                                                       }}
                                                  >
                                                       <FormattedMessage
                                                            id={`faqWorkWithElectriciansAnswer`}
                                                            defaultMessage="We work with skilled electricians who are experienced in
                        installing Level 2 chargers for electric vehicles. "
                                                            description="Do you work with electricians in Colorado? Answer"
                                                       />

                                                       <a
                                                            style={{
                                                                 fontWeight: "bold",
                                                            }}
                                                            href={`${pdfLinks[region]}`}
                                                       >
                                                            <FormattedMessage
                                                                 id={`faqWorkWithElectriciansAnswerLink`}
                                                                 defaultMessage="Here are the electricians we partner with in {stateName}"
                                                                 values={{
                                                                      stateName: "Wisconsin",
                                                                 }}
                                                            />
                                                            .
                                                       </a>
                                                  </p>
                                             </div>
                                        ),
                                        [NEW_MEXICO]: (
                                             <div>
                                                  <p>
                                                       <FormattedMessage
                                                            id={`faqWorkWithElectricians${faqKey.split("-")[1]}Answer`}
                                                            defaultMessage="We work with skilled electricians who are experienced in installing Level 2 chargers for electric vehicles. Here are the electricians we partner with in New Mexico:"
                                                            description="Do you work with electricians in New Mexico? Answer"
                                                       />
                                                  </p>
                                                  <ul>
                                                       <li>
                                                            White Star Electric
                                                            <br />
                                                            15849 Harvest Rd.
                                                            <br />
                                                            Brighton, CO 80603
                                                            <br />
                                                            <a href="mailto:office@whitestarteam.com">
                                                                 office@whitestarteam.com
                                                            </a>
                                                            <br />
                                                            720-534-1901
                                                       </li>
                                                  </ul>
                                             </div>
                                        ),
                                   }}
                              />
                         ),
                         applicableTo: [
                              //EV_ACCELERATE_AT_HOME_MINNESOTA,
                              // EV_ACCELERATE_AT_HOME_NEW_MEXICO,
                              EV_ACCELERATE_AT_HOME_WISCONSIN,
                              // HOME_WIRING_REBATE_COLORADO,
                              // EV_ACCELERATE_AT_HOME_COLORADO,
                              // OPTIMIZE_YOUR_CHARGE_COLORADO,
                              // OPTIMIZE_YOUR_CHARGE_NEW_MEXICO,
                              // OPTIMIZE_YOUR_CHARGE_MINNESOTA
                         ],
                         id: "faqWorkWithElectricians",
                    },

                    {
                         headLine: (
                              <span className="headline">
                                   <FormattedMessage
                                        id={`faqWorkWithElectricians${faqKey.split("-")[1]}`}
                                        defaultMessage="Do you work with electricians in {stateName}?"
                                        description="Do you work with electricians in Colorado?"
                                        values={{
                                             stateName: region,
                                        }}
                                   />
                              </span>
                         ),
                         body: (
                              <RenderByState
                                   stateName={region}
                                   options={{
                                        default: (
                                             <div>
                                                  <p>
                                                       <FormattedMessage
                                                            id={`faqWorkWithElectriciansAnswerLink_mn_first`}
                                                            defaultMessage="We work with skilled electricians who are experienced in installing Level 2 chargers for electric vehicles."
                                                       />
                                                       &nbsp;
                                                       <a href={`${pdfLinks[region]}`}>
                                                            <FormattedMessage
                                                                 id={`faqWorkWithElectriciansAnswerLink_mn`}
                                                                 defaultMessage="Here are the electricians we partner with in Minnesota"
                                                                 values={{
                                                                      stateName: region,
                                                                 }}
                                                            />
                                                       </a>
                                                  </p>
                                             </div>
                                        ),
                                   }}
                              />
                         ),
                         applicableTo: [
                              EV_ACCELERATE_AT_HOME_MINNESOTA,
                              // EV_ACCELERATE_AT_HOME_NEW_MEXICO,
                              //EV_ACCELERATE_AT_HOME_WISCONSIN,
                              // HOME_WIRING_REBATE_COLORADO,
                              // EV_ACCELERATE_AT_HOME_COLORADO,
                              // OPTIMIZE_YOUR_CHARGE_COLORADO,
                              // OPTIMIZE_YOUR_CHARGE_NEW_MEXICO,
                              // OPTIMIZE_YOUR_CHARGE_MINNESOTA
                         ],
                         id: "faqWorkWithElectricians",
                    },

                    {
                         header: (
                              <span className="header">
                                   <FormattedMessage
                                        id="faqProgramInformationTitle"
                                        defaultMessage="Program Information"
                                   />
                              </span>
                         ),
                         headLine: (
                              <span className="headline">
                                   <FormattedMessage
                                        id={`faqWhereTermsConditions-3`}
                                        defaultMessage="Where can I find the Terms & Conditions for EV Accelerate At Home?"
                                        description="Where can I find the Terms & Conditions for EV Accelerate At Home?"
                                        values={{
                                             stateName: region,
                                        }}
                                   />
                              </span>
                         ),
                         body: (
                              <div>
                                   <p>
                                        <FormattedMessage
                                             id="faqWhereTermsConditions_answer"
                                             defaultMessage="You can find program Terms & Conditions {link}"
                                             description="You can find program Terms & Conditions {link}"
                                             values={{
                                                  link: (
                                                       <a
                                                            href={
                                                                 region === WISCONSIN
                                                                      ? "https://www.xcelenergy.com/staticfiles/xe-responsive/Archive/23-09-501_WI-EVAH_TandC_P04.pdf"
                                                                      : region === MINNESOTA
                                                                      ? "https://my.xcelenergy.com/MyAccount/NCC_Agreement?idList=ka08b0000009ngbAAA"
                                                                      : region === NEW_MEXICO
                                                                      ? "https://www.xcelenergy.com/staticfiles/xe-responsive/Marketing/NM-EV_Accelerate_At_Home_Optimize_Your_Charge_Application_Terms_Conditions.pdf"
                                                                      : region === COLORADO
                                                                      ? "https://my.xcelenergy.com/MyAccount/NCC_Agreement?idList=ka02R000000Qe91QAC"
                                                                      : "https://www.xcelenergy.com/staticfiles/xe-responsive/Marketing/Optimize%20Your%20Charge%20Terms%20%20Conditions%20Residential%20and%20Commerical%20MN.pdf"
                                                            }
                                                       >
                                                            <FormattedMessage id="hereLink" defaultMessage="here" />
                                                       </a>
                                                  ),
                                             }}
                                        />
                                   </p>
                              </div>
                         ),
                         applicableTo: [
                              EV_ACCELERATE_AT_HOME_MINNESOTA,
                              EV_ACCELERATE_AT_HOME_NEW_MEXICO,
                              EV_ACCELERATE_AT_HOME_WISCONSIN,
                              // HOME_WIRING_REBATE_COLORADO,
                              EV_ACCELERATE_AT_HOME_COLORADO,
                              // OPTIMIZE_YOUR_CHARGE_COLORADO,
                              // OPTIMIZE_YOUR_CHARGE_NEW_MEXICO,
                              // OPTIMIZE_YOUR_CHARGE_MINNESOTA
                         ],
                         id: "faqTermsAndConditions",
                    },

                    {
                         headLine: (
                              <span className="headline">
                                   <FormattedMessage
                                        id="faqChargingStationBeInstalled"
                                        defaultMessage="When will my charging station be installed?"
                                        description="When will my charging station be installed?"
                                   />
                              </span>
                         ),
                         body: (
                              <div>
                                   <p>
                                        <FormattedMessage
                                             id="faqChargingStationBeInstalledAnswer"
                                             defaultMessage="Once you receive email confirmation that you are accepted into the program, an electrician from Xcel Energy’s network will contact you within 2 business days to schedule installation, typically within 4 weeks. These timelines are rough estimates and subject to change. If you are also installing a separate meter and service for the charger, this may take 6 weeks or more."
                                             description="When will my charging station be installed? Answer"
                                        />
                                   </p>
                              </div>
                         ),
                         applicableTo: [EV_ACCELERATE_AT_HOME_COLORADO, EV_ACCELERATE_AT_HOME_NEW_MEXICO],
                         id: "faqChargingStationBeInstalled",
                    },
                    {
                         headLine: (
                              <span className="headline">
                                   <FormattedMessage
                                        id="faqWhatIsIncludedInstallationService"
                                        defaultMessage="What is included in the installation service?"
                                        description="What is included in the installation service?"
                                   />
                              </span>
                         ),
                         body: (
                              <div>
                                   <p>
                                        <FormattedMessage
                                             id="faqWhatIsIncludedInstallationServiceAnswer_CO/NM"
                                             defaultMessage="Our Level 2 charger installation service includes setup and hardwiring. This service does not include any additional electrical work, which may be required at your charging location."
                                             description="What is included in the installation service? Answer"
                                        />
                                   </p>
                                   <p>
                                        <FormattedMessage
                                             id="faqWhatIsIncludedInstallationServiceAnswerTwo"
                                             defaultMessage="Our electricians can perform additional required home wiring work for you at the time of installation and will provide you a quote prior to that work. They can also help you save up front with our Home Wiring Rebate. You can choose your own electrician outside of our network to perform the home wiring work, but they cannot offer the home wiring rebate up front."
                                             description="What is included in the installation service? Answer Two"
                                             values={{
                                                  link: (
                                                       <a
                                                            href="/home-wiring-rebate"
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                       >
                                                            <FormattedMessage
                                                                 id="faqRebateCOHomeWiringRebate"
                                                                 defaultMessage="Home Wiring Rebate"
                                                                 description="Home Wiring Rebate"
                                                            />
                                                       </a>
                                                  ),
                                                  bold: (
                                                       <b>
                                                            <FormattedMessage
                                                                 id="theyCanAlsoHelpSave"
                                                                 defaultMessage="They can also help you save up front with our"
                                                                 description="They can also help you save up front with our"
                                                            />
                                                       </b>
                                                  ),
                                             }}
                                        />
                                   </p>
                              </div>
                         ),
                         applicableTo: [EV_ACCELERATE_AT_HOME_COLORADO, EV_ACCELERATE_AT_HOME_NEW_MEXICO],
                         id: "faqWhatIsIncludedInstallationService",
                    },

                    {
                         headLine: (
                              <span className="headline">
                                   <FormattedMessage
                                        id="faqAdditionalWiringPanelUpgradeCost"
                                        defaultMessage="How much do additional circuit wiring or panel upgrades typically cost?"
                                        description="How much do additional circuit wiring or panel upgrades typically cost?"
                                   />
                              </span>
                         ),
                         body: (
                              <div>
                                   <p>
                                        <FormattedMessage
                                             id="faqAdditionalWiringPanelUpgradeCostAnswer"
                                             defaultMessage="The program cost does not include additional circuit wiring that may be required to add a new 240-volt circuit. Those costs depend on your home’s unique electrical setup and wiring."
                                             description="The program cost does not include additional circuit wiring that may be required to add a new 240-volt circuit. Those costs depend on your home’s unique electrical setup and wiring."
                                        />
                                   </p>
                                   <p>
                                        <FormattedMessage
                                             id="faqAdditionalWiringPanelUpgradeCostAnswerTwo"
                                             defaultMessage="Our electricians can perform additional required home wiring work for you at the time of installation and will provide you a quote prior to that work. They can also help you save up front with our Home Wiring Rebate. You can choose your own electrician outside of our network to perform the home wiring work, but they cannot offer the home wiring rebate up front."
                                             description="Wiring Panel Upgrage Cost Answer Two"
                                             values={{
                                                  link: (
                                                       <a
                                                            href="/home-wiring-rebate"
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                       >
                                                            <FormattedMessage
                                                                 id="faqRebateCOHomeWiringRebate"
                                                                 defaultMessage="Home Wiring Rebate"
                                                                 description="Home Wiring Rebate"
                                                            />
                                                       </a>
                                                  ),
                                                  bold: (
                                                       <b>
                                                            <FormattedMessage
                                                                 id="theyCanAlsoHelpSave"
                                                                 defaultMessage="They can also help you save up front with our"
                                                                 description="They can also help you save up front with our"
                                                            />
                                                       </b>
                                                  ),
                                             }}
                                        />
                                   </p>
                                   <p>
                                        <FormattedMessage
                                             id="faqAdditionalWiringPanelUpgradeCostAnswerThree"
                                             defaultMessage="To estimate potential costs for additional wiring, try our Home Wiring Estimator."
                                             description="To estimate potential costs for additional wiring, try our Home Wiring Estimator."
                                             values={{
                                                  link: (
                                                       <a href="/home-charging-advisor">
                                                            <FormattedMessage
                                                                 id="homeWiringEstimator"
                                                                 defaultMessage="Home Wiring Estimator"
                                                                 description="Home Wiring Estimator"
                                                            />
                                                       </a>
                                                  ),
                                             }}
                                        />
                                   </p>
                              </div>
                         ),
                         applicableTo: [EV_ACCELERATE_AT_HOME_COLORADO, EV_ACCELERATE_AT_HOME_NEW_MEXICO],
                         id: "faqAdditionalWiringPanelUpgradeCost",
                    },
                    {
                         header: (
                              <span className="header">
                                   <FormattedMessage
                                        id="faqProgramInformationTitle"
                                        defaultMessage="Program Information"
                                   />
                              </span>
                         ),
                         headLine: (
                              <span className="headline">
                                   <FormattedMessage
                                        id="faqElectricityPricingWork"
                                        defaultMessage="How does the electricity pricing for this program work?"
                                        description="How does the electricity pricing for this program work?"
                                   />
                              </span>
                         ),
                         body: (
                              <div>
                                   <p>
                                        <FormattedMessage
                                             id="faqElectricityPricingWorkCOAnswerOne"
                                             defaultMessage="With EV Accelerate At Home, the price of the electricity you use to charge your EV varies depending on the time of day. That means your bill will depend on how much electricity you use to charge your EV and when you charge it. (The electricity you use in your home will continue to be billed at your current rate.) Your Level 2 charger will be programmed to charge only during off-peak hours, between midnight and 8 a.m. So, you can rest assured that your EV is automatically charging during the lowest pricing period. No need to go to the garage at Midnight to plug in."
                                             description="Electricity Pricing CO Answer One"
                                        />
                                   </p>
                                   <p>
                                        <FormattedMessage
                                             id="faqElectricityPricingWorkCOAnswerTwo"
                                             defaultMessage="If you need to occasionally charge your EV during other times, you can easily adjust your charging schedule."
                                             description="Electricity Pricing CO Answer Two"
                                        />
                                   </p>
                              </div>
                         ),
                         applicableTo: [EV_ACCELERATE_AT_HOME_COLORADO, EV_ACCELERATE_AT_HOME_NEW_MEXICO],
                         id: "faqElectricityPricingWork",
                    },
                    {
                         headLine: (
                              <span className="headline">
                                   <FormattedMessage
                                        id="faqElectricityPricingWork"
                                        defaultMessage="How does the electricity pricing in this program work?"
                                        description="How does the electricity pricing in this program work?"
                                   />
                              </span>
                         ),
                         body: (
                              <div>
                                   <p>
                                        <FormattedMessage
                                             id="faqElectricityPricingWork_Answer_MN"
                                             defaultMessage="With EV Accelerate At Home, the price of the electricity you use to charge your EV varies depending on the time of day. That means your bill will depend on how much electricity you use to charge your EV and when you charge it. (The electricity you use in your home will continue to be billed at your current rate.)"
                                             description="answer"
                                        />
                                   </p>
                                   <p>
                                        <FormattedMessage
                                             id="faqElectricityPricingWork_Answer_MN2"
                                             defaultMessage="Your Level 2 charger will be programmed to charge only during off-peak hours, between midnight and 8 a.m. So, you can rest assured that your EV is automatically charging during the lowest cost time period. No need to go to the garage at midnight to plug in!"
                                             description="answer"
                                        />
                                   </p>
                                   <p>
                                        <FormattedMessage
                                             id="faqElectricityPricingWork_Answer_MN3"
                                             defaultMessage="If you need to occasionally charge your EV during other times, you can easily adjust your charging schedule. Details about adjusting the scheduled charging time are found in the FAQ “What if I need to adjust my scheduled charging time?"
                                             description="answer"
                                        />
                                   </p>
                                   <p>
                                        <FormattedMessage
                                             id="faqElectricityPricingWork_Answer_MN4"
                                             defaultMessage="If you would like more information on reading your bill, {link}."
                                             description="answer"
                                             values={{
                                                  link: (
                                                       <a
                                                            href="https://www.xcelenergy.com/staticfiles/xe-responsive/23-09-528_EV-Bill-Explainer_P02.pdf"
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                       >
                                                            <FormattedMessage
                                                                 id="billExplainer"
                                                                 defaultMessage="click here to see a Bill Explainer"
                                                                 description="click here to see a Bill Explainer"
                                                            />
                                                       </a>
                                                  ),
                                             }}
                                        />
                                   </p>
                              </div>
                         ),
                         applicableTo: [EV_ACCELERATE_AT_HOME_WISCONSIN, EV_ACCELERATE_AT_HOME_MINNESOTA],
                         id: "faqElectricityPricingWorkWi",
                    },

                    {
                         headLine: (
                              <span className="headline">
                                   <FormattedMessage
                                        id="faqEvChargingRenewableEnergy"
                                        defaultMessage="Can I charge my EV with renewable energy?"
                                        description="Can I charge my EV with renewable energy?"
                                   />
                              </span>
                         ),
                         body: (
                              <div>
                                   <p>
                                        <FormattedMessage
                                             id="faqEvChargingRenewableEnergy_Answer_MN"
                                             defaultMessage="You can enroll in one of our {link} to lower your vehicle's carbon footprint even more."
                                             description="answer"
                                             values={{
                                                  link: (
                                                       <a
                                                            href="https://co.my.xcelenergy.com/s/renewable"
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                       >
                                                            <FormattedMessage
                                                                 id="faq_renewableEnergyPrograms"
                                                                 defaultMessage="renewable energy programs"
                                                                 description=""
                                                            />
                                                       </a>
                                                  ),
                                             }}
                                        />
                                   </p>
                              </div>
                         ),
                         applicableTo: [EV_ACCELERATE_AT_HOME_MINNESOTA],
                         id: "faqEvChargingRenewableEnergy",
                    },

                    {
                         headLine: (
                              <span className="headline">
                                   <FormattedMessage
                                        id="termOfConditionOptimizeChargerMN"
                                        defaultMessage="Where can I find the Terms & Conditions of Optimize Your Charge?"
                                        description="Where can I find the Terms & Conditions of Optimize Your Charge? "
                                   />
                              </span>
                         ),
                         body: (
                              <div>
                                   <p>
                                        <FormattedMessage
                                             id={"termConditionsMN"}
                                             defaultMessage={"You can find program Terms & Conditions {link}"}
                                             description="You can find program Terms & Conditions here"
                                             values={{
                                                  link: (
                                                       <a
                                                            href={
                                                                 region === NEW_MEXICO
                                                                      ? "https://www.xcelenergy.com/staticfiles/xe-responsive/Marketing/NM-EV_Accelerate_At_Home_Optimize_Your_Charge_Application_Terms_Conditions.pdf"
                                                                      : region === COLORADO
                                                                      ? "https://my.xcelenergy.com/MyAccount/NCC_Agreement?idList=ka02R000000Qe91QAC"
                                                                      : "https://www.xcelenergy.com/staticfiles/xe-responsive/Marketing/Optimize%20Your%20Charge%20Terms%20%20Conditions%20Residential%20and%20Commerical%20MN.pdf"
                                                            }
                                                       >
                                                            <FormattedMessage id="hereLink" defaultMessage="here" />
                                                       </a>
                                                  ),
                                             }}
                                        />
                                   </p>
                              </div>
                         ),
                         applicableTo: [
                              OPTIMIZE_YOUR_CHARGE_MINNESOTA,
                              OPTIMIZE_YOUR_CHARGE_COLORADO,
                              OPTIMIZE_YOUR_CHARGE_NEW_MEXICO,
                         ],
                         id: "termOfConditionOptimizeChargerMN",
                    },

                    {
                         headLine: (
                              <span className="headline">
                                   <FormattedMessage
                                        id={`faqScheduledChargingTime-wi`}
                                        defaultMessage="What if I need to adjust my scheduled charging time?"
                                        description="What if I need to adjust my scheduled charging time?"
                                        values={{
                                             stateName: region,
                                        }}
                                   />
                              </span>
                         ),
                         body: (
                              <div>
                                   <p>
                                        <FormattedMessage
                                             id={`faqScheduledChargingTime-p1`}
                                             defaultMessage="If you need to occasionally charge your EV outside your selected charging schedule, you can easily adjust your scheduled charging time."
                                             description="If you need to occasionally charge your EV outside your selected charging schedule, you can easily adjust your scheduled charging time."
                                        />
                                   </p>
                                   <ul>
                                        <li>
                                             <span>
                                                  <FormattedMessage
                                                       id={`faqScheduledChargingTime-l1`}
                                                       defaultMessage="Through your charger: Use your charger manufacturer's app to override the scheduled charging time and charge immediately. (Tesla drivers will use the Tesla app vs charger app.)"
                                                       description="Through your charger: Use your charger manufacturer's app to override the scheduled charging time and charge immediately. (Tesla drivers will use the Tesla app vs charger app.)"
                                                  />
                                             </span>
                                             <ul>
                                                  <li>
                                                       <span>
                                                            <FormattedMessage
                                                                 id={`faqScheduledChargingTime-l2`}
                                                                 defaultMessage="ChargePoint Charger, you can 'double pump' the charger by plugging in, unplugging and plugging back into the car charging port."
                                                                 description="ChargePoint Charger, you can 'double pump' the charger by plugging in, unplugging and plugging back into the car charging port."
                                                            />
                                                       </span>
                                                       <ul>
                                                            <li>
                                                                 <span>
                                                                      <FormattedMessage
                                                                           id={`faqScheduledChargingTime-l3`}
                                                                           defaultMessage="Your ChargePoint charger will revert to your scheduled charging time the next time you plug in your EV."
                                                                           description="Your ChargePoint charger will revert to your scheduled charging time the next time you plug in your EV."
                                                                      />
                                                                 </span>
                                                            </li>
                                                       </ul>
                                                  </li>
                                                  <li>
                                                       <span>
                                                            <FormattedMessage
                                                                 id={`faqScheduledChargingTime-l4`}
                                                                 defaultMessage="Enel X Juice Box - you will need to go through the EV JuiceNet Mobile App"
                                                                 description="Enel X Juice Box - you will need to go through the EV JuiceNet Mobile App"
                                                            />
                                                       </span>
                                                       <ul>
                                                            <li>
                                                                 <span>
                                                                      <FormattedMessage
                                                                           id={`faqScheduledChargingTime-l5`}
                                                                           defaultMessage="To override and charge outside of the configured Scheduled Charging window, please select “Resume” in the Enel X Way app.  If you wish to stop the override and wait for Scheduled Charging to start in its scheduled window, you will need to unplug and then reconnect your vehicle. "
                                                                           description="To override and charge outside of the configured Scheduled Charging window, please select “Resume” in the Enel X Way app.  If you wish to stop the override and wait for Scheduled Charging to start in its scheduled window, you will need to unplug and then reconnect your vehicle."
                                                                      />
                                                                 </span>
                                                            </li>
                                                       </ul>
                                                  </li>
                                             </ul>
                                             <li>
                                                  <span>
                                                       <FormattedMessage
                                                            id={`faqScheduledChargingTime-l8`}
                                                            defaultMessage="Through your vehicle: You'll adjust your charging schedule through your vehicle's app."
                                                            description="Through your vehicle: You'll adjust your charging schedule through your vehicle's app."
                                                       />
                                                  </span>
                                             </li>
                                        </li>
                                   </ul>
                                   <p>
                                        <FormattedMessage
                                             id={`faqScheduledChargingTime-l9`}
                                             defaultMessage="If you would like to choose a different charging schedule, please contact us at"
                                             description="If you would like to choose a different charging schedule, please contact us at"
                                        />{" "}
                                        <a href="mailto:ElectricVehicles@xcelenergy.com">
                                             ElectricVehicles@xcelenergy.com
                                        </a>
                                        .
                                   </p>
                              </div>
                         ),
                         applicableTo: [
                              //EV_ACCELERATE_AT_HOME_MINNESOTA,
                              // EV_ACCELERATE_AT_HOME_NEW_MEXICO,
                              EV_ACCELERATE_AT_HOME_WISCONSIN,
                              // HOME_WIRING_REBATE_COLORADO,
                              // EV_ACCELERATE_AT_HOME_COLORADO,
                              // OPTIMIZE_YOUR_CHARGE_COLORADO,
                              // OPTIMIZE_YOUR_CHARGE_NEW_MEXICO,
                              // OPTIMIZE_YOUR_CHARGE_MINNESOTA
                         ],
                         id: "faqWorkWithElectricians",
                    },

                    {
                         headLine: (
                              <span className="headline">
                                   <FormattedMessage
                                        id="faq_ParticipateIfRentHome_WI"
                                        defaultMessage="Can I participate if I rent my home?"
                                        description="Can I participate if I rent my home?"
                                   />
                              </span>
                         ),
                         body: (
                              <div>
                                   <p>
                                        <FormattedMessage
                                             id="faq_ParticipateIfRentHome_Answer_WI"
                                             defaultMessage="Customers who are renting their home (detached single family home, townhome, row house, or duplex) must have a separately metered service for that home and have the building owner’s written consent to participate in this program."
                                             description="Customers who are renting their home (detached single family home, townhome, row house, or duplex) must have a separately metered service for that home and have the building owner’s written consent to participate in this program."
                                        />
                                   </p>
                              </div>
                         ),
                         applicableTo: [
                              EV_ACCELERATE_AT_HOME_WISCONSIN,
                              OPTIMIZE_YOUR_CHARGE_MINNESOTA,
                              OPTIMIZE_YOUR_CHARGE_NEW_MEXICO,
                              OPTIMIZE_YOUR_CHARGE_COLORADO,
                         ],
                         id: "faqParticipateIfRentHome",
                    },

                    {
                         headLine: (
                              <span className="headline">
                                   <FormattedMessage
                                        id="faqHowToEnrollOwnCharger"
                                        defaultMessage="How do I enroll if I’m bringing my own charger?"
                                        description="How do I enroll if I’m bringing my own charger?"
                                   />
                              </span>
                         ),
                         body: (
                              <div>
                                   <p>
                                        <FormattedMessage
                                             id="faqHowToEnrollOwnChargerAnswer"
                                             defaultMessage="Follow the prompts in the enrollment form here:"
                                             description="How do I enroll if I’m bringing my own charger? answer"
                                             values={{
                                                  link: (
                                                       <RenderByState
                                                            stateName={faqKey}
                                                            options={{
                                                                 default: (
                                                                      <a href="https://my.xcelenergy.com/MyAccount/s/ev">
                                                                           <FormattedMessage
                                                                                id="enrollInOYC"
                                                                                defaultMessage="Enroll in Optimize Your Charge"
                                                                                description="Enroll in Optimize Your Charge"
                                                                           />
                                                                      </a>
                                                                 ),
                                                                 [OPTIMIZE_YOUR_CHARGE_NEW_MEXICO]: (
                                                                      <a href="https://www.xcelenergy.com/staticfiles/xe-responsive/Marketing/NM-EV_Accelerate_At_Home_Optimize_Your_Charge_Application_Terms_Conditions.pdf">
                                                                           <FormattedMessage
                                                                                id="enrollInOYC"
                                                                                defaultMessage="Enroll in Optimize Your Charge"
                                                                                description="Enroll in Optimize Your Charge"
                                                                           />
                                                                      </a>
                                                                 ),
                                                            }}
                                                       />
                                                  ),
                                             }}
                                        />
                                   </p>
                                   <p>
                                        <FormattedMessage
                                             id="ifChargePointCharger"
                                             defaultMessage="If you have a ChargePoint charger:"
                                             description="If you have a ChargePoint charger:"
                                        />
                                        <ul>
                                             <li>
                                                  <FormattedMessage
                                                       id="ifChargePointChargerAnswer"
                                                       defaultMessage="If you have a ChargePoint charger:"
                                                       description="If you have a ChargePoint charger: Answer"
                                                  />
                                             </li>
                                             <li>
                                                  <FormattedMessage
                                                       id="ifChargePointChargerAnswerTwo"
                                                       defaultMessage="You will be prompted to fill in information including your first and last name, charger serial number, your electric meter and service number. The charger serial number is a 12-digit number. This can be found on the charger or its original packaging. Please contact us at ElectricVehicles@xcelenergy.com so that we can provide you with your electric meter number and service number."
                                                       description="If you have a ChargePoint charger: Answer Two"
                                                       values={{
                                                            link: (
                                                                 <a href="mailto:ElectricVehicles@xcelenergy.com">
                                                                      ElectricVehicles@xcelenergy.com
                                                                 </a>
                                                            ),
                                                       }}
                                                  />
                                             </li>
                                        </ul>
                                        <FormattedMessage
                                             id="ifEnelXCharger"
                                             defaultMessage="If you have a EnelX charger:"
                                             description="If you have a EnelX charger:"
                                        />
                                        <ul>
                                             <li>
                                                  <FormattedMessage
                                                       id="ifEnelXChargerAnswer"
                                                       defaultMessage="After enrolling, Enel X will connect your Enel X charger and will notify you by email once that has been done."
                                                       description="If you have a EnelX charger: Answer"
                                                  />
                                             </li>
                                        </ul>
                                   </p>
                              </div>
                         ),
                         applicableTo: [
                              OPTIMIZE_YOUR_CHARGE_COLORADO,
                              OPTIMIZE_YOUR_CHARGE_NEW_MEXICO,
                              OPTIMIZE_YOUR_CHARGE_MINNESOTA,
                         ],
                         id: "faqHowToEnrollOwnCharger",
                    },

                    {
                         headLine: (
                              <span className="headline">
                                   <FormattedMessage
                                        id="chargerIsConnectedWifi"
                                        defaultMessage="How can I tell if my charger is connected to Wi-Fi?"
                                        description="How can I tell if my charger is connected to Wi-Fi?"
                                   />
                              </span>
                         ),
                         body: (
                              <div>
                                   <p>
                                        Do we explain in graphics and text in this FAQ and separate it by Charger Type
                                        or create a PDF (can we create PDF or does it get sourced out?) 
                                   </p>
                              </div>
                         ),
                         applicableTo: [],
                         id: "chargerIsConnectedWifi",
                    },
                    {
                         headLine: (
                              <span className="headline">
                                   <FormattedMessage
                                        id="chargerIsConnectedWifi"
                                        defaultMessage="How can I tell if my charger is connected to Wi-Fi? "
                                        description="How can I tell if my charger is connected to Wi-Fi?  "
                                   />
                              </span>
                         ),
                         body: (
                              <div>
                                   <p>
                                        Do we explain in graphics and text in this FAQ and separate it by Charger Type
                                        or create a PDF (can we create PDF or does it get sourced out?) 
                                   </p>
                              </div>
                         ),
                         applicableTo: [
                              // OPTIMIZE_YOUR_CHARGE_MINNESOTA
                         ],
                         id: "chargerIsConnectedWifi",
                    },
                    {
                         headLine: (
                              <span className="headline">
                                   What if I'm already enrolled in the EV Pricing Plan or the Time of Day Pricing Plan?
                              </span>
                         ),
                         body: (
                              <div>
                                   <p>
                                        Before you enroll, please contact us at{" "}
                                        <a href="mailto:ElectricVehicles@xcelenergy.com.">
                                             ElectricVehicles@xcelenergy.com.
                                        </a>
                                        to discuss your options. You cannot participate in this pilot and our EV Pricing
                                        Plan or Time of Day Pricing Plan at the same time.
                                        <br />
                                        See{" "}
                                        <a
                                             href="https://www.xcelenergy.com/staticfiles/xe-responsive/Energy%20Portfolio/Electricity/EV%20Subscription%20Pilot%20Terms%20and%20Conditions.pdf"
                                             target="_blank"
                                             rel="noopener noreferrer"
                                        >
                                             Terms &amp; Conditions
                                        </a>{" "}
                                        (PDF) for more information.
                                   </p>
                              </div>
                         ),
                         applicableTo: [EV_SUBSCRIPTION_SERVICE_PILOT_MINNESOTA],
                         id: "alreadyEnrolledInTheEv",
                    },
                    {
                         headLine: (
                              <span className="headline">
                                   <FormattedMessage
                                        id="faqTimeOfDayRate_MN/WI"
                                        defaultMessage="Can I participate if I’m enrolled in the Time of Day rate?"
                                        description="Can I participate if I’m enrolled in the Time of Day rate?"
                                   />
                              </span>
                         ),
                         body: (
                              <div>
                                   <p>
                                        <FormattedMessage
                                             id="faqTimeOfDayRate_Answer_WI"
                                             defaultMessage="Yes. If you are already enrolled in our {link} rate, you can enroll if you meet all other eligibility requirements. "
                                             description="If you are already enrolled"
                                             values={{
                                                  link: (
                                                       <a
                                                            href="https://www.xcelenergy.com/billing_and_payment/understanding_your_bill/residential_rate_plans/time_of_day?_ga=2.251606150.107638907.1667422966-367489819.1647884872" //faqTimeOfDayRate_link_URL
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                       >
                                                            <FormattedMessage
                                                                 id="faqTimeOfDayRate_link_text"
                                                                 defaultMessage="Time of Day"
                                                                 description="Time of Day"
                                                            />
                                                       </a>
                                                  ),
                                             }}
                                        />
                                   </p>
                                   <p>
                                        <FormattedMessage
                                             id="faqTimeOfDayRate_link_text3"
                                             defaultMessage="{xcelEnergyProvidedCharger} Like all other participants, you will rent the charger that we will install and maintain, but the monthly fee is slightly different at $13.00 per month. If you sign up for the Time of Day rate, your EV charging will also be billed at the Time of Day rate, not the EV Accelerate At Home EV charging rate. For more information see: Time of Day{lineBreak}
                        {customerOwnedCharger} If you own your charger you do not have to be enrolled in EV Accelerate At Home to benefit from the Time of Day rates you are currently participating in."
                                             description="{xcelEnergyProvidedCharger} Like all other participants, you will rent the charger that we will install and maintain, but the monthly fee is slightly different at $13.00 per month. If you sign up for the Time of Day rate, your EV charging will also be billed at the Time of Day rate, not the EV Accelerate At Home EV charging rate. For more information see: Time of Day{lineBreak}
                        {customerOwnedCharger} If you own your charger you do not have to be enrolled in EV Accelerate At Home to benefit from the Time of Day rates you are currently participating in.
                        "
                                             values={{
                                                  lineBreak: <br />,
                                                  xcelEnergyProvidedCharger: <b>Xcel Energy-Provided Charger:</b>,
                                                  customerOwnedCharger: <b>Customer-Owned Charger:</b>,
                                             }}
                                        />
                                   </p>
                              </div>
                         ),
                         applicableTo: [EV_ACCELERATE_AT_HOME_WISCONSIN],
                         id: "faqTimeOfDayRateWI",
                    },
                    {
                         headLine: (
                              <span className="headline">
                                   <FormattedMessage
                                        id="faqTimeOfDayRate_MN/WI"
                                        defaultMessage="Can I participate if I’m enrolled in the Time of Day rate?"
                                        description="Can I participate if I’m enrolled in the Time of Day rate?"
                                   />
                              </span>
                         ),
                         body: (
                              <div>
                                   <p>
                                        <FormattedMessage
                                             id="faqTimeOfDayRate_Answer_MN_v2"
                                             defaultMessage="Yes. If you are already enrolled in our {link} rate, you can enroll if you meet all other eligibility requirements."
                                             description="If you are already enrolled in our Time of Day rate"
                                             values={{
                                                  lineBreak: <br />,
                                                  link: (
                                                       <a href="https://www.xcelenergy.com/billing_and_payment/understanding_your_bill/residential_rate_plans/time_of_day?_ga=2.104863747.107638907.1667422966-367489819.1647884872">
                                                            <FormattedMessage
                                                                 id="faqTimeOfDayRate_Answer_MN_link_text"
                                                                 defaultMessage="Time of Day"
                                                                 description="Time of Day"
                                                            />
                                                       </a>
                                                  ),
                                             }}
                                        />
                                   </p>
                                   <p>
                                        <FormattedMessage
                                             id="faqTimeOfDayRate_link_text3"
                                             defaultMessage="{xcelEnergyProvidedCharger} Like all other participants, you will rent the charger that we will install and maintain, but the monthly fee is slightly different at $11.99 per month. If you sign up for the {link} rate, your EV charging will also be billed at the Time of Day rate, not the EV Accelerate At Home EV charging rate. For more information see: {link}{lineBreak}
                        {customerOwnedCharger} If you own your charger you do not have to be enrolled in EV Accelerate At Home to benefit from the Time of Day rates you are currently participating in."
                                             description="{xcelEnergyProvidedCharger} Like all other participants, you will rent the charger that we will install and maintain, but the monthly fee is slightly different at $11.99 per month. If you sign up for the {link} rate, your EV charging will also be billed at the Time of Day rate, not the EV Accelerate At Home EV charging rate. For more information see: {link}{lineBreak}
                        {customerOwnedCharger} If you own your charger you do not have to be enrolled in EV Accelerate At Home to benefit from the Time of Day rates you are currently participating in.
                        "
                                             values={{
                                                  lineBreak: <br />,
                                                  xcelEnergyProvidedCharger: <b>Xcel Energy-Provided Charger:</b>,
                                                  customerOwnedCharger: <b>Customer-Owned Charger:</b>,
                                                  link: (
                                                       <a href="https://www.xcelenergy.com/billing_and_payment/understanding_your_bill/residential_rate_plans/time_of_day?_ga=2.104863747.107638907.1667422966-367489819.1647884872">
                                                            <FormattedMessage
                                                                 id="faqTimeOfDayRate_Answer_MN_link_text"
                                                                 defaultMessage="Time of Day"
                                                                 description="Time of Day"
                                                            />
                                                       </a>
                                                  ),
                                             }}
                                        />
                                   </p>
                              </div>
                         ),
                         applicableTo: [EV_ACCELERATE_AT_HOME_MINNESOTA],
                         id: "faqTimeOfDayRateMN",
                    },
                    {
                         headLine: (
                              <span className="headline">
                                   <FormattedMessage
                                        id="faqRentMyHome_question"
                                        defaultMessage="Can I participate if I rent my home?"
                                        description="Can I participate if I rent my home?"
                                   />
                              </span>
                         ),
                         body: (
                              <div>
                                   <p>
                                        <FormattedMessage
                                             id="faqRentMyHome_answer"
                                             defaultMessage="Customers who are renting their home (detached single family home, townhome, row house, or duplex) must have a separately metered service for that home and have the building owner’s written consent to participate in this program."
                                             description="Customers who are renting their home (detached single family home, townhome, row house, or duplex) must have a separately metered service for that home and have the building owner’s written consent to participate in this program."
                                        />
                                   </p>
                              </div>
                         ),
                         applicableTo: [EV_ACCELERATE_AT_HOME_MINNESOTA],
                         id: "faqRentMyHome",
                    },
                    {
                         headLine: (
                              <span className="headline">
                                   <FormattedMessage
                                        id="faqOnSiteSolarProgram"
                                        defaultMessage="Can I participate if I’m enrolled in an Xcel Energy on-site solar program?"
                                        description="Can I participate if I’m enrolled in an Xcel Energy on-site solar program?"
                                   />
                              </span>
                         ),
                         body: (
                              <div>
                                   <p>
                                        <FormattedMessage
                                             id="faqOnSiteSolarProgram_Answer_WI_2"
                                             defaultMessage="If you are enrolled in Net Metering or Solar*Rewards, you must sign up for the {link} rate (and meet all other eligibility) to enroll in this program.{lineBreak}{xcelEnergyProvidedCharger} Like all other participants, you will have a choice to either rent or own the charger that we will install and maintain, but the monthly fee is $13.00. or $3.00 if you buy the charger and installation up front.
                  If you sign up for the {link} rate, your EV charging will also be billed at the Time of Day rate, not the EV Accelerate At Home EV charging rate.
                  {lineBreak}
                  {customerOwnedCharger} Customers who are participating in an on-site Solar or plan to switch to an on-site solar are not eligible for the EV Accelerate At Home program with their own charger."
                                             description=""
                                             values={{
                                                  link: (
                                                       <a
                                                            href="https://www.xcelenergy.com/billing_and_payment/understanding_your_bill/residential_rate_plans/time_of_day?_ga=2.251606150.107638907.1667422966-367489819.1647884872" //faqTimeOfDayRate_link_URL
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                       >
                                                            <FormattedMessage
                                                                 id="faqTimeOfDayRate_link_text"
                                                                 defaultMessage="Time of Day"
                                                                 description="Time of Day"
                                                            />
                                                       </a>
                                                  ),
                                                  lineBreak: <br />,
                                                  xcelEnergyProvidedCharger: <b>Xcel Energy-Provided Charger:</b>,
                                                  customerOwnedCharger: <b>Customer-Owned Charger:</b>,
                                             }}
                                        />
                                   </p>
                              </div>
                         ),
                         applicableTo: [EV_ACCELERATE_AT_HOME_WISCONSIN],
                         id: "faqOnSiteSolarProgramWI",
                    },
                    {
                         headLine: (
                              <span className="headline">
                                   <FormattedMessage
                                        id="faqOnSiteSolarProgram"
                                        defaultMessage="Can I participate if I’m enrolled in an Xcel Energy on-site solar program?"
                                        description="Can I participate if I’m enrolled in an Xcel Energy on-site solar program?"
                                   />
                              </span>
                         ),
                         body: (
                              <div>
                                   <p>
                                        <FormattedMessage
                                             id="faqOnSiteSolarProgram_Answer_MN_V2"
                                             defaultMessage="If you are enrolled in Net Metering or Solar*Rewards, you must sign up for the {link} rate (and meet all other eligibility) to enroll in this program.{lineBreak}
                  The monthly fee for the EV Accelerate At Home is slightly different at $11.99 per month if you rent the charger. In addition, the customer charge on the Residental Time of Day rate is $2.00 higher than the standard Residential service.
                  {lineBreak}
                  {customerOwnedCharger} Customers that have the Solar and own their own charger do not qualify for the EV Accelerate At Home program.{lineBreak}
                  If you're a solar customer considering the {link} rate, please email {email} to find out if this rate is a good option for you. If you sign up for the {link} rate, your EV charging will also be billed at Time of Day rate, not the EV Accelerate At Home EV charging rate."
                                             description="If you are enrolled in Net Metering or Solar"
                                             values={{
                                                  email: (
                                                       <a href="mailto:ee-team@xcelenergy.com">
                                                            ee-team@xcelenergy.com
                                                       </a>
                                                  ),
                                                  link: (
                                                       <a
                                                            href="https://www.xcelenergy.com/billing_and_payment/understanding_your_bill/residential_rate_plans/time_of_day?_ga=2.251606150.107638907.1667422966-367489819.1647884872" //faqTimeOfDayRate_link_URL
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                       >
                                                            <FormattedMessage
                                                                 id="faqTimeOfDayRate_link_text"
                                                                 defaultMessage="Time of Day"
                                                                 description="Time of Day"
                                                            />
                                                       </a>
                                                  ),
                                                  lineBreak: <br />,
                                                  customerOwnedCharger: <b>Customer-Owned Charger:</b>,
                                             }}
                                        />
                                   </p>
                              </div>
                         ),
                         applicableTo: [EV_ACCELERATE_AT_HOME_MINNESOTA],
                         id: "faqOnSiteSolarProgramMN",
                    },
                    {
                         headLine: (
                              <span className="headline">
                                   <FormattedMessage
                                        id="faqOnSiteSolarProgram"
                                        defaultMessage="Can I participate if I’m enrolled in an Xcel Energy on-site solar program?"
                                        description="Can I participate if I’m enrolled in an Xcel Energy on-site solar program?"
                                   />
                              </span>
                         ),
                         body: (
                              <div>
                                   <p>
                                        <FormattedMessage id="yes" defaultMessage="yes" description="yes" />.
                                   </p>
                              </div>
                         ),
                         applicableTo: [EV_ACCELERATE_AT_HOME_COLORADO, EV_ACCELERATE_AT_HOME_NEW_MEXICO],
                         id: "faqOnSiteSolarProgram",
                    },
                    {
                         headLine: (
                              <span className="headline">
                                   <FormattedMessage
                                        id="faqMultiFamilyBuilding"
                                        defaultMessage="Can I participate if I live in a multi-family building?"
                                        description="Can I participate if I live in a multi-family building?"
                                   />
                              </span>
                         ),
                         body: (
                              <div>
                                   <p>
                                        <FormattedMessage
                                             id="faqMultiFamilyBuildingAnswer"
                                             defaultMessage="If you live in a condo, townhome, or rowhouse that has an assigned parking spot and is metered separately, you can participate with your building owner’s approval."
                                             description="Can I participate if I live in a multi-family building? Answer"
                                        />
                                   </p>
                              </div>
                         ),
                         applicableTo: [EV_ACCELERATE_AT_HOME_NEW_MEXICO],
                         id: "faqMultiFamilyBuilding",
                    },
                    {
                         headLine: (
                              <span className="headline">
                                   <FormattedMessage
                                        id="faqMultiFamilyBuilding"
                                        defaultMessage="Can I participate if I live in a multi-family building?"
                                        description="Can I participate if I live in a multi-family building?"
                                   />
                              </span>
                         ),
                         body: (
                              <div>
                                   <p>
                                        <FormattedMessage
                                             id="faqMultiFamilyBuildingAnswer"
                                             defaultMessage="If you live in a condo, townhome, or rowhouse that has an assigned parking spot and is metered separately, you can participate with your building owner’s approval."
                                             description="Can I participate if I live in a multi-family building? Answer"
                                        />
                                   </p>
                                   <p>
                                        <FormattedMessage
                                             id="faqMultiFamilyBuildingAnswerTwo"
                                             defaultMessage="If you live in a condo, townhome, or rowhouse that has an assigned parking spot and is metered separately, you can participate with your building owner’s approval."
                                             description="Can I participate if I live in a multi-family building? Answer"
                                             values={{
                                                  link: (
                                                       <a href="https://co.my.xcelenergy.com/s/business/ev/multifamily-charging">
                                                            <FormattedMessage
                                                                 id="multiFamilyPrograms"
                                                                 defaultMessage="multi-family programs"
                                                                 description="multi-family programs"
                                                            />
                                                       </a>
                                                  ),
                                             }}
                                        />
                                   </p>
                              </div>
                         ),
                         applicableTo: [EV_ACCELERATE_AT_HOME_COLORADO],
                         id: "faqMultiFamilyBuildingCO",
                    },
                    {
                         headLine: (
                              <span className="headline">
                                   <FormattedMessage
                                        id="faqFiftyBillCredit"
                                        defaultMessage="When will I receive my annual $50 bill credit?"
                                        description="When will I receive my annual $50 bill credit?"
                                   />
                              </span>
                         ),
                         body: (
                              <div>
                                   <p></p>
                                   {faqKey === OPTIMIZE_YOUR_CHARGE_MINNESOTA ? (
                                        <FormattedMessage
                                             id="faqFiftyBillCreditAnswerMN"
                                             defaultMessage="To reward EV drivers who participate in Optimize Your Charge, we will provide an annual $50 bill credit every October If your enrollment is active, you enrolled before September 1st, and you charged at least 25% of the time during your preferred charging schedule."
                                             description="When will I receive my annual $50 bill credit? Answer"
                                        />
                                   ) : (
                                        <FormattedMessage
                                             id="faqFiftyBillCreditAnswer"
                                             defaultMessage="To reward EV drivers who participate in Optimize Your Charge, we will provide an annual $50 bill credit every October If your enrollment is active, you enrolled before September 1st, and you charged at least 25% of the time during your preferred charging schedule."
                                             description="When will I receive my annual $50 bill credit? Answer"
                                        />
                                   )}
                              </div>
                         ),
                         applicableTo: [
                              EV_ACCELERATE_AT_HOME_COLORADO,
                              OPTIMIZE_YOUR_CHARGE_COLORADO,
                              OPTIMIZE_YOUR_CHARGE_NEW_MEXICO,
                              EV_ACCELERATE_AT_HOME_NEW_MEXICO,
                              OPTIMIZE_YOUR_CHARGE_MINNESOTA,
                         ],
                         id: "faqFiftyBillCredit",
                    },
                    {
                         headLine: (
                              <span className="headline">
                                   What if my charger malfunctions or I need to relocate it?
                              </span>
                         ),
                         body: (
                              <div>
                                   <p>
                                        Ongoing maintenance of your charger is included in your monthly subscription. If
                                        you have questions or experience issues with your charger, please contact us at{" "}
                                        <a href="mailto:ElectricVehicles@xcelenergy.com.">
                                             ElectricVehicles@xcelenergy.com.
                                        </a>
                                        <br />
                                        For additional information and helpful tips for troubleshooting Wi-Fi or other
                                        potential issues with your charger, please see the following links.
                                        <br />
                                        <a href="https://www.chargepoint.com/drivers/home/resource/">
                                             ChargePoint Support
                                        </a>
                                        <br />
                                        <a href="https://support-emobility.enelx.com/hc/en-us">Enel X Support</a>
                                   </p>
                              </div>
                         ),
                         applicableTo: [EV_SUBSCRIPTION_SERVICE_PILOT_MINNESOTA],
                         id: "chargerMalfunctionsOrRelocate",
                    },
                    {
                         headLine: (
                              <span className="headline">
                                   <FormattedMessage
                                        id="scheduleChargingTimeAdjustMn"
                                        defaultMessage="What if I need to adjust my scheduled charging time?"
                                        description="What if I need to adjust my scheduled charging time?"
                                   />
                              </span>
                         ),
                         body: (
                              <div>
                                   <p>
                                        <FormattedMessage
                                             id="scheduleChargingTimeAdjustMnAnswer"
                                             defaultMessage="If you need to occasionally charge your EV outside your selected charging schedule, you can easily adjust your scheduled charging time."
                                        />
                                   </p>
                                   <ul>
                                        <li>
                                             <FormattedMessage
                                                  id="scheduleChargingTimeAdjustMnBullet1"
                                                  defaultMessage="Use your charger manufacturer's app to override the scheduled charging time and charge immediately. (Tesla drivers should use the Tesla app.)"
                                             />
                                        </li>
                                        <ul>
                                             <li>
                                                  <FormattedMessage
                                                       id="scheduleChargingTimeAdjustMnBullet2"
                                                       defaultMessage='If you have a ChargePoint Home, you can "double pump" the charger by plugging in, unplugging and plugging back into the car charging port.'
                                                  />
                                             </li>
                                             <li>
                                                  <FormattedMessage
                                                       id="scheduleChargingTimeAdjustMnBullet2-2"
                                                       defaultMessage="Your charger will revert to your scheduled charging time the next time you plug in your EV."
                                                  />
                                             </li>
                                        </ul>
                                        <li>
                                             <FormattedMessage
                                                  id="scheduleChargingTimeAdjustMnBullet3"
                                                  defaultMessage="Through your vehicle: You’ll adjust your charging schedule through your vehicle’s app."
                                             />
                                        </li>
                                   </ul>
                                   <p>
                                        <FormattedMessage
                                             id="scheduleChargingTimeAdjustMnEmail"
                                             defaultMessage="If you would like to choose a different charging schedule, please contact us at {email}"
                                             values={{
                                                  email: (
                                                       <a href="mailto:ElectricVehicles@xcelenergy.com">
                                                            ElectricVehicles@xcelenergy.com.
                                                       </a>
                                                  ),
                                             }}
                                        />
                                   </p>
                              </div>
                         ),
                         applicableTo: [OPTIMIZE_YOUR_CHARGE_MINNESOTA],
                         id: "scheduleChargingTimeAdjustMn",
                    },
                    {
                         headLine: (
                              <span className="headline">
                                   <FormattedMessage
                                        id="participateOptimizeCharger"
                                        defaultMessage="Can I participate in Optimize Your Charge and EV Accelerate At Home? "
                                   />
                              </span>
                         ),
                         body: (
                              <div>
                                   <p>
                                        <FormattedMessage
                                             id="Yes"
                                             defaultMessage="Yes, you can participate in both EV Accelerate At Home and Optimize Your Charge where each program has its own benefits, enrollment process and conditions. To apply for EV Accelerate At Home online, {link1}. To apply for Optimize Your Charge online, {link2}."
                                             values={{
                                                  link1: (
                                                       <a
                                                            href="https://my.xcelenergy.com/MyAccount/s/enroll-electric-vehicles?zipCode=55405&propertyType=Residential"
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                       >
                                                            <FormattedMessage
                                                                 id="clickHere"
                                                                 defaultMessage="click here"
                                                            />
                                                       </a>
                                                  ),
                                                  link2: (
                                                       <a
                                                            href="https://my.xcelenergy.com/MyAccount/s/enroll-electric-vehicles?zipCode=55405&propertyType=Residential"
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                       >
                                                            <FormattedMessage
                                                                 id="clickHere"
                                                                 defaultMessage="click here"
                                                            />
                                                       </a>
                                                  ),
                                             }}
                                        />
                                   </p>
                              </div>
                         ),
                         applicableTo: [
                              OPTIMIZE_YOUR_CHARGE_MINNESOTA,
                              OPTIMIZE_YOUR_CHARGE_COLORADO,
                              OPTIMIZE_YOUR_CHARGE_NEW_MEXICO,
                         ],
                         id: "participateOptimizeCharger",
                    },
                    {
                         headLine: (
                              <span className="headline">
                                   <FormattedMessage
                                        id="participateIfOnlyEnrolled"
                                        defaultMessage="If I am ONLY enrolled in Optimize Your Charge, do I benefit from the reduced rates that EV Accelerate At Home provides?"
                                   />
                              </span>
                         ),
                         body: (
                              <div>
                                   <p>
                                        <FormattedMessage
                                             id="noEvAccelareteIsSeparate"
                                             defaultMessage="No. EV Accelerate At Home is a separate program which has its own benefits, enrollment process and conditions. {link}"
                                             values={{
                                                  link: (
                                                       <a
                                                            href="https://my.xcelenergy.com/MyAccount/s/ev"
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                       >
                                                            <FormattedMessage
                                                                 id="applyHere"
                                                                 defaultMessage="You can apply here"
                                                            />
                                                       </a>
                                                  ),
                                             }}
                                        />
                                   </p>
                              </div>
                         ),
                         applicableTo: [
                              OPTIMIZE_YOUR_CHARGE_MINNESOTA,
                              OPTIMIZE_YOUR_CHARGE_COLORADO,
                              OPTIMIZE_YOUR_CHARGE_NEW_MEXICO,
                         ],
                         id: "participateIfOnlyEnrolled",
                    },
                    {
                         headLine: (
                              <span className="headline">
                                   <FormattedMessage
                                        id="participateEvAccelerateAtHome"
                                        defaultMessage="If I participate in EV Accelerate At Home and Optimize Your Charge, how will it impact my EV Accelerate At Home off-peak rate?"
                                   />
                              </span>
                         ),
                         body: (
                              <div>
                                   <p>
                                        <FormattedMessage
                                             id="participateEvAccelerateAtHomeAnswer"
                                             defaultMessage="EV Accelerate At Home provides a six-hour off-peak charging schedule at a low rate for EV charging (midnight to 6:00am). You can earn the annual $50 bill credit by participating in Optimize Your Charge and charging during EV Accelerate At Home six-hour off-peak schedule. For times when you need more than 6 hours to charge, Optimize Your Charge provides longer, eight-hour charging schedule options, giving you an additional two hours to charge. These two additional hours fall into the EV Accelerate At Home mid-peak rate. The annual $50 bill credit will offset some or all of the mid-peak charging costs."
                                        />
                                   </p>
                              </div>
                         ),
                         applicableTo: [OPTIMIZE_YOUR_CHARGE_MINNESOTA],
                         id: "participateEvAccelerateAtHome",
                    },
                    {
                         headLine: (
                              <span className="headline">
                                   <FormattedMessage
                                        id="enrollBusinessMN"
                                        defaultMessage="Can I enroll my business or fleet vehicles in Optimize Your Charge?"
                                   />
                              </span>
                         ),
                         body: (
                              <div>
                                   <p>
                                        <FormattedMessage
                                             id="enrollBusinessMNAnswer"
                                             defaultMessage="We can help! {link}"
                                             values={{
                                                  //enrollBusinessMNAnswer_link_URL
                                                  link: (
                                                       <strong>
                                                            <a
                                                                 href="https://mn.my.xcelenergy.com/s/business/rate-plans/optimize-your-charge"
                                                                 target="_blank"
                                                                 rel="noopener noreferrer"
                                                            >
                                                                 <FormattedMessage
                                                                      id="enrollBusinessMNAnswer_link_text"
                                                                      defaultMessage="Find more about Optimize Your Charge commercial solutions."
                                                                 />
                                                            </a>
                                                       </strong>
                                                  ),
                                             }}
                                        />
                                   </p>
                              </div>
                         ),
                         applicableTo: [OPTIMIZE_YOUR_CHARGE_MINNESOTA],
                         id: "enrollBusinessMN",
                    },
                    {
                         headLine: (
                              <span className="headline">
                                   <FormattedMessage
                                        id="faqAdjustScheduledChargingTime"
                                        defaultMessage="What if I need to adjust my scheduled charging time?"
                                        description="What if I need to adjust my scheduled charging time"
                                   />
                              </span>
                         ),
                         body: (
                              <div>
                                   <p>
                                        <FormattedMessage
                                             id="faqAdjustScheduledChargingTimeAnswer"
                                             defaultMessage="If you need to occasionally charge your EV outside your selected charging schedule, you can easily adjust your scheduled charging time."
                                             description="What if I need to adjust my scheduled charging time Answer"
                                        />
                                   </p>
                                   <ul>
                                        <li>
                                             <FormattedMessage
                                                  id="useYourChargerAppOverride"
                                                  defaultMessage="Use your charger manufacturer's app to override the scheduled charging time and charge immediately. (Tesla drivers should use the Tesla app.)"
                                                  description="Use your charger manufacturer's app to override the scheduled charging time and charge immediately. (Tesla drivers should use the Tesla app.)"
                                             />
                                             <ul>
                                                  <li>
                                                       <FormattedMessage
                                                            id="chargePointAdjustChargingTime"
                                                            defaultMessage="If you have a ChargePoint Home, you can double pump the charger by plugging in, unplugging and plugging back into the car charging port."
                                                            description="ChargePoint Home Adjust Charging Time"
                                                       />
                                                  </li>
                                                  <li>
                                                       <FormattedMessage
                                                            id="revertScheduleChargingTime"
                                                            defaultMessage="Your charger will revert to your scheduled charging time the next time you plug in your EV."
                                                            description="Revert Charging Time"
                                                       />
                                                  </li>
                                             </ul>
                                        </li>
                                        <li>
                                             <FormattedMessage
                                                  id="throughYourVehicle"
                                                  defaultMessage="Through your vehicle: You’ll adjust your charging schedule through your vehicle’s app."
                                                  description="Through Vehicle Adjust"
                                             />
                                        </li>
                                   </ul>
                                   <p>
                                        <FormattedMessage
                                             id="chooseDifferentChargingSchedule"
                                             defaultMessage="If you would like to choose a different charging schedule, please contact us at ElectricVehicles@xcelenergy.com."
                                             description="Revert Charging Time"
                                             values={{
                                                  link: (
                                                       <a href="mailto:ElectricVehicles@xcelenergy.com.">
                                                            ElectricVehicles@xcelenergy.com.
                                                       </a>
                                                  ),
                                             }}
                                        />
                                   </p>
                              </div>
                         ),
                         applicableTo: [
                              //      EV_ACCELERATE_AT_HOME_COLORADO,
                              OPTIMIZE_YOUR_CHARGE_COLORADO,
                              OPTIMIZE_YOUR_CHARGE_NEW_MEXICO,
                              CHANGING_PERKS_PILOT_COLORADO,
                              //      EV_ACCELERATE_AT_HOME_NEW_MEXICO,
                         ],
                         id: "faqAdjustScheduledChargingTime",
                    },
                    //  {
                    //    headLine: (
                    //      <span className="headline">
                    //        <FormattedMessage
                    //          id="canIChargeWithRenewableEnergy"
                    //          defaultMessage="Can I charge my EV with renewable energy?"
                    //          description="Can I charge my EV with renewable energy?"
                    //        />
                    //      </span>
                    //    ),
                    //    body: (
                    //      <div>
                    //        <p>
                    //          <FormattedMessage
                    //            id="canIChargeWithRenewableEnergyAnswer"
                    //            defaultMessage="You can enroll in one of our renewable energy programs to lower your vehicle’s carbon footprint even more."
                    //            description="You can enroll in one of our renewable energy programs to lower your vehicle’s carbon footprint even more."
                    //            values={{
                    //              link: (
                    //                <a
                    //                  href="https://www.xcelenergy.com/programs_and_rebates/residential_programs_and_rebates/renewable_energy_options_residential"
                    //                  target="_blank"
                    //                  rel="noopener noreferrer"
                    //                >
                    //                  <FormattedMessage
                    //                    id="renewableEnergyPrograms"
                    //                    defaultMessage="renewable energy programs"
                    //                    description="renewable energy programs"
                    //                  />
                    //                </a>
                    //              ),
                    //            }}
                    //          />
                    //        </p>
                    //      </div>
                    //    ),
                    //    applicableTo: [
                    //      EV_ACCELERATE_AT_HOME_WISCONSIN,
                    //      EV_ACCELERATE_AT_HOME_MINNESOTA,
                    //      EV_ACCELERATE_AT_HOME_COLORADO,
                    //      OPTIMIZE_YOUR_CHARGE_COLORADO,
                    //      OPTIMIZE_YOUR_CHARGE_NEW_MEXICO,
                    //      OPTIMIZE_YOUR_CHARGE_MINNESOTA,
                    //      CHANGING_PERKS_PILOT_COLORADO,
                    //    ],
                    //    id: 'canIChargeWithRenewableEnergy'
                    //  },
                    {
                         headLine: (
                              <span className="headline">
                                   <FormattedMessage
                                        id="canIenrollMN"
                                        defaultMessage="Can I enroll if I am enrolled in Time of Day rate or in an Xcel Energy on-site solar program? "
                                        description="Can I enroll if I am enrolled in Time of Day rate or in an Xcel Energy on-site solar program? "
                                   />
                              </span>
                         ),
                         body: (
                              <div>
                                   <p>
                                        <FormattedMessage
                                             id="canIenrollMNAnswer"
                                             defaultMessage="Yes you can enroll."
                                             description="Yes you can enroll."
                                        />
                                   </p>
                              </div>
                         ),
                         applicableTo: [
                              OPTIMIZE_YOUR_CHARGE_MINNESOTA,
                              OPTIMIZE_YOUR_CHARGE_COLORADO,
                              OPTIMIZE_YOUR_CHARGE_NEW_MEXICO,
                         ],
                         id: "canIenrollMN",
                    },
                    {
                         headLine: (
                              <span className="headline">
                                   <FormattedMessage
                                        id="canIChargeWithRenewableEnergy"
                                        defaultMessage="Can I charge my EV with renewable energy?"
                                        description="Can I charge my EV with renewable energy?"
                                   />
                              </span>
                         ),
                         body: (
                              <div>
                                   <p>
                                        <FormattedMessage
                                             id="canIChargeWithRenewableEnergyAnswer"
                                             defaultMessage="You can enroll in one of our renewable energy programs to lower your vehicle’s carbon footprint even more."
                                             description="You can enroll in one of our renewable energy programs to lower your vehicle’s carbon footprint even more."
                                             values={{
                                                  link: (
                                                       <a
                                                            href="https://nm.my.xcelenergy.com/s/renewable"
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                       >
                                                            <FormattedMessage
                                                                 id="renewableEnergyPrograms"
                                                                 defaultMessage="renewable energy programs"
                                                                 description="renewable energy programs"
                                                            />
                                                       </a>
                                                  ),
                                             }}
                                        />
                                   </p>
                              </div>
                         ),
                         applicableTo: [EV_ACCELERATE_AT_HOME_NEW_MEXICO],
                         id: "canIChargeWithRenewableEnergyNM",
                    },
                    {
                         headLine: (
                              <span className="headline">
                                   <FormattedMessage
                                        id="faq_Holidays_MN/WI"
                                        defaultMessage="Which holidays do not include peak hours?"
                                        description="Which holidays do not include peak hours?"
                                   />
                              </span>
                         ),
                         body: (
                              <div style={{ width: "100%" }}>
                                   <p>
                                        <FormattedMessage
                                             id="faq_Holidays_Answer_MN/WI"
                                             defaultMessage="There are no peak hours on New Year’s Day, Good Friday, Memorial Day, Independence Day, Labor Day, Thanksgiving, or Christmas Day. Mid-peak hours still apply."
                                             description="There are no peak hours on New Year’s Day, Good Friday, Memorial Day, Independence Day, Labor Day, Thanksgiving, or Christmas Day. Mid-peak hours still apply."
                                        />
                                   </p>
                              </div>
                         ),
                         applicableTo: [
                              // EV_ACCELERATE_AT_HOME_WISCONSIN,
                              //EV_ACCELERATE_AT_HOME_MINNESOTA,
                         ],
                         id: "faqHolidaysMN/WI",
                    },
                    {
                         headLine: (
                              <span className="headline">
                                   <FormattedMessage
                                        id="faqIncludedInMaintenanceAndWarranty"
                                        defaultMessage="What is included in charger maintenance and warranty?"
                                        description="What is included in charger maintenance and warranty?"
                                   />
                              </span>
                         ),
                         body: (
                              <div>
                                   <p>
                                        <FormattedMessage
                                             id="faqIncludedInMaintenanceAndWarrantyAnswer_MN/WI_v2"
                                             defaultMessage="{xcelEnergyCharger} If you rent the charger from us and stay in the program, ongoing maintenance is included in your monthly fee and you are eligible for a lifetime warranty for your charger.
                {lineBreak}{customerOwnedCharger}: Maintenance is not included."
                                             description="{xcelEnergyCharger} If you rent the charger from us and stay in the program, ongoing maintenance is included in your monthly fee and you are eligible for a lifetime warranty for your charger.
                {lineBreak}{customerOwnedCharger}: Maintenance is not included."
                                             values={{
                                                  lineBreak: <br />,
                                                  xcelEnergyCharger: <b>Xcel Energy-Provided Charger:</b>,
                                                  customerOwnedCharger: <b>Customer-Owned Charger:</b>,
                                             }}
                                        />
                                   </p>
                              </div>
                         ),
                         applicableTo: [EV_ACCELERATE_AT_HOME_WISCONSIN, EV_ACCELERATE_AT_HOME_MINNESOTA],
                         id: "faqIncludedInMaintenanceAndWarrantyWI/MN",
                    },
                    {
                         headLine: (
                              <span className="headline">
                                   <FormattedMessage
                                        id={`faqScheduledChargingTime${faqKey.split("-")[1]}`}
                                        defaultMessage="What if I need to adjust my scheduled charging time?"
                                        description="What if I need to adjust my scheduled charging time?"
                                        values={{
                                             stateName: region,
                                        }}
                                   />
                              </span>
                         ),
                         body: (
                              <div>
                                   <p>
                                        <FormattedMessage
                                             id={`faqScheduledChargingTime-p1`}
                                             defaultMessage="If you need to occasionally charge your EV outside your selected charging schedule, you can easily adjust your scheduled charging time."
                                             description="If you need to occasionally charge your EV outside your selected charging schedule, you can easily adjust your scheduled charging time."
                                        />
                                   </p>
                                   <ul>
                                        <li>
                                             <span>
                                                  <FormattedMessage
                                                       id={`faqScheduledChargingTime-l1`}
                                                       defaultMessage="Through your charger: Use your charger manufacturer's app to override the scheduled charging time and charge immediately. (Tesla drivers will use the Tesla app vs charger app.)"
                                                       description="Through your charger: Use your charger manufacturer's app to override the scheduled charging time and charge immediately. (Tesla drivers will use the Tesla app vs charger app.)"
                                                  />
                                             </span>
                                             <ul>
                                                  <li>
                                                       <span>
                                                            <FormattedMessage
                                                                 id={`faqScheduledChargingTime-l2`}
                                                                 defaultMessage="ChargePoint Charger, you can 'double pump' the charger by plugging in, unplugging and plugging back into the car charging port."
                                                                 description="ChargePoint Charger, you can 'double pump' the charger by plugging in, unplugging and plugging back into the car charging port."
                                                            />
                                                       </span>
                                                       <ul>
                                                            <li>
                                                                 <span>
                                                                      <FormattedMessage
                                                                           id={`faqScheduledChargingTime-l3`}
                                                                           defaultMessage="Your ChargePoint charger will revert to your scheduled charging time the next time you plug in your EV."
                                                                           description="Your ChargePoint charger will revert to your scheduled charging time the next time you plug in your EV."
                                                                      />
                                                                 </span>
                                                            </li>
                                                       </ul>
                                                  </li>
                                                  <li>
                                                       <span>
                                                            <FormattedMessage
                                                                 id={`faqScheduledChargingTime-l4`}
                                                                 defaultMessage="Enel X Juice Box - you will need to go through the EV JuiceNet Mobile App"
                                                                 description="Enel X Juice Box - you will need to go through the EV JuiceNet Mobile App"
                                                            />
                                                       </span>
                                                       <ul>
                                                            <li>
                                                                 <span>
                                                                      <FormattedMessage
                                                                           id={`faqScheduledChargingTime-l5`}
                                                                           defaultMessage="To override and charge outside of the configured Scheduled Charging window, please select “Resume” in the Enel X Way app.  If you wish to stop the override and wait for Scheduled Charging to start in its scheduled window, you will need to unplug and then reconnect your vehicle. "
                                                                           description="To override and charge outside of the configured Scheduled Charging window, please select “Resume” in the Enel X Way app.  If you wish to stop the override and wait for Scheduled Charging to start in its scheduled window, you will need to unplug and then reconnect your vehicle. "
                                                                      />
                                                                 </span>
                                                            </li>
                                                       </ul>
                                                  </li>
                                             </ul>
                                             <li>
                                                  <span>
                                                       <FormattedMessage
                                                            id={`faqScheduledChargingTime-l8`}
                                                            defaultMessage="Through your vehicle: You'll adjust your charging schedule through your vehicle's app."
                                                            description="Through your vehicle: You'll adjust your charging schedule through your vehicle's app."
                                                       />
                                                  </span>
                                             </li>
                                        </li>
                                   </ul>
                                   <p>
                                        <FormattedMessage
                                             id={`faqScheduledChargingTime-l9`}
                                             defaultMessage="If you would like to choose a different charging schedule, please contact us at"
                                             description="If you would like to choose a different charging schedule, please contact us at"
                                        />{" "}
                                        <a href="mailto:ElectricVehicles@xcelenergy.com">
                                             ElectricVehicles@xcelenergy.com
                                        </a>
                                        .
                                   </p>
                              </div>
                         ),
                         applicableTo: [
                              EV_ACCELERATE_AT_HOME_MINNESOTA,
                              EV_ACCELERATE_AT_HOME_NEW_MEXICO,
                              EV_ACCELERATE_AT_HOME_COLORADO,
                              // EV_ACCELERATE_AT_HOME_NEW_MEXICO,
                              //EV_ACCELERATE_AT_HOME_WISCONSIN,
                              // HOME_WIRING_REBATE_COLORADO,
                              // EV_ACCELERATE_AT_HOME_COLORADO,
                              // OPTIMIZE_YOUR_CHARGE_COLORADO,
                              // OPTIMIZE_YOUR_CHARGE_NEW_MEXICO,
                              // OPTIMIZE_YOUR_CHARGE_MINNESOTA
                         ],
                         id: "faqWorkWithElectricians",
                    },
                    {
                         headLine: (
                              <span className="headline">
                                   <FormattedMessage
                                        id="faqIncludedInMaintenanceAndWarranty"
                                        defaultMessage="What is included in charger maintenance and warranty?"
                                        description="What is included in charger maintenance and warranty?"
                                   />
                              </span>
                         ),
                         body: (
                              <div>
                                   <p>
                                        <FormattedMessage
                                             id="faqIncludedInMaintenanceAndWarrantyAnswer"
                                             defaultMessage="Ongoing maintenance is included in your monthly fee and you are eligible for a lifetime warranty for your charger as long as you are enrolled."
                                             description="What is included in charger maintenance and warranty? Answer"
                                        />
                                   </p>
                              </div>
                         ),
                         applicableTo: [
                              // EV_ACCELERATE_AT_HOME_COLORADO,
                              //EV_ACCELERATE_AT_HOME_NEW_MEXICO,
                         ],
                         id: "faqIncludedInMaintenanceAndWarranty",
                    },
                    {
                         headLine: (
                              <span className="headline">
                                   <FormattedMessage
                                        id="faq_ChargeOvernight_MN/WI"
                                        defaultMessage="What if I am not able to charge my EV overnight?"
                                        description="What if I am not able to charge my EV overnight?"
                                   />
                              </span>
                         ),
                         body: (
                              <div>
                                   <p>
                                        <FormattedMessage
                                             id="faq_ChargeOvernight_Answer_MN/WI"
                                             defaultMessage="You may still participate in this program, but it will likely not be the best option for you. The program is designed for EV drivers who can charge their vehicles overnight when energy prices are the lowest.{break} However, if you need to occasionally charge your EV during peak and mid-peak times, you will have that option, but you will be charged at the higher rates."
                                             description="However, if you need to occasionally charge your EV during peak and mid-peak times, you will have that option, but you will be charged at the higher rates."
                                             values={{
                                                  break: <br />,
                                             }}
                                        />
                                   </p>
                              </div>
                         ),
                         applicableTo: [EV_ACCELERATE_AT_HOME_WISCONSIN, EV_ACCELERATE_AT_HOME_MINNESOTA],
                         id: "faqChargeOvernight",
                    },
                    {
                         headLine: (
                              <span className="headline">
                                   <FormattedMessage
                                        id="chargerEvOvernightMN"
                                        defaultMessage="What if I do not charge my EV overnight?"
                                        description="What if I do not charge my EV overnight?"
                                   />
                              </span>
                         ),
                         body: (
                              <div>
                                   <p>
                                        You may still participate in this pilot program, but it will likely not be the
                                        best option for you. The program is designed for EV drivers who can charge their
                                        vehicles overnight when energy prices are the lowest.
                                        <br />
                                        However, if you need to occasionally charge your EV on weekdays between 9 a.m.
                                        and 9 p.m., you have that option. Charges during this time will incur charges in
                                        addition to your monthly subscription price. On-peak use will be charged at
                                        16.508¢ per kWh during winter months (Oct. through May) and 20.497¢ per kWh in
                                        summer months (June through Sept)
                                   </p>
                              </div>
                         ),
                         applicableTo: [EV_SUBSCRIPTION_SERVICE_PILOT_MINNESOTA],
                         id: "chargerEvOvernightMN",
                    },
                    {
                         headLine: (
                              <span className="headline">
                                   <FormattedMessage
                                        id="faqMovingTakeCharger"
                                        defaultMessage="What If I am moving and would like to take my charger?"
                                        description="What If I am moving and would like to take my charger?"
                                   />
                              </span>
                         ),
                         body: (
                              <div>
                                   <p>
                                        <FormattedMessage
                                             id="faqMovingTakeChargerAnswer_2"
                                             defaultMessage="{xcelEnergyCharger} Please contact us at {link} at least 60 days prior to the moving date. If you are moving within our service territory, we will send an electrician to remove the charger and install it at your new property.{lineBreak} {customerOwnedCharger} Please contact us at {link} at least 60 days prior to the moving date. If you are moving within our service territory, we will send an electrician to remove the charger and install it at your new property."
                                             description="What If I am moving and would like to take my charger? Answer"
                                             values={{
                                                  link: (
                                                       <a href="mailto:ElectricVehicles@xcelenergy.com">
                                                            ElectricVehicles@xcelenergy.com
                                                       </a>
                                                  ),
                                                  lineBreak: <br />,
                                                  xcelEnergyCharger: <b>Xcel Energy-Provided Charger:</b>,
                                                  customerOwnedCharger: <b>Customer-Owned Charger:</b>,
                                             }}
                                        />
                                   </p>
                              </div>
                         ),
                         applicableTo: [EV_ACCELERATE_AT_HOME_WISCONSIN, EV_ACCELERATE_AT_HOME_MINNESOTA],
                         id: "faqMovingTakeCharger",
                    },
                    {
                         headLine: (
                              <span className="headline">
                                   <FormattedMessage
                                        id="faqMovingTakeCharger"
                                        defaultMessage="What If I am moving and would like to take my charger?"
                                        description="What If I am moving and would like to take my charger?"
                                   />
                              </span>
                         ),
                         body: (
                              <div>
                                   <p>
                                        <FormattedMessage
                                             id="faqMovingTakeChargerAnswer_2"
                                             defaultMessage="{xcelEnergyCharger} Please contact us at {link} at least 60 days prior to the moving date. If you are moving within our service territory, we will send an electrician to remove the charger and install it at your new property. The relocation fee will be confirmed and billed by the electrician."
                                             description="What If I am moving and would like to take my charger? Answer"
                                             values={{
                                                  link: (
                                                       <a href="mailto:ElectricVehicles@xcelenergy.com">
                                                            ElectricVehicles@xcelenergy.com
                                                       </a>
                                                  ),
                                                  lineBreak: <br />,
                                                  xcelEnergyCharger: <b>Xcel Energy-Provided Charger:</b>,
                                             }}
                                        />
                                   </p>
                              </div>
                         ),
                         applicableTo: [EV_ACCELERATE_AT_HOME_COLORADO, EV_ACCELERATE_AT_HOME_NEW_MEXICO],
                         id: "faqMovingTakeCharger",
                    },
                    {
                         headLine: (
                              <span className="headline">
                                   <FormattedMessage
                                        id="faqCancelParticipation"
                                        defaultMessage="What if I need to cancel my participation?"
                                        description="What if I need to cancel my participation?"
                                   />
                              </span>
                         ),
                         body: (
                              <div>
                                   <p>
                                        <FormattedMessage
                                             id="faqCancelParticipationAnswer_WI_2"
                                             defaultMessage="{xcelEnergyCharger} Please contact us at {email} to cancel your participation. If you are renting the charger from us and it has been installed, Xcel Energy will assign an electrician to come remove the charger. You also have the option to buy the charger for the estimated remaining value of your specific charger. If you choose to have the charger removed, you will be returned to your previous rate.
                  {lineBreak}
                  {customerOwnedCharger} Please contact us at ElectricVehicles@xcelenergy.com to cancel your participation."
                                             description=""
                                             values={{
                                                  lineBreak: <br />,
                                                  email: (
                                                       <a href="mailto: ElectricVehicles@xcelenergy.com">
                                                            ElectricVehicles@xcelenergy.com
                                                       </a>
                                                  ),
                                                  xcelEnergyCharger: <b>Xcel Energy-Provided Charger:</b>,
                                                  customerOwnedCharger: <b>Customer-Owned Charger:</b>,
                                             }}
                                        />
                                   </p>
                              </div>
                         ),
                         applicableTo: [EV_ACCELERATE_AT_HOME_WISCONSIN, EV_ACCELERATE_AT_HOME_MINNESOTA],
                         id: "faqCancelParticipation",
                    },
                    {
                         headLine: (
                              <span className="headline">
                                   <FormattedMessage
                                        id="faqCancelParticipation-nm"
                                        defaultMessage="What if I need to cancel my participation?"
                                        description="What if I need to cancel my participation?"
                                   />
                              </span>
                         ),
                         body: (
                              <div>
                                   <p>
                                        <FormattedMessage
                                             id="faqCancelParticipationAnswer"
                                             defaultMessage="Please contact us at ElectricVehicles@xcelenergy.com to cancel your enrollment. If your charger has been installed, you will be invoiced $200 to have your charger removed or you may purchase the charger for the estimated remaining value."
                                             description="What if I need to cancel my participation?"
                                             values={{
                                                  link: (
                                                       <a href="mailto:ElectricVehicles@xcelenergy.com">
                                                            ElectricVehicles@xcelenergy.com
                                                       </a>
                                                  ),
                                             }}
                                        />
                                   </p>
                              </div>
                         ),
                         applicableTo: [EV_ACCELERATE_AT_HOME_NEW_MEXICO],
                         id: "faqCancelParticipationNM",
                    },
                    {
                         headLine: (
                              <span className="headline">
                                   <FormattedMessage
                                        id="faqCancelParticipation"
                                        defaultMessage="What if I need to cancel my participation?"
                                        description="What if I need to cancel my participation?"
                                   />
                              </span>
                         ),
                         body: (
                              <div>
                                   <p>
                                        <FormattedMessage
                                             id="faqCancelParticipationAnswer_MN"
                                             defaultMessage="Please contact us at {email} to cancel your EV program participation. For whole–home account start, stop, or transfer requests please visit {link}"
                                             description="Please contact us at"
                                             values={{
                                                  email: (
                                                       <a href="mailto: ElectricVehicles@xcelenergy.com">
                                                            ElectricVehicles@xcelenergy.com
                                                       </a>
                                                  ),
                                                  link: (
                                                       <a href="https://mn.my.xcelenergy.com/s/moving">
                                                            https://mn.my.xcelenergy.com/s/moving.
                                                       </a>
                                                  ),
                                             }}
                                        />
                                   </p>
                              </div>
                         ),
                         applicableTo: [OPTIMIZE_YOUR_CHARGE_MINNESOTA],
                         id: "faqCancelParticipationMN",
                    },
                    {
                         headLine: <span className="headline">What if I need to cancel my participation?</span>,
                         body: (
                              <div>
                                   <p>
                                        Please contact us at{" "}
                                        <a href="mailto:ElectricVehicles@xcelenergy.com">
                                             ElectricVehicles@xcelenergy.com
                                        </a>{" "}
                                        if you would like to withdraw from this pilot program.
                                   </p>
                              </div>
                         ),
                         applicableTo: ["SSP-MN"],
                         id: "whatIfINeedToCancelParticipation",
                    },
                    {
                         headLine: (
                              <span className="headline">
                                   <FormattedMessage
                                        id="faqCancelParticipation"
                                        defaultMessage="What if I need to cancel my participation?"
                                        description="What if I need to cancel my participation?"
                                   />
                              </span>
                         ),
                         body: (
                              <div>
                                   <p>
                                        <FormattedMessage
                                             id="faqCancelParticipationAnswer"
                                             defaultMessage="Please contact us at ElectricVehicles@xcelenergy.com to cancel your enrollment. If your charger has been installed, you will be invoiced $200 to have your charger removed or you may purchase the charger for the estimated remaining value."
                                             description="What if I need to cancel my participation?"
                                             values={{
                                                  link: (
                                                       <a href="mailto:ElectricVehicles@xcelenergy.com">
                                                            ElectricVehicles@xcelenergy.com
                                                       </a>
                                                  ),
                                             }}
                                        />
                                   </p>
                              </div>
                         ),
                         applicableTo: [EV_ACCELERATE_AT_HOME_COLORADO],
                         id: "faqCancelParticipationCO",
                    },
                    {
                         headLine: (
                              <span className="headline">
                                   <FormattedMessage
                                        id="faqCancelParticipation"
                                        defaultMessage="What if I need to cancel my participation?"
                                        description="What if I need to cancel my participation?"
                                   />
                              </span>
                         ),
                         body: (
                              <div>
                                   <p>
                                        <FormattedMessage
                                             id="faqCancelParticipationOYC"
                                             defaultMessage="Please contact us at ElectricVehicles@xcelenergy.com."
                                             description="Please contact us at ElectricVehicles@xcelenergy.com."
                                             values={{
                                                  link: (
                                                       <a href="mailto:ElectricVehicles@xcelenergy.com">
                                                            ElectricVehicles@xcelenergy.com
                                                       </a>
                                                  ),
                                             }}
                                        />
                                   </p>
                              </div>
                         ),
                         applicableTo: [OPTIMIZE_YOUR_CHARGE_COLORADO, OPTIMIZE_YOUR_CHARGE_NEW_MEXICO],
                         id: "faqCancelParticipation",
                    },
                    {
                         headLine: <span className="headline">What are my options at the end of the pilot?</span>,
                         body: (
                              <div>
                                   <p>
                                        If you elected to rent the charger for a monthly fee, you can have the charger
                                        removed at no cost to you after the two-year pilot ends, or you may purchase the
                                        charger from us at a prorated cost of $686. If you choose to have the charger
                                        removed, you will be returned to your previous rate.
                                        <br />
                                        If you prepaid for the charger during enrollment, we will transfer ownership of
                                        the charger to you at no cost. Please see the{" "}
                                        <a
                                             href="https://www.xcelenergy.com/staticfiles/xe-responsive/Energy%20Portfolio/Electricity/EV%20Subscription%20Pilot%20Terms%20and%20Conditions.pdf"
                                             target="_blank"
                                             rel="noopener noreferrer"
                                        >
                                             Terms &amp; Conditions
                                        </a>{" "}
                                        (PDF) for more information.
                                   </p>
                              </div>
                         ),
                         applicableTo: ["SSP-MN"],
                         id: "whatAreMyOptionsEndPilot",
                    },
                    {
                         headLine: <span className="headline">Additional Information</span>,
                         body: (
                              <div>
                                   <ul>
                                        <li>
                                             <a
                                                  href="https://www.xcelenergy.com/staticfiles/xe-responsive/Energy%20Portfolio/Electricity/EV%20Subscription%20Pilot%20Terms%20and%20Conditions.pdf"
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                             >
                                                  Terms &amp; Conditions
                                             </a>{" "}
                                             (PDF)
                                        </li>
                                        <li>
                                             <a
                                                  href="https://www.xcelenergy.com/billing_and_payment/customer_data_&_privacy/privacy_policy_and_customer_data_access"
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                             >
                                                  Enel X Data Privacy Agreement (PDF)
                                             </a>
                                        </li>
                                   </ul>
                              </div>
                         ),
                         applicableTo: [""],
                         id: "additionalInformation",
                    },

                    {
                         headLine: (
                              <span className="headline">
                                   Preview customer service agreement required at time of enrollment
                              </span>
                         ),
                         body: (
                              <div>
                                   <p>
                                        <a
                                             href="https://www.xcelenergy.com/staticfiles/xe-responsive/Energy%20Portfolio/Electricity/EV%20Subscription%20Pilot%20Terms%20and%20Conditions.pdf"
                                             target="_blank"
                                             rel="noopener noreferrer"
                                        >
                                             Terms &amp; Conditions (PDF)
                                        </a>
                                        <br />
                                        For additional questions, email us at{" "}
                                        <a href="mailto:ElectricVehicles@xcelenergy.com">
                                             ElectricVehicles@xcelenergy.com
                                        </a>
                                        .
                                   </p>
                              </div>
                         ),
                         //applicableTo: ['HSPI-WI', 'SSP-MN']
                         applicableTo: ["SSP-MN"],
                         id: "previewCustomerServiceAgreement",
                    },
                    {
                         headLine: <span className="headline">Why is Xcel Energy offering this pilot program?</span>,
                         body: (
                              <div>
                                   <p>
                                        In the years to come, we expect significant growth in electric vehicles. This
                                        pilot program will help us understand how we can effectively manage the growth
                                        of EVs in ways that can make the grid more efficient and help use more renewable
                                        energy.
                                   </p>
                              </div>
                         ),
                         applicableTo: [],
                         id: "xcelEnergyOfferingPilot",
                    },
                    {
                         headLine: <span className="headline">Why does it matter when I charge my vehicle?</span>,
                         body: (
                              <div>
                                   <p>
                                        Charging your electric vehicle (EV) during peak energy demand hours places
                                        additional strain on the energy grid. By shifting your vehicle’s charging
                                        schedule to off-peak times, you can alleviate this strain. Additionally,
                                        consider charging your EV when renewable energy sources are abundant to maximize
                                        their utilization.
                                   </p>
                              </div>
                         ),
                         applicableTo: [CHANGING_PERKS_PILOT_COLORADO],
                         id: "whyDoesItMatterWhenChargeVehicle",
                    },
                    {
                         headLine: (
                              <span className="headline">
                                   Can Charging Perks help reduce the environmental impact of charging my vehicle?
                              </span>
                         ),
                         body: (
                              <div>
                                   <p>
                                        Xcel Energy will continuously study the benefits of smart charging, focusing on
                                        efficiently utilizing our renewable energy resources for electric vehicle
                                        charging. This program sends information to your smart charging service provider
                                        during periods when the grid has an abundance of electricity from renewable
                                        resources.
                                   </p>
                              </div>
                         ),
                         applicableTo: [CHANGING_PERKS_PILOT_COLORADO],
                         id: "whyDoesItMatterWhenChargeVehicle",
                    },
                    {
                         headLine: (
                              <span className="headline">
                                   Can Charging Perks help reduce the environmental impact of charging my vehicle?
                              </span>
                         ),
                         body: (
                              <div>
                                   <p>
                                        Xcel Energy will continuously study the benefits of smart charging, focusing on
                                        efficiently utilizing our renewable energy resources for electric vehicle
                                        charging. This program sends information to your smart charging service provider
                                        during periods when the grid has an abundance of electricity from renewable
                                        resources.
                                   </p>
                              </div>
                         ),
                         applicableTo: [],
                         id: "canChargingPerksHelp",
                    },
                    {
                         headLine: <span className="headline">Will other automakers participate in this program?</span>,
                         body: (
                              <div>
                                   <p>
                                        Xcel Energy may explore expanding the program to involve additional automakers
                                        and offer opportunities to participate for more EV drivers. We recently expanded
                                        to include Wallbox model chargers, allowing more customers to enroll.
                                   </p>
                              </div>
                         ),
                         applicableTo: [CHANGING_PERKS_PILOT_COLORADO],
                         id: "willToherAutomakersParticipate",
                    },
                    {
                         headLine: <span className="headline">What participation incentive will I receive?</span>,
                         body: (
                              <div>
                                   <p>
                                        Once Xcel Energy validates your eligibility for the program, they’ll apply a $50
                                        bill credit. If you keep participating in the program, you’ll receive a $150
                                        annual bill credit reward near the end of each year. You must plug in your
                                        vehicle at least once per week to qualify.
                                   </p>
                                   <p>
                                        If you have a Level 2 charger, then you will earn an Enrollment Incentive and
                                        Annual Reward.
                                   </p>

                                   <table border="1">
                                        <tr>
                                             <th>Charger / EV</th>
                                             <th>Charging Description</th>
                                             <th>Initial Incentive</th>
                                             <th>Participation Reward</th>
                                        </tr>
                                        <tr>
                                             <td>PHEV & Level 2</td>
                                             <td>
                                                  Customers who drive an eligible Plug-in Hybrid Electric Vehicle (PHEV)
                                                  and use a Level 2 Charger at home. Level 2 Chargers require
                                                  208/240-volt electrical service and typically provide approximately 20
                                                  to 60 miles of range per hour of charging.
                                             </td>
                                             <td>$50</td>
                                             <td>$150</td>
                                        </tr>
                                        <tr>
                                             <td>BEV & Level 2</td>
                                             <td>
                                                  Customers who drive an eligible Battery Electric Vehicle (BEV) and use
                                                  a Level 2 Charger at home. Level 2 Chargers require 208/240-volt
                                                  electrical service and typically provide approximately 20 to 60 miles
                                                  of range per hour of charging.
                                             </td>
                                             <td>$50</td>
                                             <td>$150</td>
                                        </tr>
                                   </table>
                              </div>
                         ),
                         applicableTo: [CHANGING_PERKS_PILOT_COLORADO],
                         id: "whatParticipationIncentiveWillReceive",
                    },
                    {
                         headLine: (
                              <span className="headline">How do I utilize the e-Gift Cards from the pilot phase?</span>
                         ),
                         body: (
                              <div>
                                   <p>
                                        If you previously enrolled in Charging Perks during its pilot phase, Xcel Energy
                                        rewarded you with an e-gift card as the financial incentive. If you encounter
                                        any issues or have questions about redeeming or using your e-gift card, please
                                        reach out to Tango Card customer service at{" "}
                                        <a href="mailto:cs@tangocard.com">cs@tangocard.com</a>.
                                   </p>
                                   <p>
                                        Now that Charging Perks has transitioned into a full program, incentives will be
                                        provided via bill credits.
                                   </p>
                              </div>
                         ),
                         applicableTo: [CHANGING_PERKS_PILOT_COLORADO],
                         id: "howDoIUtilizeGiftCards",
                    },
                    //  {
                    //    headLine: (
                    //      <span className="headline">
                    //        How long will it take to receive my enrollment reward?
                    //      </span>
                    //    ),
                    //    body: (
                    //      <div>
                    //        <p>
                    //          After receiving your enrollment confirmation email from Xcel
                    //          Energy, please allow 2+ weeks for your e-gift card to arrive in
                    //          your inbox.
                    //        </p>
                    //      </div>
                    //    ),
                    //    applicableTo: [CHANGING_PERKS_PILOT_COLORADO],
                    //    id: 'howLongWillItTakeToReceive'
                    //  },
                    //  {
                    //    headLine: (
                    //      <span className="headline">
                    //        Who do I contact if I am having problems redeeming or using my
                    //        gift card?
                    //      </span>
                    //    ),
                    //    body: (
                    //      <div>
                    //        <p>
                    //          If you have any problems or questions redeeming or using your
                    //          e-gift card, please contact Tango Card customer service at{" "}
                    //          <a href={"mailto:cs@tangocard.com"}>cs@tangocard.com.</a>
                    //        </p>
                    //      </div>
                    //    ),
                    //    applicableTo: [CHANGING_PERKS_PILOT_COLORADO],
                    //    id: 'whoDoIContactIfHavingProblems'
                    //  },
                    {
                         headLine: <span className="headline">Will I save on my electric bill?</span>,
                         body: (
                              <div>
                                   <p>
                                        The pilot is not designed to affect your electric bill. Customers will receive
                                        gift cards for enrollment and participation, but your electric rate will remain
                                        the same.
                                   </p>
                                   <p>
                                        If you are on a time-of-use rate, this pilot will schedule much of your charging
                                        for late night and early morning hours, which are off-peak times. Depending on
                                        how you previously charged your vehicle, this pilot may provide additional
                                        savings on your bill if more of your charging during the pilot is completed
                                        during the off-peak time period.
                                   </p>
                              </div>
                         ),
                         applicableTo: [],
                    },
                    {
                         headLine: <span className="headline">How will my charging be scheduled?</span>,
                         body: (
                              <div>
                                   <p>
                                        Your smart charging service provider will generate an optimized charging
                                        schedule tailored to your preferences with insights on best charging times from
                                        Xcel Energy. This schedule will be automatically sent to your car, simplifying
                                        the process. Just plug in when you arrive home, and your car will handle the
                                        rest.
                                   </p>
                              </div>
                         ),
                         applicableTo: [CHANGING_PERKS_PILOT_COLORADO],
                    },
                    {
                         headLine: <span className="headline">What price signals are used for Charging Perks?</span>,
                         body: (
                              <div>
                                   <p>
                                        Price signals are based on day-ahead forecasts of power production costs,
                                        day-ahead forecast of renewable generation, and day-ahead forecasts of weather.
                                        Low price signals represent the better times to charge EVs due to cheaper,
                                        cleaner energy. The price signal graphic is an example of a daily proxy price
                                        signal where you can see the best off-peak times to charge at the lower price
                                        points.
                                   </p>
                              </div>
                         ),
                         applicableTo: [CHANGING_PERKS_PILOT_COLORADO],
                    },
                    {
                         headLine: <span className="headline">What if I need to change my EV charging schedule?</span>,
                         body: (
                              <div>
                                   <p>
                                        The program terms allow you to change your EV charging schedule whenever you
                                        want, without affecting your program rewards or program status. To make changes
                                        to your charging schedule, visit your manufacturer’s website or app.
                                   </p>
                              </div>
                         ),
                         applicableTo: [CHANGING_PERKS_PILOT_COLORADO],
                    },
                    {
                         headLine: (
                              <span className="headline">What if I no longer want to participate in the program?</span>
                         ),
                         body: (
                              <div>
                                   <p>
                                        You are free to leave the program without penalty whenever you need. Please
                                        contact your automaker to unenroll:
                                   </p>
                                   <p>
                                        <b>BMW: </b>
                                        <a href={"mailto:bmwgenius@bmwusa.com"}>bmwgenius@bmwusa.com</a>
                                   </p>
                                   <p>
                                        <b>Chevrolet:</b> Visit{" "}
                                        <a
                                             href="https://www.smartcharging.chevrolet.com"
                                             target="_blank"
                                             rel="noopener noreferrer"
                                        >
                                             {" "}
                                             smartcharging.chevrolet.com
                                        </a>
                                   </p>
                                   <p>
                                        <b>Ford:</b> Visit your Ford{" "}
                                        <a
                                             href="https://nam04.safelinks.protection.outlook.com/?url=https%3A%2F%2Fwww.ford.com%2Fgrid%2Fxcel%2Fdashboard&data=05%7C02%7CCarlos.A.Hill%40xcelenergy.com%7C724678ac5a93442eb14608dc7c32f330%7C24b2a5835c054b6ab4e94e12dc0025ad%7C0%7C0%7C638521805479169624%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C0%7C%7C%7C&sdata=xPaS4wEi%2Fa2FJx93pKAwMecgu03emwl6WxR98XNzowo%3D&reserved=0"
                                             target="_blank"
                                             rel="noopener noreferrer"
                                        >
                                             {" "}
                                             Account Dashboard{" "}
                                        </a>
                                        or call 800-392-3673.
                                   </p>
                                   <p>
                                        <b>Telsa:</b> please email{" "}
                                        <a href={"xcelenergy-support@weavegrid.comm"}>
                                             xcelenergy-support@weavegrid.com
                                        </a>{" "}
                                        with your request to unenroll.
                                   </p>
                                   <p>
                                        <b>Wallbox:</b> email{" "}
                                        <a href={"xcelenergy-support@weavegrid.comm"}>
                                             xcelenergy-support@weavegrid.com
                                        </a>{" "}
                                        with your request to unenroll.
                                   </p>
                              </div>
                         ),
                         applicableTo: [CHANGING_PERKS_PILOT_COLORADO],
                    },
                    //  {
                    //    headLine: (
                    //      <span className="headline">
                    //        What data are you collecting from my vehicle?
                    //      </span>
                    //    ),
                    //    body: (
                    //      <div>
                    //        <p>
                    //               Your vehicle manufacturer is accessing vehicle charging data. Once you provide permission to gather this charging data through the enrollment process, your vehicle manufacturer will provide charging information about your vehicle to Xcel Energy. Xcel Energy uses this information to evaluate the benefits of smart charging.
                    //        </p>
                    //      </div>
                    //    ),
                    //    applicableTo: [CHANGING_PERKS_PILOT_COLORADO],
                    //  },
                    {
                         headLine: (
                              <span id="adjust-power-faq" className="headline">
                                   Can I adjust the power on my charger?
                              </span>
                         ),
                         body: (
                              <div>
                                   <p>
                                        Yes! You can adjust the amperage on the ChargePoint Home Flex and the Enel X
                                        Juice Box 40 to best match your home's unique wiring and your preferences. When
                                        our electrician installs your charger, they will set charger’s amperage for you.
                                   </p>
                                   <p>Supported amperage by charger:</p>
                                   <table style={{ width: "100%" }}>
                                        <tbody>
                                             <tr>
                                                  <th className="dark-gray">
                                                       Minimum Circuit Rating
                                                       <Tooltip
                                                            id="minimum-circuit-rating-tooltip"
                                                            message="Dependent on breaker size. The circuit must be rated for 125% of the connected load."
                                                            img={tooltipImage}
                                                       />
                                                  </th>
                                                  <th className="dark-gray">
                                                       <>
                                                            Charger Amp Setting
                                                            <Tooltip
                                                                 id="charger-amp-setting-tooltip"
                                                                 message="This is the maximum connected load for a given circuit rating"
                                                                 img={tooltipImage}
                                                            />
                                                       </>
                                                  </th>
                                                  <th className="dark-gray">ChargePoint Home Flex</th>
                                                  <th className="dark-gray">Enel X Juice Box 40</th>
                                             </tr>
                                        </tbody>
                                        <tbody>
                                             <tr className="light-gray">
                                                  <td>60 amps</td>
                                                  <td>48 amps</td>
                                                  <td>
                                                       <img src={redCheck} alt="red check" />
                                                       &nbsp;
                                                  </td>
                                                  <td></td>
                                             </tr>
                                        </tbody>
                                        <tbody>
                                             <tr>
                                                  <td>50 amps*</td>
                                                  <td>40 amps*</td>
                                                  <td>
                                                       <img src={redCheck} alt="red check" />*
                                                  </td>
                                                  <td>
                                                       <img src={redCheck} alt="red check" />*
                                                  </td>
                                             </tr>
                                        </tbody>
                                        <tbody>
                                             <tr>
                                                  <td>40 amps*</td>
                                                  <td>32 amps*</td>
                                                  <td>
                                                       <img src={redCheck} alt="red check" />*
                                                  </td>
                                                  <td>
                                                       <img src={redCheck} alt="red check" />*
                                                  </td>
                                             </tr>
                                        </tbody>
                                        <tbody>
                                             <tr className="light-gray">
                                                  <td>30 amps</td>
                                                  <td>24 amps</td>
                                                  <td>
                                                       <img src={redCheck} alt="red check" />
                                                       &nbsp;
                                                  </td>
                                                  <td>
                                                       <img src={redCheck} alt="red check" />
                                                       &nbsp;
                                                  </td>
                                             </tr>
                                        </tbody>
                                        <tbody>
                                             <tr className="light-gray">
                                                  <td>20 amps</td>
                                                  <td>16 amps</td>
                                                  <td>
                                                       <img src={redCheck} alt="red check" />
                                                       &nbsp;
                                                  </td>
                                                  <td>
                                                       <img src={redCheck} alt="red check" />
                                                       &nbsp;
                                                  </td>
                                             </tr>
                                        </tbody>
                                   </table>
                                   <div className="text-right">*most common configurations</div>
                              </div>
                         ),
                         applicableTo: [],
                    },
                    {
                         headLine: (
                              <span className="headline">
                                   <FormattedMessage
                                        id="faqRebateCOIncomeRequirements"
                                        defaultMessage="What are the income requirements?"
                                        description="What are the income requirements?"
                                   />
                              </span>
                         ),
                         body: (
                              <div>
                                   <p>
                                        <FormattedMessage
                                             id="faqHWRQualifiyRebateAnswerFour"
                                             defaultMessage="To meet the “Income-Qualified” requirement, you must have a household income below"
                                             description="To meet the “Income-Qualified” requirement, you must have a household income below"
                                        />
                                        :
                                   </p>
                                   <ul>
                                        <li>
                                             <FormattedMessage
                                                  id="coloradoSixtyMedianIncome"
                                                  defaultMessage="60% of the state of Colorado’s median income, {OR}"
                                                  description="60% of the state of Colorado’s median income, {OR}"
                                                  values={{
                                                       OR: <strong>OR</strong>,
                                                  }}
                                             />
                                        </li>
                                        <li>
                                             <FormattedMessage
                                                  id="coloradoTwoHundredFederalPoverty_alt"
                                                  defaultMessage="200% of the relevant federal poverty level, {OR}"
                                                  description="200% of the relevant federal poverty level, {OR}"
                                                  values={{
                                                       OR: <strong>OR</strong>,
                                                  }}
                                             />
                                        </li>
                                        <li>
                                             <FormattedMessage
                                                  id="coloradoMedianIncomeArea_alt"
                                                  defaultMessage="80% of area median income (varies by county. For a full area Median Income table, {link}):"
                                                  description="80% of area median income (varies by county. For a full area Median Income table, {link}):"
                                                  values={{
                                                       link: (
                                                            <a
                                                                 href="https://xcelnew.my.salesforce.com/sfc/p/#1U0000011ttV/a/8b000002b0oC/hZ9ON6pdietK9e62ETAHRbT2_kYrddyKhDb7KT6zIQ0"
                                                                 target="_blank"
                                                                 rel="noopener noreferrer"
                                                            >
                                                                 click here
                                                            </a>
                                                       ),
                                                  }}
                                             />
                                        </li>
                                   </ul>
                              </div>
                         ),
                         applicableTo: [INCOME_QUALIFIED_COLORADO],
                         id: "faqRebateCOIncomeRequirements",
                    },
                    {
                         headLine: (
                              <span className="headline">
                                   <FormattedMessage
                                        id="faqRebateCOEVsQualify"
                                        defaultMessage="What electric vehicles qualify for the EV rebate?"
                                        description="What electric vehicles qualify for the EV rebate?"
                                   />
                              </span>
                         ),
                         body: (
                              <div>
                                   <p>
                                        <FormattedMessage
                                             id="faqRebateCOEVsQualifyAnswer"
                                             defaultMessage="You can get a rebate on any new or used plug-in electric vehicle (battery electric or plug-in hybrid electric) with a manufacturer’s suggested retail price for a new EV (or the final negotiated price for a pre-owned EV) of $50,000 or less. You can buy or lease a new or used EV from any Colorado-based car dealer. Lease term must be at least two years.  Learn more about which EV is right for you."
                                             description="Rebate CO EVs Qualify Answer"
                                             values={{
                                                  lineBreak: <br />,
                                                  link: (
                                                       <a href="/vehicles" target="_blank" rel="noopener noreferrer">
                                                            <FormattedMessage
                                                                 id="EVRightForYou"
                                                                 defaultMessage="EV is right for you"
                                                                 description="EV is right for you"
                                                            />
                                                       </a>
                                                  ),
                                             }}
                                        />
                                   </p>
                              </div>
                         ),
                         applicableTo: [INCOME_QUALIFIED_COLORADO],
                         id: "faqRebateCOEVsQualify",
                    },
                    {
                         headLine: (
                              <span className="headline">
                                   {" "}
                                   <FormattedMessage
                                        id="faqRebateCOWhereGetEV"
                                        defaultMessage="Where can I get an EV?"
                                        description="Where can I get an EV?"
                                   />
                              </span>
                         ),
                         body: (
                              <div>
                                   <p>
                                        <FormattedMessage
                                             id="faqRebateCOWhereGetEVAnswer"
                                             defaultMessage="To get the rebate up front while you are buying or leasing an EV, you must be pre-qualified and take your pre-qualification code to one of the EV Dealers in our EV Dealer Network.  To get the rebate after you purchase or lease the EV, you can buy or lease your EV from any Colorado-based dealer, whether they are in our dealer network or not."
                                             description="Rebate CO Where Get EV Answer"
                                             values={{
                                                  lineBreak: <br />,
                                                  link: (
                                                       <a href="/dealers" target="_blank" rel="noopener noreferrer">
                                                            <FormattedMessage
                                                                 id="evDealerNetwork"
                                                                 defaultMessage="EV Dealer Network"
                                                                 description="EV Dealer Network"
                                                            />
                                                       </a>
                                                  ),
                                             }}
                                        />
                                   </p>
                              </div>
                         ),
                         applicableTo: [INCOME_QUALIFIED_COLORADO],
                         id: "faqRebateCOWhereGetEV",
                    },
                    {
                         headLine: (
                              <span className="headline">
                                   <FormattedMessage
                                        id="faqRebateCOPreQualified"
                                        defaultMessage="How do I get pre-qualified?"
                                        description="How do I get pre-qualified?"
                                   />
                              </span>
                         ),
                         body: (
                              <div>
                                   <p>
                                        <FormattedMessage
                                             id="faqRebateCOPreQualifiedAnswer"
                                             defaultMessage="First, you must complete and submit the EV Rebate application. You must be an Xcel Energy residential electricity customer. Then, we will verify your income eligibility. Our partner, GRID Alternatives Colorado, will be in touch with you about the information needed from you to complete this process. Pre-qualification can take about 2 weeks from the time all the information necessary to process your application is received from you.  Get help with your questions about income qualification by contacting our partner, GRID Alternatives Colorado, at 866-434-1690 or information@coloradoevs.org."
                                             description="Rebate CO PreQualified Answer"
                                             values={{
                                                  lineBreak: <br />,
                                                  phone: <a href="tel:8664341690">866-434-1690</a>,
                                                  mail: (
                                                       <a href="mailto:information@coloradoevs.org">
                                                            information@coloradoevs.org
                                                       </a>
                                                  ),
                                             }}
                                        />
                                   </p>
                              </div>
                         ),
                         applicableTo: [INCOME_QUALIFIED_COLORADO],
                         id: "faqRebateCOPreQualified",
                    },
                    {
                         headLine: (
                              <span className="headline">
                                   <FormattedMessage
                                        id="faqRebateCOReceiveRebate"
                                        defaultMessage="When will I receive my rebate?"
                                        description="When will I receive my rebate?"
                                   />
                              </span>
                         ),
                         body: (
                              <div>
                                   <p>
                                        <FormattedMessage
                                             id="faqRebateCOReceiveRebateAnswer"
                                             defaultMessage="If you fill out our application before you shop and get pre-qualified, you get an instant rebate when you buy or lease your EV. You must buy or lease your EV from a dealer in our EV Dealer Network, and that dealer will reduce the price of the EV by the amount of the rebate. Pre-qualification can be done in as quickly as 10 days once we have all your information, and you will receive a code to take to the dealer for your instant rebate. You must use the code within 90 days or you’ll have to re-start the application process."
                                             values={{
                                                  lineBreak: <br />,
                                                  bold1: (
                                                       <b>
                                                            <FormattedMessage
                                                                 id="beforeYouShop"
                                                                 defaultMessage="Before you shop"
                                                                 description="Before you shop"
                                                            />
                                                       </b>
                                                  ),
                                                  bold2: (
                                                       <b>
                                                            <FormattedMessage
                                                                 id="getPreQualified"
                                                                 defaultMessage="get pre-qualified"
                                                                 description="get pre-qualified"
                                                            />
                                                       </b>
                                                  ),
                                                  bold3: (
                                                       <b>
                                                            <FormattedMessage
                                                                 id="instantRebate"
                                                                 defaultMessage="instant rebate"
                                                                 description="instant rebate"
                                                            />
                                                       </b>
                                                  ),
                                                  link1: (
                                                       <a href="/dealers" target="_blank" rel="noopener noreferrer">
                                                            <FormattedMessage
                                                                 id="evDealerNetwork"
                                                                 defaultMessage="EV Dealer Network"
                                                                 description="EV Dealer Network"
                                                            />
                                                       </a>
                                                  ),
                                             }}
                                        />
                                   </p>
                                   <p>
                                        <FormattedMessage
                                             id="faqRebateCOReceiveRebateAnswerTwo"
                                             defaultMessage="If you buy or lease your EV first and submit an EV Rebate application afterward, you will get your rebate in the form of a check 6-8 weeks after your application is approved. You must meet the income requirements and all other requirements of the EV Rebate program to qualify for the rebate. You can buy or lease an EV from any Colorado-based dealer, whether they are in our EV Dealer Network or not, and receive your rebate after you’ve purchased the vehicle."
                                             description="When will I receive my rebate Answer Two"
                                             values={{
                                                  bold1: (
                                                       <b>
                                                            <FormattedMessage
                                                                 id="buyOrLeaseFirst"
                                                                 defaultMessage="buy or lease your EV first"
                                                                 description="buy or lease your EV first"
                                                            />
                                                       </b>
                                                  ),
                                                  bold2: (
                                                       <b>
                                                            <FormattedMessage
                                                                 id="submitRebateApplication"
                                                                 defaultMessage="submit an EV Rebate application afterward"
                                                                 description="submit an EV Rebate application afterward"
                                                            />
                                                       </b>
                                                  ),
                                                  bold3: (
                                                       <b>
                                                            <FormattedMessage
                                                                 id="rebateInFormCheck"
                                                                 defaultMessage="rebate in the form of a check"
                                                                 description="rebate in the form of a check"
                                                            />
                                                       </b>
                                                  ),
                                                  bold4: (
                                                       <b>
                                                            <FormattedMessage
                                                                 id="any"
                                                                 defaultMessage="any"
                                                                 description="any"
                                                            />
                                                       </b>
                                                  ),
                                             }}
                                        />
                                   </p>
                                   <p>
                                        <FormattedMessage
                                             id="faqRebateCOReceiveRebateAnswerThree"
                                             defaultMessage="Full terms and conditions for the EV Rebate program can be found with the rebate application here."
                                             description="Full terms and conditions for the EV Rebate program can be found with the rebate application here."
                                             values={{
                                                  link: (
                                                       <a
                                                            href="https://www.xcelenergy.com/staticfiles/xe-responsive/Marketing/21-07-533_CO-EV-CarRebate_app_P03.pdf"
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                       >
                                                            <FormattedMessage
                                                                 id="here"
                                                                 defaultMessage="here"
                                                                 description="here"
                                                            />
                                                       </a>
                                                  ),
                                             }}
                                        />
                                   </p>
                              </div>
                         ),
                         applicableTo: [INCOME_QUALIFIED_COLORADO],
                         id: "faqRebateCOReceiveRebate",
                    },
                    {
                         headLine: (
                              <span className="headline">
                                   <FormattedMessage
                                        id="faqRebateCOChargeMyEV"
                                        defaultMessage="Where can I charge my EV?"
                                        description="Where can I charge my EV?"
                                   />
                              </span>
                         ),
                         body: (
                              <div>
                                   <p>
                                        <FormattedMessage
                                             id="faqRebateCOChargeMyEVAnswer"
                                             defaultMessage="You can find lots of places to charge your EV when you’re on the go. Look for public charging at grocery stores, hotels, parking ramps, parks and more."
                                             description="Where can I charge EV answer"
                                             values={{
                                                  link: (
                                                       <a
                                                            href="/charging-stations"
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                       >
                                                            <FormattedMessage
                                                                 id="lookForPublicChargingExamples"
                                                                 defaultMessage="Look for public charging at grocery stores, hotels, parking ramps, parks and more."
                                                                 description="Look for public charging at grocery stores, hotels, parking ramps, parks and more."
                                                            />
                                                       </a>
                                                  ),
                                             }}
                                        />
                                   </p>
                                   <p>
                                        <FormattedMessage
                                             id="faqRebateCOChargeMyEVAnswerTwo"
                                             defaultMessage="Charging your electric vehicle at home is easy with our home charging programs."
                                             description="Where can I charge EV answer two"
                                             values={{
                                                  link: (
                                                       <a
                                                            href="/ev-charging-programs"
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                       >
                                                            <FormattedMessage
                                                                 id="ourHomeChargingPrograms"
                                                                 defaultMessage="our home charging programs"
                                                                 description="our home charging programs"
                                                            />
                                                       </a>
                                                  ),
                                             }}
                                        />
                                        <ul>
                                             <li>
                                                  <FormattedMessage
                                                       id="faqRebateCOEVAAH"
                                                       defaultMessage="With EV Accelerate At Home, we install a Level 2 EV charger at your home and maintain it, all for a low monthly fee."
                                                       description="With EV Accelerate At Home, we install a Level 2 EV charger at your home and maintain it, all for a low monthly fee."
                                                  />
                                             </li>
                                             <li>
                                                  <FormattedMessage
                                                       id="faqRebateCOOYC"
                                                       defaultMessage="With Optimize Your Charge, you choose from three off-peak charging schedule options and get a $50 annual credit on your electric bill."
                                                       description="With Optimize Your Charge, you choose from three off-peak charging schedule options and get a $50 annual credit on your electric bill."
                                                  />
                                             </li>
                                        </ul>
                                   </p>
                                   <p>
                                        <FormattedMessage
                                             id="faqRebateCOChargeMyEVAnswerThree"
                                             defaultMessage="And be sure to check out our Home Wiring Rebate. If you meet our income requirements you can get $1,300 toward the cost of installing a Level 2 charger at your home.  More information about charging your EV can be found here: EV Charging 101."
                                             description="Where can I charge EV answer two"
                                             values={{
                                                  lineBreak: <br />,
                                                  link1: (
                                                       <a
                                                            href="/home-wiring-rebate"
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                       >
                                                            <FormattedMessage
                                                                 id="faqRebateCOHomeWiringRebate"
                                                                 defaultMessage="Home Wiring Rebate"
                                                                 description="Home Wiring Rebate"
                                                            />
                                                       </a>
                                                  ),
                                                  link2: (
                                                       <a
                                                            href="/charging-101"
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                       >
                                                            <FormattedMessage
                                                                 id="evChargingOneZeroOne"
                                                                 defaultMessage="EV Charging 101"
                                                                 description="EV Charging 101"
                                                            />
                                                       </a>
                                                  ),
                                             }}
                                        />
                                   </p>
                              </div>
                         ),
                         applicableTo: [INCOME_QUALIFIED_COLORADO],
                         id: "faqRebateCOChargeMyEV",
                    },
                    {
                         headLine: (
                              <span className="headline">
                                   <FormattedMessage
                                        id="faqRebateCOPayTaxes"
                                        defaultMessage="Do I have to pay taxes on my rebate?"
                                        description="Do I have to pay taxes on my rebate?"
                                   />
                              </span>
                         ),
                         body: (
                              <div>
                                   <p>
                                        <FormattedMessage
                                             id="faqRebateCOPayTaxesAnswer"
                                             defaultMessage="If you buy an EV from a dealer outside our EV Dealer Network, the rebate cannot be provided instantly, and you may have to pay income tax on the rebate amount. You have 2 options:"
                                             description="Have to pay taxes answer"
                                        />
                                   </p>
                                   <p>
                                        <ul>
                                             <li>
                                                  <FormattedMessage
                                                       id="faqRebateCOPayTaxesAnswerOption1"
                                                       defaultMessage="If you get prequalified and then buy from a dealer outside of our EV Dealer Network, you can receive the rebate in the form of a check from us, which you will receive within 6-8 weeks of providing proof of purchase and a completed IRS Form W9."
                                                       description="If you get prequalified and then buy from a dealer outside of our EV Dealer Network, you can receive the rebate in the form of a check from us, which you will receive within 6-8 weeks of providing proof of purchase and a completed IRS Form W9."
                                                  />
                                             </li>
                                             <li>
                                                  <FormattedMessage
                                                       id="faqRebateCOPayTaxesAnswerOption2"
                                                       defaultMessage="If you’ve already purchased your vehicle and you want to see if you can still receive the rebate, you can submit the rebate application and if we determine that you are income-qualified, you’ve purchased an eligible vehicle, and you submit a completed IRS Form W9, we’ll send you a rebate check within 6-8 weeks."
                                                       description="If you’ve already purchased your vehicle and you want to see if you can still receive the rebate, you can submit the rebate application and if we determine that you are income-qualified, you’ve purchased an eligible vehicle, and you submit a completed IRS Form W9, we’ll send you a rebate check within 6-8 weeks."
                                                  />
                                             </li>
                                        </ul>
                                   </p>
                              </div>
                         ),
                         applicableTo: [INCOME_QUALIFIED_COLORADO],
                         id: "faqRebateCOPayTaxes",
                    },
                    {
                         headLine: (
                              <span className="headline">
                                   <FormattedMessage
                                        id="faqRebateMNEnrollMore"
                                        defaultMessage="How can I enroll more than once?"
                                        description="How can I enroll more than once?"
                                   />
                              </span>
                         ),
                         body: (
                              <div>
                                   <p>
                                        <FormattedMessage
                                             id="faqRebateMNEnrollMoreAnswer"
                                             defaultMessage="To enroll in EV Accelerate At Home more than once at the same premise/account you must apply through a {Guest} account (see below steps). Currently, you will receive an error if the second enrollment is done through MyAccount.​"
                                             description="Enroll More Answer"
                                             values={{
                                                  Guest: <strong>"guest"</strong>,
                                             }}
                                        />
                                   </p>
                                   <p>
                                        <ul>
                                             <li>
                                                  <FormattedMessage
                                                       id="faqRebateMNEnrollMoreAnswerPartOne"
                                                       defaultMessage="{Steps}​ Click the {Enroll} button on this page > Enter zip code for where charging station will be located > Select if you have electric services with Xcel Energy > Select {Residential} > Select if you have Wi-Fi at the premise > Select {Continue}.​"
                                                       description="Steps to Enroll:​Click the “Enroll Now” button on this page > Enter zip code for where charging station will be located > Select if you have electric services with Xcel Energy > Select “Residential” > Select if you have Wi-Fi at the premise > Select “Continue as Guest”.​"
                                                       values={{
                                                            Steps: <strong>Steps to Enroll:</strong>,
                                                            Enroll: <strong>"Enroll Now"</strong>,
                                                            Residential: <strong>"Residential"</strong>,
                                                            Continue: <strong>"Continue as Guest"</strong>,
                                                       }}
                                                  />
                                             </li>
                                             <li>
                                                  <FormattedMessage
                                                       id="faqRebateMNEnrollMoreAnswerPartTwo"
                                                       defaultMessage="{Chargepoint}​ ChargePoint does not currently have the capabilities to have two charging stations registered to one account. If you have two ChargePoint chargers, then two email addresses must be used to create two separate accounts per serial number.​"
                                                       description="ChargePoint Customers with Two Chargers:​ChargePoint does not currently have the capabilities to have two charging stations registered to one account. If you have two ChargePoint chargers, then two email addresses must be used to create two separate accounts per serial number.​"
                                                       values={{
                                                            Chargepoint: (
                                                                 <strong>
                                                                      ChargePoint Customers with Two Chargers:
                                                                 </strong>
                                                            ),
                                                       }}
                                                  />
                                             </li>
                                        </ul>
                                   </p>
                              </div>
                         ),
                         applicableTo: [
                              EV_ACCELERATE_AT_HOME_MINNESOTA,
                              EV_ACCELERATE_AT_HOME_COLORADO,
                              EV_ACCELERATE_AT_HOME_NEW_MEXICO,
                              EV_ACCELERATE_AT_HOME_WISCONSIN,
                         ],
                         id: "faqRebateMNEnrollMore",
                    },
                    {
                         headLine: <span className="headline">Who provides what for installation?</span>,
                         body: (
                              <div>
                                   <table class="table">
                                        <tr>
                                             <th>
                                                  <span class="bold-red">Xcel Energy Provides:</span>
                                             </th>
                                             <th>
                                                  <span class="bold-red">Customer installs:</span>
                                             </th>
                                        </tr>
                                        <tr>
                                             <td>
                                                  <ul>
                                                       <li>EV Billing Meter</li>
                                                  </ul>
                                             </td>
                                             <td>
                                                  <ul>
                                                       <li>Meter socket(s) (with a lever bypass)</li>
                                                       <li>Conduit and wiring</li>
                                                       <li>EV charger or dedicated wall outlet</li>
                                                  </ul>
                                             </td>
                                        </tr>
                                   </table>
                              </div>
                         ),
                         applicableTo: [TIME_OF_DAY_SEPARATE_METER_MINNESOTA],
                    },
                    {
                         headLine: (
                              <span className="headline">Where do I install the meter socket and service box?</span>
                         ),
                         body: (
                              <div>
                                   <img src={MeterDiagram} alt="Meter Diagram" />
                              </div>
                         ),
                         applicableTo: [TIME_OF_DAY_SEPARATE_METER_MINNESOTA],
                    },
                    {
                         headLine: (
                              <span className="headline">
                                   Can I enroll in the EV Accelerate At Home Program with this option?
                              </span>
                         ),
                         body: (
                              <div>
                                   <p>
                                        No- the Time of Day Separate meter option is a dedicated meter for the EV
                                        charger you elect to install. If you would like to participate in EV Accelerate
                                        At Home you will need to install either a Time of Day Whole Home program meter
                                        or a regular residential meter. For more detailed information please view the
                                        following FAQ: Can I install the meter socket on a detached garage?
                                   </p>
                              </div>
                         ),
                         applicableTo: [TIME_OF_DAY_SEPARATE_METER_MINNESOTA],
                    },
                    {
                         headLine: <span className="headline">Can I install this as a sub-meter? </span>,
                         body: (
                              <div>
                                   <p>No. Industry best practice for safety is a dedicated service.</p>
                              </div>
                         ),
                         applicableTo: [TIME_OF_DAY_SEPARATE_METER_MINNESOTA],
                    },
                    {
                         headLine: <span className="headline">Is a lever bypass meter socket required?</span>,
                         body: (
                              <div>
                                   <p>
                                        Yes. The meter socket for the EV must be a lever bypass from a manufacturer on
                                        our approved list. It must also conform to all other standards as depicted in
                                        section 4.13 from our Standard for Electrical Installation and Use.
                                   </p>
                              </div>
                         ),
                         applicableTo: [TIME_OF_DAY_SEPARATE_METER_MINNESOTA],
                    },
                    {
                         headLine: (
                              <span className="headline">
                                   What voltage charging equipment can I install for the EV Pricing Plan?
                              </span>
                         ),
                         body: (
                              <div>
                                   <p>
                                        EV chargers that use 120V, 240V or 208V (network) are all allowed. Available
                                        voltage will be dependent on existing distribution facilities in the area.
                                   </p>
                              </div>
                         ),
                         applicableTo: [TIME_OF_DAY_SEPARATE_METER_MINNESOTA],
                    },
                    {
                         headLine: (
                              <span className="headline">Can I install the meter socket on a detached garage? </span>
                         ),
                         body: (
                              <div>
                                   <p>
                                        There are two options for customers wishing to charge their vehicle in a
                                        detached garage: 1. The customer may participate in the EV Pricing Plan by
                                        installing the EV meter within 2’ of the existing meter. If the mainhouse meter
                                        is not on the garage, a line can be run to the detached garage. 2. The customer
                                        may participate in Time of Day pricing instead of the EV Pricing Plan, and
                                        install a Time of Day meter on the detached garage. In this case, the panel can
                                        be used for additional charges besides an electric vehicle. A second service
                                        would need to be requested and started at an extra cost to the customer.
                                   </p>
                              </div>
                         ),
                         applicableTo: [TIME_OF_DAY_SEPARATE_METER_MINNESOTA],
                    },
                    {
                         headLine: <span className="headline">Can I install a fast charger?</span>,
                         body: (
                              <div>
                                   <p>
                                        {" "}
                                        Yes. Prior to installing a DC fast charger or an AC Level 2 charger, please call
                                        the Builder’s Call Line at 800.628.2121 to check if a significant load increase
                                        will necessitate a service upgrade. With prior notification, we can make the
                                        necessary system modifications to continue to reliably serve the EV customer and
                                        surrounding community.
                                   </p>
                              </div>
                         ),
                         applicableTo: [TIME_OF_DAY_SEPARATE_METER_MINNESOTA],
                    },
                    {
                         headLine: (
                              <span className="headline">
                                   Who do I call at Xcel Energy to confirm the meter specifications?
                              </span>
                         ),
                         body: (
                              <div>
                                   <ul>
                                        <li>Technical metering questions? Call 800.422.0782.</li>
                                        <li>General questions? We’re available 24 hours a day at 800.895.4999</li>
                                   </ul>
                              </div>
                         ),
                         applicableTo: [TIME_OF_DAY_SEPARATE_METER_MINNESOTA],
                    },
                    {
                         headLine: <span className="headline">Need more Guidance?</span>,
                         body: (
                              <div>
                                   <p>
                                        Consult the{" "}
                                        <a
                                             href="https://www.xcelenergy.com/staticfiles/xe-responsive/Admin/Managed%20Documents%20&%20PDFs/Xcel-Energy-Standard-For-Electric-Installation-and-Use.pdf"
                                             target="_blank"
                                             rel="noopener noreferrer"
                                        >
                                             Standard for Electric Installation and Use manual
                                        </a>
                                        .<br />
                                        Key sections related to the EV Pricing Plan including (but are not limited to):
                                   </p>
                                   <ul>
                                        <li>
                                             <b>4.10.3</b> Meter installation & Ownership
                                        </li>
                                        <li>
                                             <b>4.13</b> Meter Sockets
                                        </li>
                                        <li>
                                             <b>4.14.2</b> Meter Installation
                                        </li>
                                        <li>
                                             <b>4.15.5</b> Meter Socket Identification Requirements
                                        </li>
                                        <li>
                                             <b>4.15</b> Meter Mounting Heights
                                        </li>
                                   </ul>
                              </div>
                         ),
                         applicableTo: [TIME_OF_DAY_SEPARATE_METER_MINNESOTA],
                    },
               ].filter(byApplicable(faqKey));
     }
};
