import React from "react";
import { FormattedMessage } from "react-intl";

const HowItWorks = () => {
     return (
          <>
               <h3 style={{ fontSize: "26px", paddingTop: "3.5rem" }}>
                    <FormattedMessage
                         id="chargingOptionsPerksMissing_title"
                         defaultMessage="Existing Participants"
                         description="Existing Participants"
                    />
               </h3>
               <p>
                    <FormattedMessage
                         id="perksBottomText.priceSignal"
                         defaultMessage=""
                         description=""
                         values={{
                              link: (
                                   <a
                                        href="https://www.xcelenergy.com/staticfiles/xe-responsive/PDF%20Pic.pdf?_ga=2.242135947.865215813.1718029269-2129354804.1709148865"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                   >
                                        <FormattedMessage
                                             id="perksBottomText.priceSignal.link"
                                             defaultMessage="price signal"
                                             description="price signal"
                                        />
                                   </a>
                              ),
                         }}
                    />
               </p>

               <p>
                    <FormattedMessage
                         id="perksBottomText2_v2"
                         defaultMessage="Customers on a Time of Use (TOU) rate pay varying electricity prices during specific hours (as indicated on the {link}). The goal of the price signal is to encourage EV owners to charge during off-peak hours when renewable energy is abundant, and rates are reduced. Note that all hours are considered off-peak during weekends."
                         description="Customers on a Time of Use (TOU) rate pay varying electricity prices during specific hours (as indicated on the {link}). The goal of the price signal is to encourage EV owners to charge during off-peak hours when renewable energy is abundant, and rates are reduced. Note that all hours are considered off-peak during weekends."
                         values={{
                              link: (
                                   <a
                                        href="https://co.my.xcelenergy.com/s/billing-payment/residential-rates/time-of-use-pricing"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                   >
                                        <FormattedMessage
                                             id="perksBottomText2.webpage.link"
                                             defaultMessage="TOU webpage"
                                             description="TOU webpage"
                                        />
                                   </a>
                              ),
                         }}
                    />
               </p>
               <p>
                    <FormattedMessage
                         id="perksBottomText3"
                         defaultMessage="Rest assured that this schedule ensures your vehicle is ready when you need it, while also supporting grid efficiency and renewable energy utilization. Your smart charging service provider will automatically send the charging schedule to your car, simplifying the process. If adjustments are necessary, they make it easy for you to change."
                         description="Rest assured that this schedule ensures your vehicle is ready when you need it, while also supporting grid efficiency and renewable energy utilization. Your smart charging service provider will automatically send the charging schedule to your car, simplifying the process. If adjustments are necessary, they make it easy for you to change."
                    />
               </p>
          </>
     );
};

export default HowItWorks;
