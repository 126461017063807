import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link, NavLink } from "react-router-dom";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from "reactstrap";

import logo from "../../../assets/images/logo.svg";
import "./Header.scss";
import { FormattedMessage } from "react-intl";
import InputZipcode from "../../InputZipcode/InputZipcode";
import { NEW_MEXICO, COLORADO } from "../../../../constants";
import { RenderByState } from "../../../../components/RenderByState";
import GaTracker from "../../../../utils/GaTracker/GaTracker";

const Header = ({
  page,
  zipcode,
  activePage,
  userLocation,
  language,
  changeLanguage,
}) => {
  const [collapse, setCollapse] = useState(false);

  const isHomepage =
    window.location.pathname === "/" || window.location.pathname === null;
  const intZip = parseInt(zipcode);
  const xcelStatesSet = new Set([
    "New Mexico",
    "Colorado",
    "Minnesota",
    "Wisconsin",
  ]);
  const validState = xcelStatesSet.has(userLocation?.region);
  const isNM = intZip >= 87001 && intZip <= 88439;
  const isCO = intZip >= 80001 && intZip <= 81658;
  const isMN = intZip >= 55001 && intZip <= 56763;
  const isWI = intZip >= 53001 && intZip <= 54986;
  const enableSelect = isNM || isCO || isMN || isWI

  const onChangeLanguage = (e) => {
    GaTracker.trackEvent({
      category: "Xcel Language Change",
      action: "Clicked Xcel Language Button",
      label: `Changed to ${e.target.value}`,
    });

    changeLanguage(e.target.value);
  };

  return (
    <header className="Header">
      <div className="container-fluid" style={{ padding: 0 }}>
        <div className="row topHeader d-none d-lg-flex">
          <div className="col-11 input-zipcode">
            <InputZipcode userLocation={userLocation} zipcode={zipcode} prefix="desktop" />
          </div>
          {enableSelect && (
            <div className="col-1 d-flex justify-content-center">
              <select
                className="form-select select-language"
                aria-label="Change Language" 
                onChange={onChangeLanguage}
                value={language}
              >
                <option value={"EN"} key={"EN"}>
                  {"English"}
                </option>
                <option value={"ES"} key={"ES"}>
                  {"Español"}
                </option>
              </select>
            </div>
          ) }
        </div>
        <div className="row topHeader mobile-top-header d-lg-none">
          <div className="col-10 input-zipcode p-0">
            <InputZipcode userLocation={userLocation} zipcode={zipcode} prefix="mobile" />
          </div>
          {isCO && (
            <div className="col-2 d-flex justify-content-center p-0">
              <select
                className="form-select select-language"
                aria-label="Change Language"
                onChange={onChangeLanguage}
                value={language}
              >
                <option value={"EN"} key={"EN"}>
                  {"EN"}
                </option>
                <option value={"ES"} key={"ES"}>
                  {"ES"}
                </option>
              </select>
            </div>
          )}
        </div>

        <Navbar expand="lg">
          <div
            style={{
              paddingTop: "0.75rem",
              paddingBottom: "0.75rem",
              paddingLeft: "2rem",
            }}
          >
            <Link to="/" className={isHomepage ? "active" : ""}>
              <img
                src={logo}
                alt="{process.env.REACT_APP_FULL_COMPANY_NAME}"
                className="img-fluid"
                style={{ maxWidth: "200px" }}
              />
            </Link>
          </div>
          <NavbarToggler onClick={() => setCollapse(!collapse)} aria-label={collapse ? 'Close navigation' : 'Open navigation'} />
          <Collapse isOpen={collapse} navbar>
            <Nav className="ml-auto" navbar>
              {process.env.REACT_APP_PAGES_HOME_ENABLED ? (
                <NavItem>
                  <Link to="/" className={isHomepage ? "active" : ""}>
                    <span>Home</span>
                  </Link>
                </NavItem>
              ) : null}
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle
                  nav
                  caret
                  className={activePage === "vehicles" ? "active" : ""}
                >
                  <span>
                    <FormattedMessage
                      id="electricVehicles"
                      defaultMessage="Electric Vehicles"
                      description="Electric Vehicles Header"
                    />
                  </span>
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem
                    key={`nav-item-new-vehicles`}
                    title={page === "vehicles" ? "active" : null}
                    tag={NavLink}
                    to={"/vehicles"}
                    role="menuitem"
                  >
                    <FormattedMessage
                      id="EVCatalog"
                      defaultMessage="EV Catalog"
                      description="EV Catalog Header"
                    />
                  </DropdownItem>
                  <DropdownItem
                    key={`nav-item-used-vehicles`}
                    title={page === "vehicles" ? "active" : null}
                    tag={NavLink}
                    to={"/used-vehicles"}
                    role="menuitem"
                  >
                    <FormattedMessage
                      id="preOwnedEVs"
                      defaultMessage="Pre-Owned EVs"
                      description="Pre-Owned EVs Header"
                    />
                  </DropdownItem>
                  <DropdownItem
                    key={`nav-item-compare-vehicles`}
                    title={page === "vehicles" ? "active" : null}
                    tag={NavLink}
                    to={"/compare-vehicles"}
                    role="menuitem"
                  >
                    <FormattedMessage
                      id="compareVehicles"
                      defaultMessage="Compare Vehicles"
                      description="Compare Vehicles Header"
                    />
                  </DropdownItem>
                  <DropdownItem
                    key={`nav-item-evs-faq`}
                    title={page === "vehicles" ? "active" : null}
                    tag={NavLink}
                    to={"/ev-101"}
                    role="menuitem"
                  >
                    EV 101
                  </DropdownItem>
                  {isCO && (
                    <DropdownItem
                      key={`nav-item-ev-rebate-co`}
                      tag={NavLink}
                      to={"/ev-rebate-co"}
                      role="menuitem"
                    >
                      <FormattedMessage
                        id="EVRebate"
                        defaultMessage="EV Rebate"
                        description="EV Rebate Header"
                      />
                    </DropdownItem>
                  )}
                </DropdownMenu>
              </UncontrolledDropdown>
              <NavItem>
                <NavLink
                  title={page === "incentives" ? "Active Page" : null}
                  to="/incentives"
                >
                  <span>
                    <FormattedMessage
                      id="incentives"
                      defaultMessage="Incentives"
                      description="Incentives Header"
                    />
                  </span>
                </NavLink>
              </NavItem>
              {validState && !isNM ? (
                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle
                    nav
                    caret
                    className={
                      ["dealers", "inventory"].includes(activePage)
                        ? "active"
                        : ""
                    }
                  >
                    <span>
                      <FormattedMessage
                        id="qualifiedDealers"
                        defaultMessage="Qualified Dealers"
                        description="Qualified Dealers Header"
                      />
                    </span>
                  </DropdownToggle>
                  <DropdownMenu style={{ width: "max-content" }}>
                    <DropdownItem
                      key={`nav-item-ev-dealers-programs`}
                      tag={NavLink}
                      to={"/dealers"}
                      role="menuitem"
                    >
                      <FormattedMessage
                        id="header.EVDealers"
                        defaultMessage="Browse Dealerships"
                        description="Browse Dealerships Header"
                      />
                    </DropdownItem>
                    {validState && userLocation?.region !== NEW_MEXICO && (
                      <DropdownItem
                        key={`nav-item-ev-inventory-programs`}
                        tag={NavLink}
                        to={"/inventory"}
                        role="menuitem"
                      >
                        <FormattedMessage
                          id="header.EVInventory"
                          defaultMessage="Browse Local Inventory"
                          description="Browse Local Inventory Header"
                        />
                      </DropdownItem>
                    )}
                  </DropdownMenu>
                </UncontrolledDropdown>
              ) : null}
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle
                  nav
                  caret
                  className={
                    ["home-charging-advisor"].includes(activePage)
                      ? "active"
                      : ""
                  }
                >
                  <span>
                    <FormattedMessage
                      id="charging"
                      defaultMessage="Charging"
                      description="Charging Header"
                    />
                  </span>
                </DropdownToggle>
                <DropdownMenu
                  style={{ marginLeft: "-52px", width: "max-content" }}
                >
                  {validState && userLocation?.region !== NEW_MEXICO && (
                    <DropdownItem
                      key={`nav-item-home-charging-advisor`}
                      title={
                        page === "home-charging-advisor" ? "Active Page" : null
                      }
                      tag={NavLink}
                      to={"/home-charging-advisor"}
                      role="menuitem"
                    >
                      <FormattedMessage
                        id="homeChargingAdvisor"
                        defaultMessage="Home Charging Advisor"
                        description="Home Charging Advisor Header"
                      />
                    </DropdownItem>
                  )}
                  <DropdownItem
                    key={`nav-item-charging-stations`}
                    title={page === "map" ? "active" : null}
                    tag={NavLink}
                    to={"/charging-stations"}
                    role="menuitem"
                  >
                    <FormattedMessage
                      id="header.chargingStations"
                      defaultMessage="Charging Stations"
                      description="Charging Stations Header"
                    />
                  </DropdownItem>
                  {validState && (
                    <DropdownItem
                      key={`nav-item-ev-charging-programs`}
                      tag={NavLink}
                      to={"/ev-charging-programs"}
                      role="menuitem"
                    >
                      <FormattedMessage
                        id="header.EVChargingProgram"
                        defaultMessage="EV Charging Programs"
                        description="EV Charging Programs Header"
                      />
                    </DropdownItem>
                  )}
                  <RenderByState
                    stateName={userLocation?.region}
                    options={{
                      default: null,
                      [COLORADO]: (
                        <DropdownItem
                          key={`nav-item-home-wiring-rebate`}
                          tag={NavLink}
                          to={"/home-wiring-rebate"}
                          role="menuitem"
                        >
                          <FormattedMessage
                            id="faqRebateCOHomeWiringRebate"
                            defaultMessage="EV Charger and Wiring Rebate"
                            description="EV Charger and Wiring Rebate"
                          />
                        </DropdownItem>
                      ),
                      [NEW_MEXICO]: (
                        <DropdownItem
                          key={`nav-item-home-wiring-rebate-nm`}
                          tag={NavLink}
                          to={"/home-wiring-rebate-nm"}
                          role="menuitem"
                        >
                          <FormattedMessage
                            id="faqRebateCOHomeWiringRebate"
                            defaultMessage="EV Charger and Wiring Rebate"
                            description="EV Charger and Wiring Rebate"
                          />
                        </DropdownItem>
                      ),
                    }}
                  />
                  <DropdownItem
                    key={`nav-item-charging-faq`}
                    tag={NavLink}
                    to={"/charging-101"}
                    role="menuitem"
                  >
                    <FormattedMessage
                      id="evChargingOneZeroOne"
                      defaultMessage="Charging 101"
                      description="Charging 101"
                    />
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              {process.env.REACT_APP_PAGES_CUSTOM_1_ENABLED ? (
                <NavItem>
                  <NavLink
                    title={
                      page === process.env.REACT_APP_PAGES_CUSTOM_1_NAVLINK
                        ? "Active Page"
                        : null
                    }
                    to={process.env.REACT_APP_PAGES_CUSTOM_1_NAVLINK}
                  >
                    <span>{process.env.REACT_APP_PAGES_CUSTOM_1_NAVNAME}</span>
                  </NavLink>
                </NavItem>
              ) : null}
              {process.env.REACT_APP_PAGES_FAQ_ENABLED ? (
                <NavItem>
                  <NavLink
                    title={page === "faq" ? "Active Page" : null}
                    to="/faq"
                  >
                    <span>FAQ</span>
                  </NavLink>
                </NavItem>
              ) : null}
              {process.env.REACT_APP_PAGES_ELECTRICIANS_ENABLED ? (
                <NavItem>
                  <NavLink
                    title={page === "electricians" ? "Active Page" : null}
                    to="/electricians"
                  >
                    <span>
                      <FormattedMessage
                        id="electricians"
                        defaultMessage="Electricians"
                        description="Electricians Header"
                      />
                    </span>
                  </NavLink>
                </NavItem>
              ) : null}
              {process.env.REACT_APP_PAGES_FAQ_ENABLED ? (
                <NavItem>
                  <NavLink
                    title={page === "faq" ? "Active Page" : null}
                    to="/faq"
                  >
                    <span>FAQ</span>
                  </NavLink>
                </NavItem>
              ) : null}
              {process.env.REACT_APP_PAGES_EVENTS_ENABLED ? (
                <NavItem>
                  <NavLink
                    title={page === "events" ? "Active Page" : null}
                    to="/events"
                  >
                    <span>
                      <FormattedMessage
                        id="events"
                        defaultMessage="Events"
                        description="Events Header"
                      />
                    </span>
                  </NavLink>
                </NavItem>
              ) : null}
            </Nav>
          </Collapse>
        </Navbar>
      </div>
    </header>
  );
};

export default Header;

Header.propTypes = {
  page: PropTypes.string,
};
