import React from "react";
import PropTypes from "prop-types";

import GaTracker from "../../../utils/GaTracker/GaTracker";

import GrandTitleParas from "../shared/GrandTitledParas/GrandTitledParas";
import { FormattedMessage } from "react-intl";

import "./IncomeQualified.scss";

const handleEnrollClick = () => {
     GaTracker.trackEvent({
          category: "Xcel Domain Links",
          action: "Clicked Xcel Domain Link",
          label: `Enroll in Income Qualified CO`,
     });
};

const renderEnrollmentDetails = (stateName, enrollURL) => (
  <>
    <section className="container works-elible-enroll">
      <div className="row">
        <GrandTitleParas
          classNames="eligible-if"
          colonHeader={
            <FormattedMessage
              id="youAreEligible"
              defaultMessage="You're eligible if you:"
              description="You're eligible if you:"
            />
          }
        >
          <ul>
            <li>
              <FormattedMessage
                id="evRebateCoEligibleAccount"
                defaultMessage="Have an electric service account with Xcel Energy in Colorado."
                description="Have an electric service account with Xcel Energy in Colorado."
              />
            </li>
            <li>
              <FormattedMessage
                id="evRebateCoEligibleIncome"
                defaultMessage="Meet certain income requirements."
                description="Meet certain income requirements."
              />
            </li>
            <li>
              <FormattedMessage
                id="evRebateCoEligibleEnrolled"
                defaultMessage="If you are enrolled in a program such as LEAP, CARE, SNAP or TANF, you qualify."
                description="If you are enrolled in a program such as LEAP, CARE, SNAP or TANF, you qualify."
              />
            </li>
            <li>
              <FormattedMessage
                id="evRebateCoEligibleQuestions"
                defaultMessage="See Frequently Asked Questions for more information."
                description="See Frequently Asked Questions for more information."
              />
            </li>
          </ul>
        </GrandTitleParas>

        <div className="qualification-option-container">
          <h2>
            <FormattedMessage
              id="qualificationOptionHeader1"
              defaultMessage="QUALIFICATION OPTION 1: STATE , FEDERAL, OR AREA MEDIAN INCOME"
              description="QUALIFICATION OPTION 1: STATE , FEDERAL, OR AREA MEDIAN INCOME"
            />
          </h2>
          <p>
            <FormattedMessage
              id="qualificationOptionParagraph1"
              defaultMessage="To meet the “Income-Qualified” requirement, you must have a gross household income below:"
              description="To meet the “Income-Qualified” requirement, you must have a gross household income below­:"
            />
          </p>
          <ul>
            <li>
              <FormattedMessage
                id="qualificationOptionList1"
                defaultMessage="60% of the state of Colorado's median income, {OR}"
                description="60% of the state of Colorado's median income, {OR}"
                values={{
                  OR: (
                    <strong className="uppercase">
                      <FormattedMessage
                        id="or"
                        defaultMessage="OR"
                        description="OR"
                      />
                    </strong>
                  ),
                }}
              />
            </li>
            <li>
              <FormattedMessage
                id="qualificationOptionList2"
                defaultMessage="200% of the relevant federal poverty level, {OR}"
                description="200% of the relevant federal poverty level, {OR}"
                values={{
                  OR: (
                    <strong className="uppercase">
                      <FormattedMessage
                        id="or"
                        defaultMessage="OR"
                        description="OR"
                      />
                    </strong>
                  ),
                }}
              />
            </li>
            <li>
              <FormattedMessage
                id="qualificationOptionList3"
                defaultMessage="80% of area median income (varies by county; see examples below. For a full Area Median Income table, {link}):"
                description=""
                values={{
                  link: (
                    <a
                      href="https://xcelnew.my.salesforce.com/sfc/p/#1U0000011ttV/a/8b000002b0oC/hZ9ON6pdietK9e62ETAHRbT2_kYrddyKhDb7KT6zIQ0"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FormattedMessage
                        id="clickHere"
                        defaultMessage="click here"
                        description="click here"
                      />
                    </a>
                  ),
                }}
              />
            </li>
          </ul>

          <div className="table-responsive">
            <table className="table-center">
              <thead>
                <tr>
                  <th>
                    <strong>
                      <FormattedMessage
                        id="coloradoCountyAMI"
                        defaultMessage="80% of County AMI"
                        description="80% of County AMI"
                      />
                    </strong>
                  </th>
                  <th>
                    <strong>
                      <FormattedMessage
                        id="coloradoIndividual"
                        defaultMessage="Individual"
                        description="Individual"
                      />
                    </strong>
                  </th>
                  <th>
                    <strong>
                      {" "}
                      <FormattedMessage
                        id="coloradoFamilyFour"
                        defaultMessage="Family of 4"
                        description="Family of 4"
                      />
                    </strong>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <strong>
                      <FormattedMessage
                        id="coloradoDenverCounty"
                        defaultMessage="Denver, Jefferson, Arapahoe, Adams, and Douglas County"
                        description="Denver, Jefferson, Arapahoe, Adams, and Douglas County"
                      />
                    </strong>
                  </td>
                  <td>$73,040</td>
                  <td>$104,320</td>
                </tr>
                <tr>
                  <td>
                    <strong>
                      <FormattedMessage
                        id="coloradoBoulderCounty"
                        defaultMessage="Boulder County"
                        description="Boulder County"
                      />
                    </strong>
                  </td>
                  <td>$81,760</td>
                  <td>$116,800</td>
                </tr>
                <tr>
                  <td>
                    <strong>
                      <FormattedMessage
                        id="coloradoMesaCounty"
                        defaultMessage="Mesa County"
                        description="Mesa County"
                      />
                    </strong>
                  </td>
                  <td>$52,800</td>
                  <td>$75,360</td>
                </tr>
                <tr>
                  <td>
                    <strong>
                      <FormattedMessage
                        id="coloradoSummitCounty"
                        defaultMessage="Summit County"
                        description="Summit County"
                      />
                    </strong>
                  </td>
                  <td>$68,240</td>
                  <td>$97,440</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className="qualification-option-container">
          <h2>
            <FormattedMessage
              id="qualificationOptionHeader1"
              defaultMessage="QUALIFICATION OPTION 2: ENROLLMENT IN ANY OF THE FOLLOWING PROGRAMS"
              description="QUALIFICATION OPTION 2: ENROLLMENT IN ANY OF THE FOLLOWING PROGRAMS"
            />
          </h2>
          <ul>
            <li>
              <FormattedMessage
                id="qualificationOption2List1"
                defaultMessage="State of Colorado Low-Income-Energy Assistance Program (LEAP)"
                description="State of Colorado Low-Income-Energy Assistance Program (LEAP)"
              />
            </li>
            <li>
              <FormattedMessage
                id="qualificationOption2List2"
                defaultMessage="Energy Outreach Colorado's Colorado Affordable Residential Energy Program (CARE)"
                description="Energy Outreach Colorado's Colorado Affordable Residential Energy Program (CARE)"
              />
            </li>
            <li>
              <FormattedMessage
                id="qualificationOption2List3"
                defaultMessage="Colorado's Weather Assistance Program (WAP)"
                description="Colorado's Weather Assistance Program (WAP)"
              />
            </li>
            <li>
              <FormattedMessage
                id="qualificationOption2List4"
                defaultMessage="Xcel Energy income-qualified demand side management program"
                description="Xcel Energy income-qualified demand side management program"
              />
            </li>
            <li>
              <FormattedMessage
                id="qualificationOption2List5"
                defaultMessage="Xcel Energy's income-qualified Community Solar Gardens program"
                description=""
              />
            </li>
            <li>
              <FormattedMessage
                id="qualificationOption2List6"
                defaultMessage="Supplemental Nutrition Assistance Program (SNAP)"
                description="Supplemental Nutrition Assistance Program (SNAP)"
              />
            </li>
            <li>
              <FormattedMessage
                id="qualificationOption2List7"
                defaultMessage="Temporary Assistance for Needy Families program (TANF)"
                description="Temporary Assistance for Needy Families program (TANF)"
              />
            </li>
          </ul>
        </div>

        <GrandTitleParas classNames="enroll">
          <div className="text-center">
            <a
              href={
                "https://my.xcelenergy.com/MyAccount/s/savings/income-qualified-ev-rebate-details"
              }
              target="_blank"
              rel="noopener noreferrer"
              onClick={handleEnrollClick}
            >
              <button className="btn btn-ae btn-xcel">
                <FormattedMessage
                  id="applyNow"
                  defaultMessage="Apply Now"
                  description="Apply Now"
                />
              </button>
            </a>
          </div>
        </GrandTitleParas>
        <div className="apply-now-disclaimer">
          <p>
            <FormattedMessage
              id="faqRebateCOReceiveEVRebate_first"
              defaultMessage="Get help with your questions about income qualification by contacting our partner, CLEAResult at {phone} or {email}."
              values={{
                phone: (
                  <a href="tel:18002196500">
                    <u>1-800-219-6500</u>
                  </a>
                ),
                email: (
                  <a href="mailto:XcelQualify@clearesult.com">
                    <u>XcelQualify@clearesult.com</u>
                  </a>
                ),
              }}
            />
          </p>
          <p>
            <FormattedMessage
              id="faqRebateCOReceiveEVRebate2"
              defaultMessage="If you fill out an application before you shop and get pre-qualified, you can get an instant, non-taxable rebate when you buy or lease your EV from a dealer in our EV Dealer Network."
            />
          </p>
        </div>
      </div>
    </section>
  </>
);

const EasyChargingHome = ({ stateName, enrollURL }) => {
     return (
          <div className="easy-charging-home">
               <section className="overview">{renderEnrollmentDetails(stateName, enrollURL)}</section>
          </div>
     );
};

export default EasyChargingHome;

EasyChargingHome.propTypes = {
     stateName: PropTypes.string,
     enrollURL: PropTypes.string,
};
