import React from "react";
import { FormattedMessage } from "react-intl";
import { getByState } from "../../../components/RenderByState";
import { MINNESOTA, NEW_MEXICO } from "../../../constants";

const EnvironmentalAndGridBenefits = ({
  ownCharger,
  setOwnCharger,
  stateName,
}) => {
  return (
    <div className="how-it-works">
      <div className="toggle-buttons">
        <div
          className={`own-charger-toggle ${ownCharger ? null : "active"}`}
          onClick={() => setOwnCharger(false)}
        >
          <span>
            <FormattedMessage
              id="egbNeedCharger"
              defaultMessage="I need a Level 2 charger"
              description="I need a Level 2 charger"
            />
          </span>
        </div>
        <div
          className={`own-charger-toggle ${ownCharger ? "active" : ``}`}
          onClick={() => setOwnCharger(true)}
        >
          <span>
            <FormattedMessage
              id="egbOwnCharger"
              defaultMessage="I will use my own Level 2 charger"
              description="I will use my own Level 2 charger"
            />
          </span>
        </div>
      </div>
      <div>
        {ownCharger ? (
          <td colSpan="2" className="has-charger">
            <div className="mb-3">
              <FormattedMessage
                id="youAreEligible"
                defaultMessage="You're eligible if you:"
                description="You're eligible if you:"
              />
            </div>
            <ul>
              <li>
                <FormattedMessage
                  id="driveEVOrPHEV"
                  defaultMessage="Drive an electric vehicle (all-electric or plug-in hybrid)"
                  description="Drive an electric vehicle (all-electric or plug-in hybrid)"
                />
              </li>
              <li>
                <FormattedMessage
                  id="liveInResidenceState"
                  defaultMessage="Live in a residence that receives electric service from Xcel Energy in {stateName}"
                  description="Live in a residence that receives electric service from Xcel Energy in {stateName}"
                  values={{
                    stateName,
                  }}
                />
              </li>
              <li>
                <FormattedMessage
                  id="chargingHardwired"
                  defaultMessage="Charging station will need to be hard-wired and inspected"
                  description="Charging station will need to be hard-wired and inspected"
                />
              </li>
              <li>
                <FormattedMessage
                  id="haveQualifying"
                  defaultMessage="Have a qualifying ChargePoint or Enel X Way charger (hyper link to section with charger information)"
                  description="Have a qualifying ChargePoint or Enel X Way charger (hyper link to section with charger information)"
                />
              </li>
              <li>
                <FormattedMessage
                  id="accessToWifi"
                  defaultMessage="Have access to Wi-Fi at the charging location"
                  description="Have access to Wi-Fi at the charging location"
                />
              </li>
              <li>
                <FormattedMessage
                  id="chargingVerify"
                  defaultMessage="Your charging station will be tested to verify data transmission"
                  description="Your charging station will be tested to verify data transmission"
                />
              </li>
            </ul>
          </td>
        ) : (
          <td colSpan="2" className="needs-charger">
            <p>
              {stateName === MINNESOTA ? (
                <FormattedMessage
                  id="ifyouneedlevel2"
                  defaultMessage="If you need a Level 2 charger, our EV Accelerate At Home program could be a good fit for you."
                  description="Our Programs"
                />
              ) : (
                <FormattedMessage
                  id="egbOurPrograms"
                  defaultMessage="We have programs that make charging at home easier. If you need a Level 2 charger, our EV Accelerate At Home program could be a good fit for you."
                  description="Our Programs"
                />
              )}
            </p>
            <div className="text-center">
              <a
                href={getByState({
                  stateName,
                  options: {
                    default: "/ev-accelerate-at-home-co",
                    [NEW_MEXICO]: "/ev-accelerate-at-home-nm",
                    [MINNESOTA]: "/ev-accelerate-at-home-mn",
                  },
                })}
              >
                <button className="btn btn-ae btn-xcel">
                  <FormattedMessage
                    id="eventCard.learnMore"
                    defaultMessage="Learn More"
                    description="Learn More"
                  />
                </button>
              </a>
            </div>
          </td>
        )}
      </div>
    </div>
  );
};

export default EnvironmentalAndGridBenefits;
