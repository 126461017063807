const chargePoint = {
    "charger_id": "ChargePoint_Home_Flex",
    "make": "ChargePoint",
    "model": "Home Flex",
    "model_variant": "",
    "price": 699,
    "price_src": "https://www.amazon.com/gp/product/B07WXZDHGV/ref=as_li_tl?ie=UTF8&tag=charg03-20&camp=1789&creative=9325&linkCode=as2&creativeASIN=B07WNXTHNW&linkId=108ace822e712862427722848f5aafac&th=1",
    "amps": "50 amps, 12 kW",
    "cord_length": 23,
    "home_outlet": "Hardwired connection",
    "form_factor": "Wall mounted",
    "wifi": true,
    "warranty": 3,
    "safe_outdoors": true,
    "made_in_america": null,
    "details_link": "https://www.chargepoint.com/resources/chargepoint-home-flex-cph50-brochure/",
    "spec_src": "https://www.chargepoint.com/files/datasheets/ds-home.pdf",
    "img": "https://assets.zappyride.com/img/chargers/ChargePoint%20Home%20Flex.png",
    "has_discount": false,
    "discount_value": 0,
    "discount_code": "",
    "plug" : "J1772",
    "incentives": [
      {
        "grantor": "Federal",
        "grantor_type": "Country",
        "applicant_type": "Passenger",
        "name": "Alternative Fuel Infrastructure Tax Credit",
        "description": "Customers who purchase qualified residential fueling equipment before December 31, 2020, may receive a tax credit of up to $1,000.",
        "type": "Tax Credit",
        "support_for": "Charging station",
        "typical_amount": "Up to $1,000",
        "limitations": "",
        "details_url": "https://afdc.energy.gov/laws/10513",
        "applicable_to_new_vehicles": true,
        "applicable_to_used_vehicles": true,
        "income_dependent": false,
        "applicable_to_fcevs": false,
        "available": true,
        "evaluation": {
          "applicable_to_lease": true,
          "applicable_to_ownership": true,
          "capitalized_in_lease": false,
          "eligibility": "eligible",
          "amount_in_purchase": 509.7,
          "amount_in_lease": 0,
          "purchase_calculation_rationale": "Tax credit is equal to the lesser of 30% of the cost and installation of the charger, or $1,000: $509.70",
          "lease_calculation_rationale": "Not applicable to leases. ",
          "amount_in_lease_precision": "exact",
          "amount_in_purchase_precision": "exact"
        }
      }
    ]
  }

  const enel = {
    "charger_id": "Enel_X_JuiceBox_40",
    "make": "Enel X Way",
    "model": "JuiceBox",
    "model_variant": "48",
    "price": 639,
    "price_src": "https://evcharging.enelx.com/store/residential/juicebox-48",
    "amps": "40 amps, 9.6 kW",
    "cord_length": 25,
    "home_outlet": "Hardwired connection",
    "form_factor": "Wall mounted",
    "wifi": true,
    "warranty": 3,
    "safe_outdoors": true,
    "made_in_america": true,
    "details_link": "https://support-emobility.enelx.com/hc/en-us/articles/360038171892-Next-Gen-JuiceBox-40-Data-Sheet",
    "spec_src": "https://evcharging.enelx.com/store/residential/juicebox-48",
    "img": "https://assets.zappyride.com/img/chargers/Enel%20X%20JuiceBox%2048.png",
    "has_discount": false,
    "discount_value": 0,
    "discount_code": "",
    "plug" : "J1772",
    "incentives": [
      {
        "grantor": "Federal",
        "grantor_type": "Country",
        "applicant_type": "Passenger",
        "name": "Alternative Fuel Infrastructure Tax Credit",
        "description": "Customers who purchase qualified residential fueling equipment before December 31, 2020, may receive a tax credit of up to $1,000.",
        "type": "Tax Credit",
        "support_for": "Charging station",
        "typical_amount": "Up to $1,000",
        "limitations": "",
        "details_url": "https://afdc.energy.gov/laws/10513",
        "applicable_to_new_vehicles": true,
        "applicable_to_used_vehicles": true,
        "income_dependent": false,
        "applicable_to_fcevs": false,
        "available": true,
        "evaluation": {
          "applicable_to_lease": true,
          "applicable_to_ownership": true,
          "capitalized_in_lease": false,
          "eligibility": "eligible",
          "amount_in_purchase": 491.7,
          "amount_in_lease": 0,
          "purchase_calculation_rationale": "Tax credit is equal to the lesser of 30% of the cost and installation of the charger, or $1,000: $491.70",
          "lease_calculation_rationale": "Not applicable to leases. ",
          "amount_in_lease_precision": "exact",
          "amount_in_purchase_precision": "exact"
        }
      }
    ]
  };

  export default [
      chargePoint, 
      enel
  ];