import React from "react";
import PropTypes from "prop-types";
import GaTracker from "../../../utils/GaTracker/GaTracker";
import { FormattedMessage } from "react-intl";
import { NEW_MEXICO, COLORADO } from "../../../constants";

export const EnrollButton = ({ stateName }) => {
  return (
    <button className="btn btn-ae btn-xcel" style={{marginTop:"25px"}}>
      <FormattedMessage
        id="enrollNow"
        defaultMessage="Enroll Now"
        description="Enroll Now"
      />
    </button>
  );
};

export const Blurb = ({ stateName, ownCharger }) => {
  let stateDisclaimer = "";
  if (stateName === "Minnesota") {
    stateDisclaimer = "";
  }

  switch (stateName) {
    case COLORADO:
      return null;
    case NEW_MEXICO:
      return null;
    default:
      return (
        <div className="qualified-electrician-blurb">
          {stateDisclaimer}

          {!ownCharger && (
              <>
                     <p>
                     <FormattedMessage
                            id="DueToCurrentMarketConditions_MN/WI"
                            defaultMessage="*Due to current market conditions, inventory of chargers is low and you may encounter delays in scheduling and having your charger installed over the 4 week timeline. We are doing our best to work with manufacturers to secure charging stations.
                            Since your charger will be Wi-Fi enabled, please confirm that the installation location has Wi-Fi access. If signal strength in this location is below 3 bars, you may need to install a Wi-Fi booster before the electrician arrives to install the charger. Be sure to have your Wi-Fi password available for connecting the charger during installation."
                            description="*Due to current market conditions, inventory of chargers is low and you may encounter delays in scheduling and having your charger installed over the 4 week timeline. We are doing our best to work with manufacturers to secure charging stations."
                     />
                     </p>
              </>
          )}
        </div>
      );
  }
};

const EnrollWithBlurb = ({ enrollURL, stateName, ownCharger }) => {
  const handleEnrollClick = () => {
    GaTracker.trackEvent({
      category: "Xcel Domain Links",
      action: "Clicked Xcel Domain Link",
      label: `Enroll in EV Accel at Home ${stateName}`,
    });

    switch (stateName) {
      case "Colorado":
        document.dispatchEvent(new Event("pixelClickTrackCO"));
        break;

      case "Minnesota":
        document.dispatchEvent(new Event("pixelClickTrackMN"));
        break;

      default:
        break;
    }
  };

  return (
    <>
      <div className="text-center" style={{ marginBottom: "20px" }}>
        <a
          href={enrollURL}
          target="_blank"
          rel="noopener noreferrer"
          onClick={handleEnrollClick}
        >
          <EnrollButton stateName={stateName} />
        </a>
        {stateName === "New Mexico" ? (
              <>
                     <p style={{marginTop:"50px"}}>We are not currently processing EV Accelerate At Home applications. Customers may still enroll in the Optimize Your Charge program and the Home Wiring Rebate program.</p>
              </>
        ) : null}
      </div>
      <Blurb stateName={stateName} ownCharger={ownCharger} />
    </>
  );
};

export default EnrollWithBlurb;

EnrollWithBlurb.propTypes = {
  enrollURL: PropTypes.string,
  stateName: PropTypes.string,
};
